import React from 'react';
import styles from "../LandingPage/landingPageMobCss/Section1Mob.module.css"; 
import imgSec from "../imagesMobImg/section1Mob/section1.png"
import logo1 from "../imagesMobImg/section1Mob/arq.svg";
import logo2 from "../imagesMobImg/section1Mob/essentia_logo 1.svg";
import logo3 from "../imagesMobImg/section1Mob/alsorg logo 1.svg";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";

const LandingPageMob = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setDemostate({
      name: "",
      company: "",
      email: "",
      phone: "",
    });
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [demostate, setDemostate] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    let name = e.target.name;
    if (name === "phone") {
      if (e.target.value.length <= 10) {
        setDemostate((prev) => {
          return { ...prev, ["phone"]: e.target.value };
        });
      }
    } else {
      setDemostate((prev) => {
        return { ...prev, [name]: e.target.value };
      });
    }
  };

  const handleQuerySubmit = async () => {
    if (
      demostate.name?.length > 0 &&
      demostate.company?.length > 0 &&
      demostate.email?.length > 0 &&
      demostate.phone?.length > 0
    )
      await axios
        .post("https://pro-api.idesign.market/user/schedule-demo", {
          name: demostate.name,
          companyName: demostate.company,
          email: demostate.email,
          phoneNumber: demostate.phone,
        })
        .then(function (response) {
          console.log(response);
          setShow(false);
          setShow2(true);
          setDemostate({
            name: "",
            company: "",
            email: "",
            phone: "",
          });
        })
        .catch(function (error) {
          // console.log(error.message);
          toast.error(
            "Email address or phone number you have entered is already registered with us."
          );
        });
    else {
      toast.error("Please enter all the fields");
    }
  };

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  return (
   <>
      <Modal
        show={show2}
        onHide={handleClose2}
        animation={false}
        centered
        dialogClassName="modal-80w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex"
              style={{
                textAlign: "center",
                color: "#212121",
                fontWeight: "500",
              
              }}
            >
              Your Query has been submitted, you'll get a revert within 4 hrs!!
            </div>
            <button
              className="mt-3"
              style={{
                background: "#3b5998",
                outline: "none",
                border: "none",
                color: "white",
                fontSize: "14px",
                // borderRadius: "50px",
                // height: "36px",
                padding: "0.5rem 1rem",
                width: "30%",
              }}
              onClick={() => setShow2(false)}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>Schedule a Demo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column w-100" >
            <div
              className="d-flex flex-column"
              style={{ marginBottom: "0.75rem" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#444444",
                  paddingLeft:'0.5rem'
                }}
              >
                Name
              </label>
              <input
                type="text"
                placeholder="Enter name"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "black",
                  border: "1px solid #a7a7a7",
                  height: "36px",
                  padding: "0.5rem",
                }}
                value={demostate.name}
                name="name"
                onChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginBottom: "0.75rem" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#444444",
                  paddingLeft:'0.5rem'
                }}
              >
                Name of Company
              </label>
              <input
                type="text"
                placeholder="Enter company name"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "black",
                  border: "1px solid #a7a7a7",
                  height: "36px",
                  padding: "0.5rem",
                }}
                value={demostate.company}
                name="company"
                onChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginBottom: "0.75rem" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#444444",
                  paddingLeft:'0.5rem'
                }}
              >
                Email
              </label>
              <input
                type="text"
                placeholder="Enter email"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "black",
                  border: "1px solid #a7a7a7",
                  height: "36px",
                  padding: "0.5rem",
                }}
                value={demostate.email}
                name="email"
                onChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginBottom: "0.75rem" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#444444",
                  paddingLeft:'0.5rem'
                }}
              >
                Phone No.
              </label>
              <input
                type="number"
                placeholder="Enter phone number"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "black",
                  border: "1px solid #a7a7a7",
                  height: "36px",
                  padding: "0.5rem",
                }}
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                value={demostate.phone}
                name="phone"
                onChange={handleChange}
              />
            </div>
            <button
              className="mt-3"
              style={{
                background: "#3b5998",
                outline: "none",
                border: "none",
                color: "white",
                fontSize: "14px",
                // borderRadius: "50px",
                // height: "36px",
                padding: "0.5rem 1rem",
              }}
              onClick={handleQuerySubmit}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
       <div style={{marginBottom:'16rem',position:"relative"}}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.topHeading}>
              One Solution for Interior and  Construction Companies
            </div>
            <p className={styles.para}>
              Manage your business at one place- Onboard clients, create 
              and  share  quotations,  track drawings, approvals, timelines,
              tasks, purchases & much more
            </p>
            <div style={{ marginTop: "1.2rem",width:'100%',display:'flex',justifyContent:"center",alignItems:'center'}}>
            <a href="https://pro.idesign.market/signup" target="blank" style={{textDecoration:"none"}}> <button className={styles.btn1}>Get Started</button></a> 
              <button className={styles.btn2} onClick={handleShow}>Schedule a Demo</button>
            </div>
          </div>
         
        </div>
        <div
            style={{
            
              display: "flex",
              justifyContent: "center",
              flexDirection:"column",
              alignItems: "center",
              height:"77%",
              position:'absolute',
              top:"65%",
            }}
          >
            <img src={imgSec} alt="" className={styles.img1} />
            <div
          style={{
           
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
      
          }}
        >
          <div>
            <img src={logo1} alt="" style={{width:'6rem',height:'1.5rem'}}/>
          </div>
          <div>
            <img src={logo2} alt="" style={{marginLeft:"2rem",width:'5rem',height:'1.3rem'}}/>
          </div>
          <div>

            <img src={logo3} alt="" style={{marginLeft:"2rem",width:'4rem',height:'1.7rem'}}/>
          </div>
        </div>
          </div>
       
    
      </div>
   
   </>
  )
}

export default LandingPageMob;