import React, { useEffect, useState } from 'react'
import img3d from './3dImages/3dimg.svg'
import cadicon from './3dImages/cadicon.svg'
import pdficon from './3dImages/pdficon.svg'
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const SideCart = ({ payMethod }) => {

  const [data, setData] = useState([])
  const [userData, setUserData] = useState([])
  const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
  const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
  const payM = useSelector((state) => state.threedReducer.payMethod)
  const [delId, setDelId] = useState()

  const showApiData = async () => {
    const res3 = await axios.get(`https://3d-api.idesign.market/api/room/req-designs?designerId=${localStorage.getItem("userId")}`)
    setData(res3.data)
    // console.log(res3)
  }


  useEffect(() => {
    showApiData()
  }, [])
  // console.log(data)
  // console.log(data[0].cadFile)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleDelete = async () => {
    // console.log(delId)
    await axios.delete(`https://3d-api.idesign.market/api/room/remove-from-cart?id=${delId}`).then((res) => {
      setShow(false)
      showApiData()
      window.location.reload()
    })

  }

  const handleShow = async (_id) => {
    setShow(true)
    setDelId(_id)
    // await axios.delete(`https://3d-api.idesign.market/api/room/remove-from-cart?id=${_id}`).then((res) => console.log(res))
  }


  

  let ar = []
  let ar1 = []

  var totViews = 0
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].room.length; j++) {
      totViews += data[i].room[j].views
    }
    ar[i] = totViews
  }

  ar1[0] = ar[0]

  for (let k = 0; k < ar.length; k++) {
    if (k > 0) {
      ar1[k] = ar[k] - ar[k - 1]
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Body className='d-flex flex-column p-4'>
          <p style={{
            fontWeight: "400",
            fontSize: "22px",
            color: "black"
          }}>Are you sure you want to remove this?</p>
          <div>
            <button style={{
              background: "#176091",
              borderRadius: "4px", border: "none", color: "white", width: "90px", height: "32px", cursor: "pointer"
            }} onClick={handleDelete}>Yes</button>
            <button className='mx-3' style={{
              background: "#FFFFFF",
              borderRadius: "4px", border: "1px solid gainsboro", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)", color: "black", width: "90px", height: "32px", cursor: "pointer"
            }} onClick={handleClose}>No</button>
          </div>
        </Modal.Body>
      </Modal>
      <div className='d-flex flex-column w-100'>
        {data.map((item2, index) => (
          <>
            <div className="cart_left my-3">
              <div className="cart_img_div">
                <img src={img3d} alt="" className="cart_img" />
              </div>
              <div className="cart_left_start">
                <div className="cart_sub">
                  <h4 className="cart_head">3D feature</h4>
                  <div className="cart_below">
                    <div className="cart_below2">
                      {item2.room.map((item, index) => (
                        <button className="mapped mb-2 p-1">{item.name}({item.views})</button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="cart_file">
                  <div className="cart_file_sub">
                    {data && item2.cadFile.fileName && <p className="file1 mb-0" ><img src={cadicon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{item2.cadFile.fileName.slice(0, 9)}{"..."}</p>}
                    {item2.pdf && item2.pdf.fileName ? <p className="file1 mb-0" style={{ marginLeft: "10px" }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{item2.pdf.fileName.slice(0, 9)}{"..."}</p> : <p className="file1" style={{ marginLeft: "10px" }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p>}
                  </div>
                </div>
                {payMethod === "full" ? <div className="ca_ending d-flex flex-row">
                  <p className="cart_tot">₹ {ar1[index] * 1200} </p>
                  <span className='cart_payment'>(Complete Payment)</span>
                  <button className='pay-btn float-end' onClick={() => handleShow(item2._id)}>Remove</button>
                </div> : <div className="ca_ending d-flex flex-row">
                  <p className="cart_tot">₹ {(ar1[index] * 1200) / 2} </p>
                  <span className='cart_payment'>(Half Payment)</span>
                  <button className='pay-btn float-end' onClick={() => handleShow(item2._id)} >Remove</button>
                </div>}
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  )
}

export default SideCart