import React, { useEffect, useState } from 'react'
import img3d from './3dImages/3dimg.svg'
import cadicon from './3dImages/cadicon.svg'
import pdficon from './3dImages/pdficon.svg'
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import blank3d from './3dImages/blank3d.svg'
const SideCartMob = ({ payMethod }) => {


    const [data, setData] = useState([])
    const [delId, setDelId] = useState()

    const showApiData = async () => {
        const res3 = await axios.get(`https://3d-api.idesign.market/api/room/req-designs?designerId=${localStorage.getItem("userId")}`)
        setData(res3.data)
        console.log(res3)
    }
    useEffect(() => {
        showApiData()
    }, [])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleDelete = async () => {
        // console.log(delId)
        await axios.delete(`https://3d-api.idesign.market/api/room/remove-from-cart?id=${delId}`).then((res) => {
            setShow(false)
            showApiData()
            window.location.reload()
        })
    }

    const handleShow = async (_id) => {
        setShow(true)
        setDelId(_id)
    }

    let ar = []
    let ar1 = []

    var totViews = 0
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].room.length; j++) {
            totViews += data[i].room[j].views
        }
        ar[i] = totViews
    }

    ar1[0] = ar[0]

    for (let k = 0; k < ar.length; k++) {
        if (k > 0) {
            ar1[k] = ar[k] - ar[k - 1]

        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} centered size="md">
                <Modal.Body className='d-flex flex-column p-4'>
                    <p style={{
                        fontWeight: "400",
                        fontSize: "22px",
                        color: "black"
                    }}>Are you sure you want to remove this?</p>
                    <div>
                        <button style={{
                            background: "#176091",
                            borderRadius: "4px", border: "none", color: "white", width: "90px", height: "32px", cursor: "pointer"
                        }} onClick={handleDelete}>Yes</button>
                        <button className='mx-3' style={{
                            background: "#FFFFFF",
                            borderRadius: "4px", border: "1px solid gainsboro", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)", color: "black", width: "90px", height: "32px", cursor: "pointer"
                        }} onClick={handleClose}>No</button>
                    </div>
                </Modal.Body>
            </Modal>
            {data.map((item2, index) => (<div className="box_start d-flex flex-column my-2">
                <div className="box_top d-flex mt-3">
                    <img src={blank3d} alt="" className="box_blank" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
                    <div className="box_lines d-flex flex-column mx-2">
                        <h4 className="box_head">3D feature</h4>
                        {data && item2.cadFile.fileName && <p className="box-file1" ><img src={cadicon} style={{ height: "15px", width: "10px", marginRight: "8px" }} alt="" className="fileimg" />{item2.cadFile.fileName.slice(0, 9)}{"..."}</p>}
                        {item2.pdf && item2.pdf.fileName ? <p className="box-file1" ><img src={pdficon} style={{ height: "15px", width: "10px", marginRight: "8px" }} alt="" className="fileimg" />{item2.pdf.fileName.slice(0, 9)}{"..."}</p> : <p className="box-file1" ><img src={pdficon} style={{ height: "15px", width: "10px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p>}
                    </div>
                    <button className='pay-btn float-end' onClick={() => handleShow(item2._id)}>Remove</button>
                </div>
                <div className="view_btns-mob d-flex p-3 flex-wrap">
                    {item2.room.map((item, index) => (
                        <button className="mapped mb-2">{item.name}({item.views})</button>
                    ))}
                </div>
                {payMethod === "full" ? <div className="end-cart-mob d-flex w-100 px-3">
                    <p className="cart_tot2">₹{ar1[index] * 1200} </p>
                    <span className='cart_payment2 '>(Complete Payment)</span>
                </div> : <div className="end-cart-mob d-flex w-100 px-3">
                    <p className="cart_tot2">₹{(ar1[index] * 1200) / 2} </p>
                    <span className='cart_payment2 '>(Half Payment)</span>
                </div>}
            </div>))}
        </>
    )
}

export default SideCartMob