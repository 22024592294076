import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import header from "./Images/header.svg"
// import { useState } from 'react';
import { AiOutlineDown } from "react-icons/ai";
import loggo from '../SaasOnboarding/NewLandingPage/imagesAnj/logo.png';
import { useState } from 'react';
import { Link } from 'react-router-dom';


export default function HamburgerMenu(props) {
    const [isToggle, setToggle] = useState(false);
    const navigate = useNavigate();

    const handleNew = () => {
        props.changeTab2()
    }
    const handleNew2 = () => {
        props.changeTab()
    }


        useEffect(() => {
        if (localStorage.getItem("pathname") === null) {
            if (localStorage.getItem("token") != "null" && localStorage.getItem("token") != null) {
                // navigate("/lead")
                // setIsLoggedIn(true)
            }
        } else {
            const path = localStorage.getItem("pathname")
            navigate(`${path}`)
        }
    }, [])
    return (
        <div>
            <section className="top-nav">
                <div className='d-flex'>
                    <img src={loggo} style={{ width: '8.4rem', height: '3.5rem', objectFit: 'contain' }} onClick={() => navigate("/")} />
                </div>
                <input id="menu-toggle" type="checkbox" />
                <label className="menu-button-container" htmlFor="menu-toggle" onClick={() => setToggle(false)}>
                    <div className="menu-button" />
                </label>
                <ul className="menu">
                    <li className='' style={{ fontSize: '15px', color: 'black', borderBottom: '1px solid #D7D7D7',fontWeight: "500" }} onClick={() => navigate('/products')}>Products</li>
                    {/* <li className='' style={{ fontSize: '15px', color: 'black', borderBottom: '1px solid #D7D7D7',fontWeight: "500" }} role="button" onClick={() => navigate('/pricing')}>Pricing</li> */}
                    <li className='' style={{ fontSize: '15px', color: 'black', borderBottom: '1px solid #D7D7D7',fontWeight: "500" }} role="button" onClick={() => window.location.assign("https://pro.idesign.market/community/")}>Community</li>
                    <li className='' style={{ fontSize: '15px', color: 'black', borderBottom: '1px solid #D7D7D7',fontWeight: "500" }} role="button" onClick={() => window.location.assign("https://magazine.idesign.market/")}>Magazine</li>
                    {/* <li className='d-flex justify-content-between p-0' style={{ borderBottom: '1px solid #D7D7D7' }}>
                        <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }}>About  </li>

                        {isToggle ? <li role="button" onClick={() => setToggle(!isToggle)}><AiOutlineDown style={{ transform: 'rotate(180deg)' }} />
                        </li>

                            : <li role="button" onClick={() => setToggle(!isToggle)}><AiOutlineDown /></li>}

                    </li>
                    {isToggle ? <>
                        <ul style={{ listStyleType: 'none', width: '92%', paddingLeft: '0px', }}>
                            <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }} > <Link to="/aboutidesign" style={{color: "black", textDecoration: "none"}}>
                                About Us
                                </Link></li>
                            <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }} > <Link to="/terms" style={{ color: "black", textDecoration: "none" }}>
                                Terms & Condition
                            </Link></li>
                            <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }} > <Link to="/privacy-policy" style={{ color: "black", textDecoration: "none" }}>
                                Privacy Policy
                            </Link></li>
                            <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }} > <Link to="/contact" style={{ color: "black", textDecoration: "none" }}>
                                Contact Us
                            </Link></li>
                        </ul></> : ""} */}
                    {/* <li className='btn_back flex-column'>
                        <button className='btn-login' style={{ background: '#3B5998', color: '#ffffff', margin: '0rem', width: '100%', fontSize: "13px", fontWeight: "500", height: '2rem' }} onClick={() => navigate('/signup')}>Create an Account</button>
                    </li> */}

                    <li className='d-flex mt-0' style={{ position: 'relative' }} >

                        <button className='btn-login pt-0' style={{ color: '#3B5998', border: '1px solid #3B5998', boxShadow: 'none', margin: '0rem', height: '2rem', width: '100%', background: "white",fontSize: "13px", fontWeight: "500" }} onClick={() => navigate('/login')}>Sign in </button>
                    </li>
                </ul>

            </section>

        </div>
    )
}