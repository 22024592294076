import React from 'react';
import styles from "../onBoardingCss/fewmoreSteps.module.css";
import main from "../footerImg/reports/reports.png";
import icon from "../footerImg/reports/Vector.png";
import check from '../footerImg/reports/check.svg';
import Carousel from "react-bootstrap/Carousel";
import icon1 from "../footerImg/task/Vector.png";
import icon2 from "../footerImg/reports/Vector.png";

const FewMoresteps = () => {
  return (
    <>
    <div className={styles.container}>
        <div className={styles.first_contain}>
           
        <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
            }}
          
            nextIcon="" nextLabel=""
            prevIcon="" prevLabel=""
          >
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.amazonaws.com/Rectangle%207461%20%284%29.svg"
                  alt="First slide"
                  className={styles.imgg}
                />
              </div>
              <div
                style={{ backgroundColor: "#E9EFFB", paddingLeft: "2.5rem" }}
              >
                <div>
                  <img src={icon} alt="" className={styles.icon} />
                </div>
                <div className={styles.heading}>FILE MANAGER</div>
                <div className={styles.para}>
                  Easily organize and manage all of your important documents
                  with our file manager feature.
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%283%29.svg"
                  alt="Second slide"
                  className={styles.imgg}
                />
              </div>
              <div
                style={{ backgroundColor: "#E9EFFB", paddingLeft: "2.5rem" }}
              >
                <div>
                  <img src={icon1} alt="" className={styles.icon} />
                </div>
                <div className={styles.heading}>TASK MANAGEMENT</div>
                <div className={styles.para}>
                  Optimize resource usage and ensures that projects are
                  completed efficiently and on time.
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207465%20%282%29.svg"
                  alt="Third slide"
                  className={styles.imgg}
                />
              </div>

              <div
                style={{ backgroundColor: "#E9EFFB", paddingLeft: "2.5rem" }}
              >
                <div>
                  <img src={icon2} alt="" className={styles.icon} />
                </div>
                <div className={styles.heading}>DAILY PROJECT REPORT</div>
                <div className={styles.para}>
                  Ensures that all team members are aware of the current status
                  of the project and can make informed decisions accordingly.
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
           
        </div>
        {/* second div */}
        <div className={styles.second_contain}>
            <div className={styles.PersonalContent_div}>
                <div style={{display:"flex",justifyContent:'space-between',alignItems:'center',width:'80%'}}>
                <div className={styles.personal_head}>Great Radhika, A few more steps & we are done</div>
                </div>
             <button className={styles.inputs1}><img src={check} alt="" className={styles.check}/> Complete your Profile</button>
             <button className={styles.inputs1}><img src={check} alt="" className={styles.check}/>Add Company Details</button>
             <button className={styles.inputs1}><img src={check} alt="" className={styles.check}/>Create a Project Listing</button>
             {/* <a href="https://pro.idesign.market/file-beta/" className={styles.inputs} style={{textDecoration:"none",textAlign:"center"}}>Upload new file to file manager</a>
             <a href="https://pro.idesign.market/quo-beta/enterprise-landing-page" className={styles.inputs} style={{textDecoration:"none",textAlign:"center"}}> Create a design quotation</a> */}
           <a href="https://pro.idesign.market/" className={styles.skip} style={{textDecoration:"none"}}>Skip to dashboard{">>"}</a>
            </div>
          
        </div>
     </div>
    </>
  )
}

export default FewMoresteps;