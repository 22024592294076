export const constants = {
  B2B_BASE: "https://pro-api.idesign.market",
  COMMUNITY_BASE: "https://community-api.idesign.market",
  PO_BASE: "https://po-api.idesign.market",
  PMT_BASE: "https://pmt.idesign.market",
  QUOT_BASE: "https://quotation-api.idesign.market",
  BASE_URL: "/po-beta",
};
export const BASE_URL_ESS = "https://pro.idesign.market/";
export function getToken() {
    return `Bearer ${localStorage.getItem("token")}`;
}