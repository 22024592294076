import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import loggo from "./Images/loggo.png"
const BeforeOtpHeader = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("pathname") === null) {
            if (localStorage.getItem("token") != "null" && localStorage.getItem("token") != null) {
                navigate("/lead")
                // setIsLoggedIn(true)
            }
        } else {
            const path = localStorage.getItem("pathname")
            navigate(`${path}`)
        }
    }, [])
  return (
      <div className="w-100 mainn_header">
          <div className="header-onboard">
              <img src={loggo} style={{ width: '10rem', height: '3.5rem', objectFit: 'contain' }} onClick={() => navigate("/")} />
          </div>
      </div>
  )
}

export default BeforeOtpHeader