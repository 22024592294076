import React, { useState, Fragment, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Dropdown,
  Modal,
  Navbar,
  NavItem,
  Offcanvas,
} from "react-bootstrap";
import { scrollToTop } from "../../../Helpers/utils";
import Logo from "../Logo";
import { useSelector, useDispatch } from "react-redux";
import { handleLogout } from "../../../Redux/Actions/auth";
import { confirmAlert } from "react-confirm-alert";
import { TOP_NAV_MENU } from "../../../Constants/navBar";
import { ROUTES } from "../../../Router/routes";
import SidebarNav from "../../../Lms/SidebarNav";
import person from "../../../Images/person.webp";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const Header = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigateTo = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);
  // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const itemClick = () => {
    setSideMenu(false);
    scrollToTop();
  };
  // console.log("test")

  const logoutHandler = () => {
    setSideMenu(false);
    confirmAlert({
      message: `Are you sure you want to logout?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(handleLogout()),
        },
        {
          label: "No",
        },
      ],
    });
  };

  // useEffect(() => {
  //   if (localStorage.getItem("path") === null) {
  //     if (
  //       localStorage.getItem("token") != "null" &&
  //       localStorage.getItem("token") != null
  //     ) {
  //       navigateTo("/lead");
  //       setIsLoggedIn(true);
  //     }
  //   } else {
  //     const path = localStorage.getItem("path");
  //     navigateTo(`${path}`);
  //   }
  // }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setDemostate({
      name: "",
      company: "",
      email: "",
      phone: "",
    });
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [demostate, setDemostate] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    let name = e.target.name;
    if (name === "phone") {
      if (e.target.value.length <= 10) {
        setDemostate((prev) => {
          return { ...prev, ["phone"]: e.target.value };
        });
      }
    } else {
      setDemostate((prev) => {
        return { ...prev, [name]: e.target.value };
      });
    }
  };

  const handleQuerySubmit = async () => {
    if (
      demostate.name?.length > 0 &&
      demostate.company?.length > 0 &&
      demostate.email?.length > 0 &&
      demostate.phone?.length > 0
    )
      await axios
        .post("https://pro-api.idesign.market/user/schedule-demo", {
          name: demostate.name,
          companyName: demostate.company,
          email: demostate.email,
          phoneNumber: demostate.phone,
        })
        .then(function (response) {
          console.log(response);
          setShow(false);
          setShow2(true);
          setDemostate({
            name: "",
            company: "",
            email: "",
            phone: "",
          });
        })
        .catch(function (error) {
          // console.log(error.message);
          toast.error(
            "Email address or phone number you have entered is already registered with us."
          );
        });
    else {
      toast.error("Please enter all the fields");
    }
  };

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  // console.log(demostate);

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        show={show2}
        onHide={handleClose2}
        animation={false}
        centered
        dialogClassName="modal-80w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex"
              style={{
                textAlign: "center",
                color: "#212121",
                fontWeight: "500",
              }}
            >
              Your Query has been submitted, you'll get a revert within 4 hrs!!
            </div>
            <button
              className="mt-3"
              style={{
                background: "#3b5998",
                outline: "none",
                border: "none",
                color: "white",
                fontSize: "14px",
                // borderRadius: "50px",
                // height: "36px",
                padding: "0.5rem 1rem",
                width: "30%",
              }}
              onClick={() => setShow2(false)}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>Schedule a Demo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column w-100">
            <div
              className="d-flex flex-column"
              style={{ marginBottom: "0.75rem" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#444444",
                }}
              >
                Name
              </label>
              <input
                type="text"
                placeholder="Enter name"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "black",
                  border: "1px solid #a7a7a7",
                  height: "36px",
                  padding: "0.5rem",
                }}
                value={demostate.name}
                name="name"
                onChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginBottom: "0.75rem" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#444444",
                }}
              >
                Name of Company
              </label>
              <input
                type="text"
                placeholder="Enter company name"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "black",
                  border: "1px solid #a7a7a7",
                  height: "36px",
                  padding: "0.5rem",
                }}
                value={demostate.company}
                name="company"
                onChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginBottom: "0.75rem" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#444444",
                }}
              >
                Email
              </label>
              <input
                type="text"
                placeholder="Enter email"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "black",
                  border: "1px solid #a7a7a7",
                  height: "36px",
                  padding: "0.5rem",
                }}
                value={demostate.email}
                name="email"
                onChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginBottom: "0.75rem" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#444444",
                }}
              >
                Phone No.
              </label>
              <input
                type="number"
                placeholder="Enter phone number"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "black",
                  border: "1px solid #a7a7a7",
                  height: "36px",
                  padding: "0.5rem",
                }}
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                value={demostate.phone}
                name="phone"
                onChange={handleChange}
              />
            </div>
            <button
              className="mt-3"
              style={{
                background: "#3b5998",
                outline: "none",
                border: "none",
                color: "white",
                fontSize: "14px",
                // borderRadius: "50px",
                // height: "36px",
                padding: "0.5rem 1rem",
              }}
              onClick={handleQuerySubmit}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="header">
        <Container>
          <Navbar style={{ boxShadow: "none" }} expand="lg" expanded={sideMenu}>
            <Container className="ps-0 pe-0">
              <Navbar.Toggle
                aria-controls="offcanvasNavbar"
                onClick={() => setSideMenu(true)}
              >
                <span
                  className="navbar-toggler-icon"
                  style={{ width: "25px", height: "21px" }}
                ></span>
              </Navbar.Toggle>
              <Logo />

              {/* <NavItem className="mobile_viwe">
              {isLoggedIn ? (
                <span
                  className="nav-link pointer interFont"
                  onClick={logoutHandler}
                >
                  Logout
                </span>
              ) : (
                <Link
                  className="nav-link"
                  to={ROUTES.LOGIN}
                  onClick={scrollToTop}
                >
                  Log In
                </Link>
              )}
            </NavItem> */}

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {/* <button className="">Schedule a demo</button> */}

                <ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0">
                  {TOP_NAV_MENU.map((menu, i) => (
                    <Fragment key={i}>
                      {menu.external ? (
                        <NavItem key={i} style={{ cursor: "pointer" }}>
                          <a className={`nav-link`} href={menu.to}>
                            {menu.label}
                          </a>
                        </NavItem>
                      ) : (
                        <NavItem key={i} style={{ cursor: "pointer" }}>
                          <Link
                            className={`nav-link`}
                            to={menu.to}
                            onClick={itemClick}
                          >
                            {menu.label}
                          </Link>
                        </NavItem>
                      )}
                    </Fragment>
                  ))}
                </ul>
                <div className="d-flex color-change">
                  <NavItem>
                    {isLoggedIn ? (
                      <>
                        {/* <button>schedule a demo</button> */}
                        <span className="pointer">
                          <Dropdown>
                            <Dropdown.Toggle
                              style={{ background: "none", border: "none" }}
                            >
                              <img
                                style={{ height: "2rem", width: "2rem" }}
                                src={person}
                                alt="person link"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                id="re-dash"
                                onClick={() => navigateTo("/lead")}
                              >
                                Dashboard
                              </Dropdown.Item>
                              <Dropdown.Item onClick={logoutHandler}>
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      </>
                    ) : (
                      <div className="d-flex align-items-center">
                        <Link
                          className="nav-link"
                          to={ROUTES.LOGIN}
                          onClick={itemClick}
                        >
                          Log In / Sign Up
                        </Link>
                        <button
                          className="ms-3"
                          style={{
                            background: "#3b5998",
                            outline: "none",
                            border: "none",
                            color: "white",
                            fontSize: "14px",
                            borderRadius: "50px",
                            // height: "36px",
                            padding: "0.5rem 1rem",
                          }}
                          onClick={handleShow}
                        >
                          Schedule a demo
                        </button>
                      </div>
                    )}
                  </NavItem>
                </div>
              </div>
              <SidebarNav
                sidebarShow={sideMenu}
                sidebarClose={itemClick}
                sidebarLogout={logoutHandler}
              />
            </Container>
          </Navbar>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Header;
