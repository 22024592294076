import react from "react";
// import header from "./Images/header.svg"
import loggo from "./Images/loggo.png"

export default function HeaderOnboard() {
    return (
        <div className="w-100 mainn_header">
            <div className="header-onboard">
                <img src={loggo} style={{width:'10rem',height:'3.5rem',objectFit:'contain'}}/>
            </div>
        </div>
    )
}