import React from "react";
import LandingPageMob from "./LandingPageMob";
import Section2Mob from "./Section2Mob";
import Section3Mob from "./Section3Mob";
import Section4Mob from "./Section4Mob";
import TestimonalMob from "./TestimonalMob";
import AboutUs from "./AboutUs";
import FooterMobile from "../FooterMobile";
import HamburgerMenu from "../HamburgerMeun";
import Mobile from "./Mobile";
import PricingLandMob from "./PricingLandMob";

const MainMobComp = () => {
  return (
    <>
      <HamburgerMenu />
      <LandingPageMob />
      <div style={{paddingLeft:"1.5rem",paddingRight:"1.5rem"}}>    
        <Section2Mob />
        <Section3Mob />
        <Section4Mob />
        {/* <PricingLandMob/> */}
        <TestimonalMob />    
      </div>
      <AboutUs />
      <Mobile/>
      <FooterMobile />
    </>
  );
};

export default MainMobComp;
