import React, { useEffect, useState } from 'react'
import cadicon from './3dImages/cadicon.svg'
import pdficon from './3dImages/pdficon.svg'
import blank3d from './3dImages/blank3d.svg'
import img3d from './3dImages/3dimg.svg'
import details from './3dImages/details.svg'
import { Modal, Offcanvas } from 'react-bootstrap'
import close from './3dImages/close.svg'
import eye from './3dImages/eye.svg'
import gmeet from './3dImages/gmeet.svg'
import write from './3dImages/write.svg'
import file from './3dImages/file.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { fetchProfileData } from '../Lms/Actions'
const Completed3dMob = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const handleClose = () => { setShow(false) }
    const handleClose2 = () => { setShow2(false) }



    const navigate = useNavigate()
    const payM = useSelector((state) => state.threedReducer.payMethod)
    const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
    const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
    const [data, setData] = useState([])
    const [userData, setUserData] = useState([])
    const [completedData, setCompletedData] = useState([])
    const [showId, setShowId] = useState();
    const [ofArr, setOfArr] = useState([])
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    // console.log(profileInfo)
    // console.log(profileInfo[0]?.data?.data?.planId?._id)
    const dispatch = useDispatch()

    useEffect(async () => {
        const res = await axios.get(`https://3d-api.idesign.market/api/room/completed-designs?designerId=${localStorage.getItem("userId")}`)
        // console.log(res)
        setCompletedData(res.data)
    }, [])

    useEffect(() => {
        dispatch(fetchProfileData(authToken))
    }, [])

    const handleShow = (id) => {
        // console.log(id)
        setShowId(id)
        setShow(true)
    }
    const handleShow2 = (id) => {
        // console.log(id)
        setShowId(id)
        setShow2(true)
    }
    useEffect(() => {
        const b = completedData.filter((currElem) => {
            return currElem._id == showId
        })
        setOfArr(b)
    }, [showId])

    // console.log(ofArr)


    const handleHalfPay = (id) => {
        setShowId(id)
        console.log(id)
        localStorage.setItem("halfPayId", showId)
        navigate("/3dCartHalf")
    }

    var tot = 0
    for (let i = 0; i < ofArr[0]?.room?.length; i++) {
        tot += ofArr[0]?.room[i]?.views
    }
    // console.log(ofArr)
    // console.log()
    // let date0 = new Date(ofArr[0]?.adminStatus?.block?.meet?.date)
    // let date1 = date0.toString().substring(4, 15).split(' ')
    // let date2 = date1.split(' ')

    // console.log(date1)

    var date = new Date()
    function getD(item) {
        // let e = new Date(parseInt(item.substring(0, 4)), parseInt(item[6]) - 1, parseInt(item[9]))
        // let days = e.getTime()
        // return Math.round((date.getTime() - days) / (1000 * 3600 * 24))
        // console.log(item)
        let e = new Date(item)
        // console.log(e)
        let days = e.getTime()
        // console.log(days)
        return Math.round((date.getTime() - days) / (1000 * 3600 * 24))
    }

    const downloadFile = async (file) => {
        // console.log(file)
        window.location.href = `https://3d-api.idesign.market/api/room/downloadFile?fileKey=${file?.url?.slice(47)}`
    }

    return (
        <div>
            {completedData.map((item2, index) => (
                <div className="mob_start_main d-flex flex-column" style={{ borderBottom: "1px solid #DFDFDF" }}>
                    <div className="box_top d-flex mt-3">
                        <div style={{
                            background: "#F0F0F0",
                            borderRadius: "4px",
                            padding: "7px 7px",
                            height: "64px"
                        }}>
                            <img src={img3d} className="ordImg2" style={{
                                width: "50px",
                                height: "50px"
                            }} />
                        </div>
                        <div className="box_lines d-flex flex-column w-100 " style={{ marginLeft: "12px" }}>
                            <div className="ord_head d-flex">
                                <h4 className="cart_head d-flex flex-column">3D feature <span className='ord_span pt-1'>Last Contacted  {getD(item2.updatedAt)} days ago</span></h4>
                                {item2.paidAmount && <span className="ord_span2 d-flex float-end">Paid <p className="cart_head px-2">₹ {Math.round(item2.paidAmount)}</p></span>}
                            </div>
                            {completedData && item2.cadFile.fileName && <p className="file2 mb-0"><img src={cadicon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{item2.cadFile.fileName.slice(0, 9)}{"..."}</p>}
                            {item2.paidAmount === item2.amountToBePaid ? item2.pdf && item2.pdf.fileName ? <p className="file3 mb-0"><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{item2.pdf.fileName.slice(0, 9)}{"..."}<button className="ord_upload_comp" onClick={() => {
                                downloadFile(item2?.adminStatus?.finalRender?.file)
                            }}>Download File</button></p> : <p className="file2 my-0"><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file <button className="ord_upload_comp" onClick={() => {
                                    downloadFile(item2?.adminStatus?.finalRender?.file)
                            }}>Download File</button></p> :
                                item2.pdf && item2.pdf.fileName ? <><p className="file3 mb-0"><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{item2.pdf.fileName.slice(0, 9)}{"..."} </p><h4 className="cart_head2 px-0 mt-3 d-flex">To download pay<span style={{
                                    color: "#3B5998",
                                    paddingLeft: "6px"
                                }}> ₹{Math.round(item2.paidAmount)}/-</span> <button className="ord_upload2" onClick={() => handleShow(item2._id)}>Pay Now</button> </h4></> : <><p className="file2  mb-0"><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p><h4 className="cart_head2 px-0 mt-3 d-flex">To download pay<span style={{
                                    color: "#3B5998",
                                     paddingLeft: "6px"
                                    }}> ₹{Math.round(item2.paidAmount)}/-</span> <button className="ord_upload2" onClick={() => handleShow(item2._id)}>Pay Now</button> </h4></>}

                            <div className="ord_en d-flex mt-3">
                                {item2.status === "completed" ? <><div className="ord_circ2"></div>
                                    <h4 className="cart_head2 d-flex">Complete <span className='ord_span3'>(Final Render)</span></h4></> : ""}
                                <p className="ord_view" onClick={() => handleShow2(item2._id)}>View Details <img src={details} alt="" style={{
                                    height: "10px",
                                    width: "10px"
                                }} /></p>

                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <Modal show={show} onHide={handleClose} centered size="sm">
                <Modal.Body style={{ padding: "1.5rem" }}>
                    <img src={close} alt="" className='d-flex float-end' onClick={handleClose} style={{
                        opacity: "0.5",
                        height: "13px",
                        width: "13px",
                        cursor: "pointer"
                    }} />
                    <h4 style={{ fontWeight: "600", fontSize: "14px", color: "#000000", textAlign: "center" }}>Hey {profileInfo[0]?.data?.data?.companyName}!</h4>
                    <p style={{ fontWeight: "400", fontSize: "12px", color: "#000000", textAlign: "center" }}>To download this file please pay remaining 50% amount.</p>
                    <p className='d-flex justify-content-around mb-0' style={{ fontWeight: "400", fontSize: "12px", color: "#000000", textAlign: "center" }}>Total Price<p style={{ fontWeight: "400", fontSize: "12px", color: "#000000", textAlign: "center" }}>₹ {Math.round(ofArr[0]?.amountToBePaid)}</p></p>
                    <p className='d-flex justify-content-around mb-0' style={{ fontWeight: "400", fontSize: "12px", color: "#000000", textAlign: "center" }}>Total Paid<p style={{ fontWeight: "400", fontSize: "12px", color: "#1FCE85", textAlign: "center" }}>₹ {Math.round(ofArr[0]?.paidAmount)}</p></p>
                    <p className='d-flex justify-content-around mb-0' style={{ fontWeight: "400", fontSize: "12px", color: "#000000", textAlign: "center" }}>Total remaning<p style={{ fontWeight: "400", fontSize: "12px", color: "#000000", textAlign: "center" }}>₹ {Math.round(ofArr[0]?.paidAmount)}</p></p>
                    <hr style={{ width: "80%", margin: "auto" }} />
                    <p className='d-flex justify-content-around mb-0 py-2' style={{ fontWeight: "400", fontSize: "12px", color: "#000000", textAlign: "center" }}>Total Price<p style={{ fontWeight: "400", fontSize: "12px", color: "#000000", textAlign: "center" }}>₹ {Math.round(ofArr[0]?.amountToBePaid)}</p></p>
                    <button className='modal-btn' style={{ fontSize: "12px", height: "32px" }} onClick={() => handleHalfPay(ofArr[0]?._id)}>Pay Now</button>
                </Modal.Body>
            </Modal>
            <Offcanvas show={show2} onHide={handleClose2} placement='end' size="md">
                <Offcanvas.Header closeButton className='mb-0'>
                    <div>
                        <Offcanvas.Title>{profileInfo[0]?.data?.data?.companyName}</Offcanvas.Title>
                        {/* <p style={{ fontSize: "14px", color: "#888888" }}>Delhi · 7h ago</p> */}
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body >
                    <div className="cart_file_sub2 pt-2 px-3">
                        {ofArr[0]?.cadFile?.fileName && <p className="file2"><img src={cadicon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ofArr[0]?.cadFile?.fileName.slice(0, 9)}{"..."}</p>}
                        {ofArr[0]?.pdf?.fileName ? <p className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ofArr[0]?.pdf?.fileName.slice(0, 9)}{"..."}</p> : localStorage.getItem("saveLater") ? <><p className="file3" style={{ marginLeft: "30px" }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />File Not uploaded</p> </> : <p className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p>}
                    </div>
                    <div className='d-flex flex-wrap px-2'>
                        {ofArr[0]?.room?.map((item) => (
                            <button className='btn-off mx-2 mb-2'>{item.name} {item.views} <img src={eye} alt="" className="bbeye" /></button>
                        ))}
                        <p className='mx-2 mt-1 p-off'>Total {tot} views</p>
                    </div>
                    <div className='pt-2 px-3 d-flex'>
                        <div className="off-line d-flex flex-column">
                            <div className="d-flex">
                                <div className="ord_circ2 "></div>
                                <h4 className="cart_head2 d-flex ">Complete<span className='ord_span3'> (3D Block)</span>{ofArr[0]?.paidAmount === ofArr[0]?.amountToBePaid ? <button className="ord-off px-3 mx-2" onClick={() => {
                                    downloadFile(ofArr[0]?.adminStatus?.block?.file)
                                }}>Download File</button> : ""}</h4></div>
                            <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.meet?.date)).toString().substring(4, 15)}</span></div>
                            <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>meeting is completed sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.remark?.date)).toString().substring(4, 15)}</span></div>
                            <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.file?.date)).toString().substring(4, 15)}</span></div>
                            <div className="d-flex">
                                <div className="ord_circ2 m-0" ></div>
                                <h4 className="cart_head2 d-flex">Complete<span className='ord_span3'> (Low render)</span>{ofArr[0]?.paidAmount === ofArr[0]?.amountToBePaid ? <button className="ord-off px-3 mx-2" onClick={() => {
                                    downloadFile(ofArr[0]?.adminStatus?.lowRender?.file)
                                }}>Download File</button> : ""}</h4></div>
                            <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>
                            <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>meeting is completed sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>
                            <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.file?.date)).toString().substring(4, 15)}</span></div>

                            <div className="d-flex">
                                <div className="ord_circ2 m-0" ></div>
                                <h4 className="cart_head2 d-flex ">Complete<span className='ord_span3'> (Final render)</span>{ofArr[0]?.paidAmount === ofArr[0]?.amountToBePaid ? <button className="ord-off px-3 mx-2" onClick={() => {
                                      downloadFile(ofArr[0]?.adminStatus?.finalRender?.file)
                                }}>Download File</button> : ""}</h4></div>
                            <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.finalRender?.meet?.date)).toString().substring(4, 15)}</span></div>
                            <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>meeting is completed sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.finalRender?.meet?.date)).toString().substring(4, 15)}</span></div>
                            <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.finalRender?.file?.date)).toString().substring(4, 15)}</span></div>
                        </div>
                        <div className="_line2"></div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default Completed3dMob