import React from "react";
import styles from "./ProductsCss/design.module.css";
import LoginHeader from "../LoginHeader";
import SameComponents from "./SameComponents";
import { useState,useEffect } from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { featureScroll } from "../../../Actions";
import { useDispatch,useSelector } from "react-redux";
import { desArr } from "../../../../Constants/arrayConstants";
import { useLocation } from "react-router-dom";

const Design = () => {
  const [isFixed, setIsFixed] = useState(false);
  const dispatch = useDispatch();
  const { scrollToFeatures } = useSelector((state) => state.addToCartReducer);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const shouldBeFixed = scrollPosition > 350; // Adjust the value according to your needs
      setIsFixed(shouldBeFixed);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

   // scroll to top
   const [visible, setVisible] = useState(false);

   const toggleVisible = () => {
     const scrolled = document.documentElement.scrollTop;
     if (scrolled > 300) {
       setVisible(true);
     } else if (scrolled <= 300) {
       setVisible(false);
     }
   };
 
   const scrollToTop = () => {
     window.scrollTo({
       top: 0,
       behavior: "smooth",
     });
   };
   window.addEventListener("scroll", toggleVisible);

     // stop scrolling
  const [showFixedDiv, setShowFixedDiv] = useState(false);

    
  function ifUp(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  function checkScrollDirection(event) {
    if (ifUp(event)) {
      return true
    } else {
      return false
    }
  }


  useEffect(() => {
    if (isFixed) {
      // let isScrolling = null;

      // const handleScroll = () => {
      //   clearTimeout(isScrolling);

      //   setShowFixedDiv(true);

      //   isScrolling = setTimeout(() => {
      //     setShowFixedDiv(false);
      //   }, 1000); // Adjust the timeout value as needed
      // };

      // window.addEventListener("scroll", handleScroll);
      // return () => {
      //   window.removeEventListener("scroll", handleScroll);
      // };

      window.onwheel = (e) => {
        if(window.pageYOffset > 100){
          setIsFixed(checkScrollDirection(e))
          setShowFixedDiv(checkScrollDirection(e))
          }
        }
    }
  }, [isFixed]);


  const path = useLocation();
  const queryParams = new URLSearchParams(path.search);
  const searchValue = queryParams.get('search');
     // scroll to feature
  useEffect(() => {

    if (searchValue) {
      const targetDiv = document.getElementById(searchValue);
      const offset = 360;
      const targetOffset = targetDiv.offsetTop - offset;

      window.scrollTo({
        top: targetOffset,
        behavior: "smooth",
      });

      dispatch(featureScroll(""));
      return () => {
        dispatch(featureScroll(""));
      };
    }
  }, [searchValue]);
  return (
    <>
    <div className="d-flex flex-column" style={{ overflowX: "hidden" }}>
      <LoginHeader />
      <div style={{ display: "flex", flexDirection: "column", zIndex: 0 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "#FFFFFC",
          paddingTop:"5rem",
        }}
      >
        <div className={styles.container}>
          <div style={{ width: "50%",display:"flex",justifyContent:'center'}}>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group+2611931.svg"
              alt=""
              style={{ width: "75%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "50%",
              paddingTop: "4rem",
              paddingRight: "5rem",
            }}
          >
            <div className={styles.heading}>
            Design & Build Companies: Streamline your Business with our easy and intuitive software
            </div>
            <p className={styles.para}>
              Our platform offers a range of powerful tools that will help
              streamline your operations, increase efficiency, and drive
              profitability.
            </p>
          </div>
        </div>

        {showFixedDiv ? (
              <div className={isFixed ? styles.btn_div : ""}>
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    marginTop: "1rem",
                    flexWrap: "wrap",
                    padding: "1rem 12rem",
                    justifyContent: "center",
                  }}
                >
            

                  {desArr.map((curelem) => {
                    return (
                      <a href={curelem.link} style={{textDecoration:'none'}}>
                        <div
                          style={{
                            backgroundColor: curelem.background,
                            color: curelem.textColor,
                            borderRadius: "30px",
                            padding: "0.4rem 1.2rem",
                            fontWeight: "600",
                          }}
                        >
                          {curelem.label}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className={styles.btn_div1}> 
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    // marginTop: "1rem",
                    flexWrap: "wrap",
                    padding: "1rem 12rem",
                    justifyContent: "center",
                  }}
                >
                {desArr.map((curelem) => {
                  return (
                    <a href={curelem.link} style={{textDecoration:'none'}}>
                      <div
                        style={{
                          backgroundColor: curelem.background,
                          color: curelem.textColor,
                          borderRadius: "30px",
                          padding: "0.5rem 1.5rem",
                          fontWeight: "600",
                        }}
                      >
                        {curelem.label}
                      </div>
                    </a>
                  );
                })}
              </div>
              </div>
            )}
      </div>

      <SameComponents />
      <div
        style={{
          position: "fixed",
          top: "90%",
          right: "5%",
          cursor: "pointer",
        }}
      >
        <BsFillArrowUpCircleFill
          onClick={scrollToTop}
          size={30}
          style={{ display: visible ? "inline" : "none" }}
        />
      </div>
      </div>
      </div>
    </>
  );
};

export default Design;
