import React from 'react';
import logo from "../../NewLandingPage/imagesAnj/logo.png";

const HeaderMob = () => {
  return (
    <>
     <div style={{height:"7vh",position:'fixed',width:"100%",backgroundColor:"#ffffff",display:"flex",alignItems:"center",zIndex:"1",borderBottom:"1px solid #DFDFDF"}}>
         <img src={logo} alt="" style={{width:"12rem",paddingLeft:"1.5rem"}}/>
     </div>
    
    </>
  )
}

export default HeaderMob