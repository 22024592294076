import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import idesign from './SaasAssets/idesign.png'
import idesign from "./NewLandingPage/imagesAnj/logo.png";
import { BiChevronDown } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ScheduleADemo from "./Products/ScheduleADemo";
import { FiChevronDown } from "react-icons/fi";
import { useRef } from "react";
import { featureScroll } from "../../Actions";
// import "../SaasOnboarding/"

// import { Direction } from "react-toastify/dist/utils";

const LoginHeader = (props) => {
  const [changeBorder, setchangeBorder] = useState("");
  const dispatch = useDispatch();
  const [changeComp, setchangeComp] = useState("find");
  const navigate = useNavigate();
  const [show11, setShow11] = useState(false);
  const handleShow = () => setShow11(true);
  const handleClose = () => setShow11(false);
  const path = useLocation();

  // console.log(path)

  const handleSignIn = () => {
    navigate("/login");
  };
  const handleSignup = () => {
    navigate("/signup");
  };

  const profileData = useSelector(
    (state) => state.addToCartReducer.profileData
  );
  // console.log(profileData)

  useEffect(() => {
    if (localStorage.getItem("pathname") === null) {
      if (
        localStorage.getItem("token") != "null" &&
        localStorage.getItem("token") != null
      ) {
        // navigate("/lead");
        // setIsLoggedIn(true)
      }
    } else {
      const path = localStorage.getItem("pathname");
      // navigate(`${path}`);
    }
  }, []);

  const [box, setbox] = useState("All");
  const [box1, setbox1] = useState("find");

  const [activeTab, setactiveTab] = useState("notact");
  const location = useLocation();

  const [dropdownOpen, setdropdownOpen] = useState("");

  // scroll to top
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  //  redirct func
  // const scrollToDiv=(id)=>{
  //   const targetDiv = document.getElementById(id);
  //   const offset = 150;
  //   const targetOffset = targetDiv.offsetTop - offset;

  //   window.scrollTo({
  //     top: targetOffset,
  //     behavior: 'smooth',
  //   });
  // }

  // useEffect(() => {
  //   scrollToDiv(localStorage.getItem("features"));
  // }, [])

  return (
    <>
      <ScheduleADemo
        show={show11}
        showFunc={handleShow}
        closeFunc={handleClose}
      />

      <div
        style={
          path.pathname === "/landingpage" ||
          path.pathname === "/" ||
          path.pathname === "/products" ||
          path.pathname === "/pricing" ||
          path.pathname === "/architect" ||
          path.pathname === "/design" ||
          path.pathname === "/contractor" ||
          path.pathname === "/estate" ||
          path.pathname === "/retail"
            ? {
                top: "0",
                height: "10vh",
                zIndex: "1",
                boxShadow: " 0px 4px 12px rgba(0, 0, 0, 0.1)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                background: "white",
                borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
              }
            : {
                height: "10vh",
                boxShadow: " 0px 4px 12px rgba(0, 0, 0, 0.1)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
              }
        }
      >
        {path.pathname === "/landingpage" ||
        path.pathname === "/" ||
        path.pathname === "/products" ||
        path.pathname === "/about" ||
        path.pathname === "/contact" ||
        path.pathname === "/architect" ||
        path.pathname === "/design" ||
        path.pathname === "/contractor" ||
        path.pathname === "/estate" ||
        path.pathname === "/retail" ? (
          // path.pathname === "/pricing"
          <div
            className="d-flex justify-content-between"
            style={{ width: "92%", height: "10vh" }}
          >
            <img
              src={idesign}
              alt=""
              style={{
                width: "12rem",
                zIndex: "2",
                cursor: "pointer",
                objectFit: "contain",
                height: "100%",
              }}
              onClick={() => {
                scrollToTop();
                navigate("/");
              }}
            />
            <div className="d-flex justify-content-between align-items-center gap-5">
              <div className={changeBorder==="prod"?"head-pricing-active":"head-pricing"}>
                <Dropdown show={dropdownOpen === "solution"}>
                  <Dropdown.Toggle
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                     
                    }}
                    className="px-0"
                    onMouseEnter={() => setdropdownOpen("solution")}
                  >
                  <a href="https://pro.idesign.market/products" style={{textDecoration:"none"}}><span style={{color:'#000000'}} onClick={()=>setchangeBorder("prod")}>Product</span></a>  
                    <FiChevronDown
                      style={{ marginLeft: "0.5rem", color: "black" }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      width: "46rem",
                      border: "none",
                      borderRadius: "4px",
                      backgroundColor: "#EFF3F9",
                    }}
                    onMouseLeave={() => setdropdownOpen("")}
                    className="drop_menu"
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        gap: "0.7rem",
                        
                      }}
                    >
                      <div className="Find_comm_div">
                        <div
                          className={
                            box === "All" ? "find_div" : "find_div_normal"
                          }
                          onMouseOver={() => setbox("All")}
                          onClick={() => {
                            navigate("/products");
                          }}
                        >
                          <div className="find_head">All Features</div>
                          <p className="findd_para">
                            Explore all our features designed to enhance your
                            project
                          </p>
                        </div>

                        <div
                          className={
                            box === "arc" ? "find_div" : "find_div_normal"
                          }
                          onMouseOver={() => setbox("arc")}
                          onClick={() => {
                            navigate("/architect");
                          }}
                        >
                          <div className="find_head">Architects</div>
                          <p className="findd_para">
                            Streamline your Design Process to Enhance Efficiency
                            and Profitability
                          </p>
                        </div>

                        <div
                          className={
                            box === "con" ? "find_div" : "find_div_normal"
                          }
                          onMouseOver={() => setbox("con")}
                          onClick={() => {
                            navigate("/contractor");
                          }}
                        >
                          <div className="find_head">
                            Construction Companies
                          </div>
                          <p className="findd_para">
                            Efficiently Manage Projects and Teams
                          </p>
                        </div>

                        <div
                          className={
                            box === "des" ? "find_div" : "find_div_normal"
                          }
                          onMouseOver={() => setbox("des")}
                          onClick={() => {
                            navigate("/design");
                          }}
                        >
                          <div className="find_head">Design & Build</div>
                          <p className="findd_para">
                            Manage Design to Construction with intuitive
                            features for the project journey
                          </p>
                        </div>

                        <div
                          className={
                            box === "estate" ? "find_div" : "find_div_normal"
                          }
                          onMouseOver={() => setbox("estate")}
                          onClick={() => {
                            navigate("/estate");
                          }}
                        >
                          <div className="find_head">Real Estate Builders</div>
                          <p className="findd_para">
                            Efficiently Manage Projects and Teams
                          </p>
                        </div>

                        <div
                          className={
                            box === "retail" ? "find_div" : "find_div_normal"
                          }
                          onMouseOver={() => setbox("retail")}
                          onClick={() => {
                            navigate("/retail");
                          }}
                        >
                          <div className="find_head">Retail Companies</div>
                          <p className="findd_para">
                            Get a complete overview of your stores with dynamic
                            updates
                          </p>
                        </div>
                      </div>

                      {/* sec div */}
                      {box === "All" || box === "des" || box === "estate" ? (
                        // all
                        <div className="Company_type_div2">
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("file"));
                                if (box === "All") {
                                  navigate("/products?search=file");
                                } else if (box === "des") {
                                  navigate("/design?search=file");
                                } else if (box === "estate") {
                                  navigate("/estate?search=file");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.amazonaws.com/file.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Files</span>
                                <span className="file_para">
                                  Manage Drawing
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("quo"));
                                if (box === "All") {
                                  navigate("/products?search=quo");
                                } else if (box === "des") {
                                  navigate("/design?search=quo");
                                } else if (box === "estate") {
                                  navigate("/estate?search=quo");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Quotation</span>
                                <span className="file_para">
                                  Create Quotation
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 2nd row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display:"flex",
                                alignItems: "center",
                                textDecoration: "none",
                                gap: "0.7rem",
                                width: "50%",
                          
                              }}
                              onClick={() => {
                                dispatch(featureScroll("po"));
                                if (box === "All") {
                                  navigate("/products?search=po");
                                } else if (box === "des") {
                                  navigate("/design?search=po");
                                } else if (box === "estate") {
                                  navigate("/estate?search=po");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/poo.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">
                                  Purchase Order
                                </span>
                                <span className="file_para">Create PO</span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.7rem",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("task"));
                                if (box === "All") {
                                  navigate("/products?search=task");
                                } else if (box === "des") {
                                  navigate("/design?search=task");
                                } else if (box === "estate") {
                                  navigate("/estate?search=task");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/taskee.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Task Manager</span>
                                <span className="file_para">Manage Tasks</span>
                              </div>
                            </div>
                          </div>

                          {/* 3rd row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("report"));
                                if (box === "All") {
                                  navigate("/products?search=report");
                                } else if (box === "des") {
                                  navigate("/design?search=report");
                                } else if (box === "estate") {
                                  navigate("/estate?search=report");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/i.svg"
                                alt=""
                                style={{
                                  height: "1.5rem",
                                  marginTop: "0.3rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">
                                  Daily Project Report
                                </span>
                                <span className="file_para">Create DPR</span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("report"));
                                if (box === "All") {
                                  navigate("/products?search=report");
                                } else if (box === "des") {
                                  navigate("/design?search=report");
                                } else if (box === "estate") {
                                  navigate("/estate?search=report");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/edit_note.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">MOM</span>
                                <span className="file_para">
                                  Document Info.
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 4th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("time"));
                                if (box === "All") {
                                  navigate("/products?search=time");
                                } else if (box === "des") {
                                  navigate("/design?search=time");
                                } else if (box === "estate") {
                                  navigate("/estate?search=time");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/timeL.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Timelines</span>
                                <span className="file_para">
                                  Manage Project Timeline
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("aco"));
                                if (box === "All") {
                                  navigate("/products?search=aco");
                                } else if (box === "des") {
                                  navigate("/design?search=aco");
                                } else if (box === "estate") {
                                  navigate("/estate?search=aco");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector.png"
                                alt=""
                                style={{
                                  height: "1.5rem",
                                  marginTop: "0.3rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Accounts</span>
                                <span className="file_para">
                                  Manage Expenses
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 5th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.7rem",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("po"));
                                if (box === "All") {
                                  navigate("/products?search=po");
                                } else if (box === "des") {
                                  navigate("/design?search=po");
                                } else if (box === "estate") {
                                  navigate("/estate?search=po");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/vendorr.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Vendor</span>
                                <span className="file_para">
                                  Manage Vendors
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("lead"));
                                if (box === "All") {
                                  navigate("/products?search=lead");
                                } else if (box === "des") {
                                  navigate("/design?search=lead");
                                } else if (box === "estate") {
                                  navigate("/estate?search=lead");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/leadss.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Manage Leads</span>
                                <span className="file_para">
                                  Manage New Leads
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 6th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.7rem",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("chat"));
                                if (box === "All") {
                                  navigate("/products?search=chat");
                                } else if (box === "des") {
                                  navigate("/design?search=chat");
                                } else if (box === "estate") {
                                  navigate("/estate?search=chat");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/forum.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Chat</span>
                                <span className="file_para">
                                  Powerful Communication
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("inventory"));
                                if (box === "All") {
                                  navigate("/products?search=inventory");
                                } else if (box === "des") {
                                  navigate("/design?search=inventory");
                                } else if (box === "estate") {
                                  navigate("/estate?search=inventory");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/inventory_2.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Inventory</span>
                                <span className="file_para">
                                  Manage Resources
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 7th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("quality"));
                                if (box === "All") {
                                  navigate("/products?search=quality");
                                } else if (box === "des") {
                                  navigate("/design?search=quality");
                                } else if (box === "estate") {
                                  navigate("/estate?search=quality");
                                }
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/add_task.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Quality Check</span>
                                <span className="file_para">
                                  Save Time, Reduce Rework
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/overview.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                                onClick={() => {
                                  dispatch(featureScroll("sheet"));
                                  if (box === "All") {
                                    navigate("/products?search=sheet");
                                  } else if (box === "des") {
                                    navigate("/design?search=sheet");
                                  } else if (box === "estate") {
                                    navigate("/estate?search=sheet");
                                  }
                                }}
                              >
                                <span className="file_head">Timesheets</span>
                                <span className="file_para">
                                  Optimize workflow
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 8th row */}
                          <div
                            style={{ display: "flex", marginBottom: "1rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                // justifyContent:'center',
                                alignItems: "center",
                                gap: "0.7rem",
                                width: "50%",
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/phone_android.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Mobile App</span>
                                <span className="file_para">
                                  Projects on the go
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : box === "arc" ? (
                        <div className="Company_type_div2">
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.7rem",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("file"));
                                navigate("/architect?search=file");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.amazonaws.com/file.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Files</span>
                                <span className="file_para">
                                  Manage Drawing
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("quo"));
                                navigate("/architect?search=quo");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Quotation</span>
                                <span className="file_para">
                                  Create Quotation
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 2nd row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("po"));
                                navigate("/architect?search=po");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/poo.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">
                                  Purchase Order
                                </span>
                                <span className="file_para">Create PO</span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.7rem",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("task"));
                                navigate("/architect?search=task");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/taskee.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Task Manager</span>
                                <span className="file_para">Manage Tasks</span>
                              </div>
                            </div>
                          </div>

                          {/* 3rd row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("report"));
                                navigate("/architect?search=report");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/i.svg"
                                alt=""
                                style={{
                                  height: "1.5rem",
                                  marginTop: "0.3rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">
                                  Daily Project Report
                                </span>
                                <span className="file_para">Create DPR</span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("report"));
                                navigate("/architect?search=report");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/edit_note.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">MOM</span>
                                <span className="file_para">
                                  Document Info.
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 4th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("time"));
                                navigate("/architect?search=time");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/timeL.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Timelines</span>
                                <span className="file_para">
                                  Manage Project Timeline
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("aco"));
                                navigate("/architect?search=aco");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector.png"
                                alt=""
                                style={{
                                  height: "1.5rem",
                                  marginTop: "0.3rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Accounts</span>
                                <span className="file_para">
                                  Manage Expenses
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 5th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "55%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("lead"));
                                navigate("/architect?search=lead");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/leadss.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Manage Leads</span>
                                <span className="file_para">
                                  Manage New Leads
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "45%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                marginLeft: "0.5rem",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("chat"));
                                navigate("/architect?search=chat");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/forum.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Chat</span>
                                <span className="file_para">
                                  Powerful Communication
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 6th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("quality"));
                                navigate("/architect?search=quality");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/add_task.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Quality Check</span>
                                <span className="file_para">
                                  Save Time, Reduce Rework
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("sheet"));
                                navigate("/architect?search=sheet");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/overview.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Timesheets</span>
                                <span className="file_para">
                                  Optimize workflow
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 8th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/phone_android.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Mobile App</span>
                                <span className="file_para">
                                  Projects on the go
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : box === "con" ? (
                        <div className="Company_type_div2">
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("file"));
                                navigate("/contractor?search=file");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.amazonaws.com/file.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Files</span>
                                <span className="file_para">
                                  Manage Drawing
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("quo"));
                                navigate("/contractor?search=quo");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Quotation</span>
                                <span className="file_para">
                                  Create Quotation
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 2nd row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("po"));
                                navigate("/contractor?search=po");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/poo.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">
                                  Purchase Order
                                </span>
                                <span className="file_para">Create PO</span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("task"));
                                navigate("/contractor?search=task");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/taskee.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Task Manager</span>
                                <span className="file_para">Manage Tasks</span>
                              </div>
                            </div>
                          </div>

                          {/* 3rd row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("report"));
                                navigate("/contractor?search=report");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/i.svg"
                                alt=""
                                style={{
                                  height: "1.5rem",
                                  marginTop: "0.3rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">
                                  Daily Project Report
                                </span>
                                <span className="file_para">Create DPR</span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("report"));
                                navigate("/contractor?search=report");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/edit_note.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">MOM</span>
                                <span className="file_para">
                                  Document Info.
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 4th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("time"));
                                navigate("/contractor?search=time");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/timeL.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Timelines</span>
                                <span className="file_para">
                                  Manage Project Timeline
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                textDecoration: "none",
                                justifyContent: "center",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("aco"));
                                navigate("/contractor?search=aco");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector.png"
                                alt=""
                                style={{
                                  height: "1.5rem",
                                  marginTop: "0.3rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Accounts</span>
                                <span className="file_para">
                                  Manage Expenses
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 5th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",

                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("po"));
                                navigate("/contractor?search=po");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/vendorr.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Vendor</span>
                                <span className="file_para">
                                  Manage Vendors
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("lead"));
                                navigate("/contractor?search=lead");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/leadss.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Manage Leads</span>
                                <span className="file_para">
                                  Manage New Leads
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 6th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("chat"));
                                navigate("/contractor?search=chat");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/forum.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Chat</span>
                                <span className="file_para">
                                  Powerful Communication
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("inventory"));
                                navigate("/contractor?search=inventory");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/inventory_2.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Inventory</span>
                                <span className="file_para">
                                  Manage Resources
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 7th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("quality"));
                                navigate("/contractor?search=quality");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/add_task.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Quality Check</span>
                                <span className="file_para">
                                  Save Time, Reduce Rework
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",

                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/phone_android.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Mobile App</span>
                                <span className="file_para">
                                  Projects on the go
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : box === "retail" ? (
                        <div className="Company_type_div2">
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("file"));
                                navigate("/retail?search=file");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.amazonaws.com/file.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Files</span>
                                <span className="file_para">
                                  Manage Drawing
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("quo"));
                                navigate("/retail?search=quo");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Quotation</span>
                                <span className="file_para">
                                  Create Quotation
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 2nd row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("po"));
                                navigate("/retail?search=po");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/poo.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">
                                  Purchase Order
                                </span>
                                <span className="file_para">Create PO</span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("task"));
                                navigate("/retail?search=task");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/taskee.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Task Manager</span>
                                <span className="file_para">Manage Tasks</span>
                              </div>
                            </div>
                          </div>

                          {/* 3rd row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("report"));
                                navigate("/retail?search=report");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/i.svg"
                                alt=""
                                style={{
                                  height: "1.5rem",
                                  marginTop: "0.3rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">
                                  Daily Project Report
                                </span>
                                <span className="file_para">Create DPR</span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                justifyContent: "center",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("report"));
                                navigate("/retail?search=report");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/edit_note.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">MOM</span>
                                <span className="file_para">
                                  Document Info.
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 4th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("time"));
                                navigate("/retail?search=time");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/timeL.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Timelines</span>
                                <span className="file_para">
                                  Manage Project Timeline
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("aco"));
                                navigate("/retail?search=aco");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector.png"
                                alt=""
                                style={{
                                  height: "1.5rem",
                                  marginTop: "0.3rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Accounts</span>
                                <span className="file_para">
                                  Manage Expenses
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 5th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("chat"));
                                navigate("/retail?search=chat");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/forum.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Chat</span>
                                <span className="file_para">
                                  Powerful Communication
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                width: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("po"));
                                navigate("/retail?search=po");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/vendorr.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Vendor</span>
                                <span className="file_para">
                                  Manage Vendors
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* 6th row */}
                          <div
                            style={{ display: "flex", marginBottom: "2rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                dispatch(featureScroll("quality"));
                                navigate("/retail?search=quality");
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/add_task.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Quality Check</span>
                                <span className="file_para">
                                  Save Time, Reduce Rework
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "0.7rem",
                                alignItems: "center",
                                width: "50%",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.ap-south-1.amazonaws.com/phone_android.svg"
                                alt=""
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                <span className="file_head">Mobile App</span>
                                <span className="file_para">
                                  Projects on the go
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        "null"
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/* <div
                className={
                  location.pathname === "/products"
                    ? "head-pricing-active"
                    : "head-pricing"
                }
                onClick={() => {
                  navigate("/products");
                  window.scroll(0, 0);
                }}
              >
                Products
              </div> */}
              {/* <div
                className={
                  location.pathname === "/pricing"
                    ? "head-pricing-active me-4"
                    : "head-pricing me-4"
                }
                onClick={() => {
                  navigate("/pricing");
                  window.scroll(0, 0);
                }}
              >
                Pricing
              </div> */}
              {/* <div
                className="head-pricing me-4"
                onClick={() => {
                  window.location.assign(
                    "https://pro.idesign.market/community/"
                  );
                  setactiveTab("act");
                }}
              >
                Community
              </div> */}
              <div
                className={changeBorder==="maga"?"head-pricing-active":"head-pricing"}
                onClick={() => {
                  window.location.assign("https://magazine.idesign.market/");
                  setchangeBorder("maga");
                }}
              >
                Magazine
              </div>

              <div
                className={changeBorder==="abou"?"head-pricing-active":"head-pricing"}
                onClick={() => {
                  navigate("/about");
                  setchangeBorder("abou");
                  
                 
                }}
              >
                   About
              </div>

              <div
                className={changeBorder==="contac"?"head-pricing-active":"head-pricing"}
                onClick={() => {
                  navigate("/contact");
                  setchangeBorder("contac");
                }}
              >
                Contact
              </div>

              {/* moreeeeeeeeeeeeeeeeeeee */}
              <div
                className={changeBorder==="Mor"?"head-pricing-active":"head-pricing"}
                onClick={() => {
                  setchangeBorder("Mor")
                }}
              >
                <Dropdown show={dropdownOpen === "more"}>
                  <Dropdown.Toggle
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                    className="px-0"
                    onMouseEnter={() => setdropdownOpen("more")}
                  >
                    <span style={{color:'#000000'}}>More</span> 
                    <FiChevronDown style={{ marginLeft: "0.5rem",color: "black" }} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      width: "45rem",
                      border: "none",
                      borderRadius: "4px",
                      backgroundColor: "#EFF3F9",
                    }}
                    onMouseLeave={() => setdropdownOpen("")}
                    className="drop2_menu"
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.5rem",
                        // paddingRight: "0.5rem",
                        gap: "0.7rem",
                        // border:'2px solid red'
                      }}
                    >
                      <div className="Find_comm_div">
                        <a
                          href="https://www.idesign.market/find-professionals"
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <div
                            className={
                              box1 === "find" ? "find_div" : "find_div_normal"
                            }
                            onMouseOver={() => setbox1("find")}
                          >
                            <div className="find_head">Find Professionals</div>
                            <p className="findd_para">
                              Unleash Your Creative Potential : Seamlessly
                              Streamline Your Interior Design Process
                            </p>
                          </div>
                        </a>

                        <a
                          href="https://www.idesign.market/community/"
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <div
                            className={
                              box1 === "comm" ? "find_div" : "find_div_normal"
                            }
                            onMouseOver={() => setbox1("comm")}
                            onClick={() => setchangeComp("comm")}
                          >
                            <div className="find_head">Community</div>
                            <p className="findd_para">
                              Streamline Your Construction Process: Enhance
                              Efficiency and Profitability
                            </p>
                          </div>
                        </a>
                      </div>
                      {/* sec div */}

                      {box1 === "find" ? (
                        <div className="Company_type_div">
                          <a
                            href="https://www.idesign.market/find-professionals/interior-designers"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            className="des_div"
                          >
                            <img
                              src="https://idesign-office.s3.amazonaws.com/shelves.svg"
                              alt=""
                            />
                            <span className="enterprise_head">
                              Interior Designer
                            </span>
                          </a>

                          <a
                            href="https://www.idesign.market/find-professionals/modular-kitchens"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            className="des_div"
                          >
                            <img
                              src="https://idesign-office.s3.ap-south-1.amazonaws.com/pallet.svg"
                              alt=""
                            />
                            <span className="enterprise_head">
                              Modular Factory
                            </span>
                          </a>

                          <a
                            href="https://www.idesign.market/find-professionals/contractors"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            className="des_div"
                          >
                            <img
                              src="https://idesign-office.s3.ap-south-1.amazonaws.com/handyman.svg"
                              alt=""
                            />
                            <span className="enterprise_head">Contractor</span>
                          </a>
                        </div>
                      ) : (
                        <div
                          style={{ width: "62%", height: "13rem" }}
                          className="comm_imge"
                        >
                          <a
                            href="https://www.idesign.market/community/"
                            target="_blank"
                          >
                            <img
                              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Frame%202611639%20%283%29.svg"
                              alt=""
                              className="comm_img"
                            />
                          </a>
                        </div>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <button className="header-signn" onClick={handleSignIn}>
                Login
              </button>
              {/* <button className="header-sign2" onClick={handleSignup}>
                Sign Up
              </button> */}
              <button
                className="header-sign ms-3"
                style={{ width: "10rem", height: "2.5rem", border: "none" }}
                onClick={handleShow}
              >
                Schedule a demo
              </button>
            </div>
          </div>
        ) : (
          <img
            src={idesign}
            alt=""
            style={{ width: "11rem", zIndex: "1", cursor: "pointer" }}
            onClick={() => {
              navigate("/");
              window.scroll(0, 0);
            }}
          />
        )}
      </div>
    </>
  );
};

export default LoginHeader;
