import React from 'react'
import styles from "./landingPageCss/landingSection7.module.css";
import mobile from "./imagesAnj/mobileImg/mob.svg";
import btn from "./imagesAnj/mobileImg/btn.png";
import play1 from "./imagesAnj/mobileImg/apple.png";
import play2 from "./imagesAnj/mobileImg/Google Play.png";


const LandingSection7 = () => {
    return (
    <>
      <div className={styles.container}>
        <div className={styles.contain}>
            <div className={styles.img_div}>
                <img src={mobile} alt="" className={styles.mobImg}/>
            </div>

            <div className={styles.content_div}>
                <div className={styles.mob_head}>Join us on Mobile</div>
                <div className={styles.para}>Download  our latest app to stay up to date with running <br /> projects and manage your team effectively</div>
                <div className={styles.input_div}> 
                    <input type="text" className={styles.firstInput} placeholder='+91'/>
                    <input type="number" className={styles.secInput} placeholder='Phone Number'/>
                   <img src={btn} alt="" className={styles.btn}/>
                </div>

                <div className={styles.appDiv}>
                    <div className={styles.app_head}>Download iDesign App from</div>
                     <img src={play2} alt="" className={styles.apple}/>
                     <img src={play1} alt="" className={styles.apple} style={{marginLeft:'1rem'}}/>
                </div>
            </div>
        </div>
      </div>
    </>
    )
}

export default LandingSection7