import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './EmailVeification.css'
import email from './emailVeri.svg'
// import { emailVerfy } from '../../../Redux/Actions/auth'
import { handleRegister } from '../../../Redux/Actions/auth'
import axios from 'axios';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { emailVerfy } from '../../../Redux/Actions/auth'

const EmailVeification = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch()
 const [otp, setOtp] = useState("")
 const location = useLocation();

  const handleChange = (e) => {
    e.preventDefault()
    if(e.target.value.length <= 4){
      setOtp(e.target.value)
    }
  }

  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  const payload = {
    deviceId: localStorage.getItem("deviceId"),
    email: localStorage.getItem("email"),
    password: localStorage.getItem("password")
  }

  const handleCheck = (e)=>{
    e.preventDefault()
    axios.post('https://email-api.idesign.market/api/verify', {
      email: localStorage.getItem("email"),
      "otp":otp
    }).then((res)=>{
      console.log(res)
      if(res.status == 200){
       dispatch(handleRegister(payload))
       localStorage.removeItem("deviceId")
       localStorage.removeItem("email")
       localStorage.removeItem("password")
       window.location.href = "/chooseprofession";
      }else if(res.status == 203){
        toast.error("Enter correct otp")
      }
    }).catch((err)=>{
      console.log(err)
    })
  }

  const goTo = () => {
    localStorage.removeItem("path")
    localStorage.setItem('token','null');
    navigateTo('/')
  }

  return (
    <>
    <ToastContainer/>
      <div className="email_main">
        <div className="email_main2">
          <div className="email_data">
            <h2 className="email_data_header">Email Verification Code</h2>
            <p className="email_data_byline">We have sent you a verification code on your registered Email id <strong>{localStorage.getItem("email")}</strong></p>
              <div>
              <form className="otp_input">
                <input type="number" className="email_input" value = {otp} onChange = {handleChange} maxLength = {4}/>
               <button className="email_verify" onClick={handleCheck}>Verify</button>
              </form>
              </div>
            <div className="email_span">
            <a className="span1" role="button" onClick={()=>{emailVerfy(payload, navigateTo)}}>Send the code again</a>
              <div className="span1" role="button" onClick={goTo}>Change the Email address</div>
            </div>
          </div>
          <img src={email} alt="svg image" className="email_img" />
        </div>
      </div>
    </>
  )
}

export default EmailVeification