import React from "react";
import "../SaasOnboarding.css";
import preview from "../SaasAssets/preview.svg";
import CountUp from "react-countup";
import styles from "./landingPageCss/landingSection1.module.css";
import { useNavigate } from "react-router-dom";
import imgSec from "./imagesAnj/section1/section1.png";
import logo1 from "./imagesAnj/section1/arq.svg";
import logo2 from "./imagesAnj/section1/essentia_logo 1.svg";
import logo3 from "./imagesAnj/section1/alsorg logo 1.svg";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Dropdown} from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";


const LandingSection1 = () => {
  const navigate = useNavigate();
  const handleAcc = () => {
    navigate("/signup");
  };
  const [show, setShow] = useState(false);
  const [dropDown, setdropDown] = useState("");
  const [anotherDropDown, setanotherDropDown] = useState("");
  const [thirdDrop, setthirdDrop] = useState("");

  const handleClose = () => {
    setDemostate({
      name: "",
      company: "",
      email: "",
      phone: "",
      companyType: "",
      companyTurnover: "",
      designation:"",
    });
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [demostate, setDemostate] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    companyType: "",
    companyTurnover: "",
    designation:"",
  });

  const handleChange = (e) => {
    let name = e.target.name;
    if (name === "phone") {
      if (e.target.value.length <= 10) {
        setDemostate((prev) => {
          return { ...prev, ["phone"]: e.target.value };
        });
      }
    } else {
      setDemostate((prev) => {
        return { ...prev, [name]: e.target.value };
      });
    }
  };

  const handleQuerySubmit = async () => {
    if (
      demostate.name?.length > 0 &&
      demostate.company?.length > 0 &&
      demostate.email?.length > 0 &&
      demostate.phone?.length > 0 &&
      demostate.companyType?.length > 0 &&
      demostate.companyTurnover?.length > 0 && 
      demostate.designation?.length>0
    )
      await axios
        .post("https://pro-api.idesign.market/user/schedule-demo", {
          name: demostate.name,
          companyName: demostate.company,
          email: demostate.email,
          phoneNumber: demostate.phone,
          companyType: demostate.companyType,
          companyTurnover: demostate.companyTurnover,
          designation: demostate.designation,
        })
        .then(function (response) {
          console.log(response);
          setShow(false);
          setShow2(true);
          setDemostate({
            name: "",
            company: "",
            email: "",
            phone: "",
            companyType: "",
            companyTurnover: "",
            designation:"",
          });
        
        })
        .catch(function (error) {
          // console.log(error.message);
          toast.error(
            "Email address or phone number you have entered is already registered with us."
          );
        });
    else {
      toast.error("Please enter all the fields");
    }
  };

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <>
       <Modal
        show={show2}
        onHide={handleClose2}
        animation={false}
        centered
        dialogClassName="modal-80w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex"
              style={{
                textAlign: "center",
                color: "#212121",
                fontWeight: "500",
              }}
            >
              Your Query has been submitted, you'll get a revert within 4 hrs!!
            </div>
            <button
              // className="mt-3"
              style={{
                background: "#3b5998",
                outline: "none",
                border: "none",
                color: "white",
                fontSize: "14px",
                // borderRadius: "50px",
                // height: "36px",
                // padding: "0.5rem 1rem",
                width: "30%",
              }}
              onClick={() => setShow2(false)}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {/* <Modal.Header closeButton className="pb-0">
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "40%",
                background: "#F0F4FF",
                display: "flex",
                flexDirection: "column",
                padding: "2rem",
                borderRadius: "4px",
                // border:'2px solid black',
              }}
            >
              <div className={styles.scheduleHead}>
                Introducing our innovative B2B Interior Design SaaS Platform
              </div>
              <div className={styles.requestHead}>Request a Demo today!</div>

              <div>
                <div className={styles.eachLine}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/check_circle.svg"
                    alt=""
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  Collaborate with other professionals
                </div>
                <div className={styles.eachLine}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/check_circle.svg"
                    alt=""
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  Improve communication with your clients
                </div>
                <div className={styles.eachLine}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/check_circle.svg"
                    alt=""
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  Streamline your interior design process
                </div>
                <div className={styles.eachLine}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/check_circle.svg"
                    alt=""
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  Stay ahead of the curve
                </div>
              </div>
            </div>

            {/* second div */}
            <div style={{ width: "60%", padding: "2rem" }}>
              <div className={styles.scheduleDemo}>Schedule a Demo</div>
              <div className="d-flex flex-column w-100">
                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Name*"
                    variant="outlined"
                    style={{ color: "rgb(164, 162, 162)" }}
                    value={demostate.name}
                    name="name"
                    onChange={handleChange}
                  />
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <TextField
                    type="number"
                    size="small"
                    id="outlined-basic"
                    label="Mobile Number*"
                    variant="outlined"
                    value={demostate.phone}
                    name="phone"
                    onChange={handleChange}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                  />
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Email Address*"
                    variant="outlined"
                    value={demostate.email}
                    name="email"
                    onChange={handleChange}
                  />
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Company Name*"
                    variant="outlined"
                    value={demostate.company}
                    name="company"
                    onChange={handleChange}
                  />
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Company Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Company Type"
                      value={demostate.companyType}
                      name="companyType"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={"General Contractor"}>
                        General Contractor
                      </MenuItem>
                      <MenuItem value={"Developer / Builder"}>
                        Developer / Builder
                      </MenuItem>
                      <MenuItem value={"Sub-Contractor"}>
                        Sub-Contractor
                      </MenuItem>
                      <MenuItem value={"Project Management Consultancy"}>
                        Project Management Consultancy
                      </MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Company Turnover
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Company Turnover"
                      value={demostate.companyTurnover}
                      name="companyTurnover"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={"oneToFiveCrore"}>1-5 crore</MenuItem>
                      <MenuItem value={"fiveTo10Crore"}>5-10 crore</MenuItem>
                      <MenuItem value={"tenToFiftyCrore"}>10-50 crore</MenuItem>
                      <MenuItem value={"FiftyToHundredCrore"}>
                        50-100 crore
                      </MenuItem>
                      <MenuItem value={"MorethanHundredCrore"}>
                        More than 100 crore
                      </MenuItem>
                      <MenuItem value={"LessthanOneCrore"}>
                        Less than 1 crore
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                    Designation
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Designation"
                      value={demostate.designation}
                      name="designation"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={"Founder/CXO"}>Founder/CXO</MenuItem>
                      <MenuItem value={"Project Manager"}>
                        Project Manager
                      </MenuItem>
                      <MenuItem value={"Planning Manager"}>
                        Planning Manager
                      </MenuItem>
                      <MenuItem value={"Site Supervisor"}>
                        Site Supervisor
                      </MenuItem>
                      <MenuItem value={"Site Engineer"}>Site Engineer</MenuItem>
                      <MenuItem value={"Student"}>Student</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="mt-1"
                    style={{
                      background: "#1D3557",
                      outline: "none",
                      border: "none",
                      color: "white",
                      fontSize: "14px",
                      borderRadius: "9px",
                      padding: "0.5rem 1rem",
                    }}
                    onClick={handleQuerySubmit}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div style={{ marginBottom: "7rem" }}>
        <div className={styles.containerr}>
          <div className={styles.content}>
            <div className={styles.topHeading}>
              One Solution for Interior and <br /> Construction Companies
            </div>
            <p className={styles.para}>
              Manage your business at one place- Onboard clients, create <br />
              and share quotations, track drawings, approvals, timelines, <br />
              tasks, purchases & much more
            </p>
            <div style={{ marginTop: "1.2rem" }}>
              <a
                href="https://pro.idesign.market/contact"
                style={{ textDecoration: "none" }}
                target="blank"
              >
                <button className={styles.btn1}>Get Started</button>
              </a>
              <button className={styles.btn2} onClick={handleShow}>
                Schedule a Demo
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              height: "85%",
              // border:'2px solid red',
            }}
          >
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/section1.png"
              alt=""
              className={styles.img1}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "15rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border:"2px solid red",
          }}
        >
          <div>
            <img
              src={logo1}
              alt=""
              style={{ width: "9rem", height: "1.5rem" }}
            />
          </div>
          <div>
            <img
              src={logo2}
              alt=""
              style={{ marginLeft: "3rem", width: "7rem", height: "1.6rem" }}
            />
          </div>
          <div>
            <img
              src={logo3}
              alt=""
              style={{ marginLeft: "3rem", width: "6rem", height: "2.5rem"}}
            />
          </div>

          <div>
            <img
              src="https://idesign-office.s3.amazonaws.com/Mask%20group.svg"F
              alt=""
              style={{ marginLeft: "3rem", width: "8rem", height: "4rem" }}
            />
          </div>

          <div>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Mask%20group%20%281%29.svg"
              alt=""
              style={{ marginLeft: "3rem", width: "8rem", height: "4rem" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingSection1;
