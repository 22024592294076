import React, { Fragment } from "react";
import { CONTACT_INFO } from "../../Constants/strings";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactSchema } from "../../Constants/formSchema";
import { submitQuery } from "../../Apis";
import { toast } from "react-toastify";
import FormInput from "../../Components/Forms/FormInput";
// import LoginHeader from "../../Lms/LmsWebComponents/SaasOnboarding/LoginHeader.jsx"
import LoginHeader from "../../Lms/LmsWebComponents/SaasOnboarding/LoginHeader";
import HamburgerMeun from "../../Lms/LmsWebComponents/SaasOnboardingMobile/HamburgerMeun";
import Footer from "../../Lms/LmsWebComponents/SaasOnboarding/PricingPage/Footer";
import FooterMobile from "../../Lms/LmsWebComponents/SaasOnboardingMobile/FooterMobile";
import { useState, useEffect } from "react";

const Contact = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const querySubmit = (values) => {
    submitQuery({
      ...values,
      type: 2,
      phoneNumber: `${values?.phoneNumber}`,
    }).then((res) => {
      toast.success("Query Sent");
      reset();
    });
  };
  return (
    <>
      {isDesktop ? <LoginHeader /> : <HamburgerMeun />}
      {isDesktop ? (
        <>
          <div className="main_contact" style={{height:"90vh"}}>
            <div className="container">
              <div className="nextSection_contact">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    // border:'2px solid red',
                  }}
                >
                  <h3>Contact us</h3>
                  <div style={{ marginTop: "0.5rem", color: "#212121" }}>
                  Fill out the form below to be contacted by our  representative
                  </div>
                </div>

                <div className="container" style={{ marginTop: "4rem" }}>
                  <div className="d-flex">
                    <div className="col-md-7 ps-5 contact_sections">
                      <div style={{ width: "60%" }}>
                        <div className="addressContact">Bangalore address</div>
                        <div className="paraContact">
                          Brigade Innovations LLP 29th & 30th Floor, World Trade
                          Center, Brigade Gateway Campus, 26/1 Dr. Rajkumar
                          Road, Malleswaram – Rajajinagar, Bengaluru - 560055
                        </div>
                      </div>

                      <div style={{ marginTop: "2rem", width: "60%" }}>
                        <div className="addressContact">Delhi address</div>
                        <div className="paraContact">
                          Plot No. 342, Mehrauli- Gurgaon Road, Sultanpur, New
                          Delhi- 110030
                        </div>
                      </div>

                      <div style={{ marginTop: "2rem", width: "60%" }}>
                        <div className="addressContact">For general info</div>
                        <div className="paraContact">info@idesign.market</div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <form onSubmit={handleSubmit(querySubmit)}>
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-6">
                              <FormInput
                                name="name"
                                label="Full Name"
                                placeholder="John keen"
                                isInvalid={!!errors?.name}
                                error={errors?.name?.message}
                                register={register("name")}
                                className="formInput"
                              />
                            </div>
                            <div className="col-md-6">
                              <FormInput
                                name="phoneNumber"
                                label="Phone Number"
                                placeholder="123456789"
                                isInvalid={!!errors?.phoneNumber}
                                error={errors?.phoneNumber?.message}
                                register={register("phoneNumber")}
                                className="formInput"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <FormInput
                                name="email"
                                label="Email"
                                placeholder="John@gmail.com"
                                isInvalid={!!errors?.email}
                                error={errors?.email?.message}
                                register={register("email")}
                                className="formInput"
                              />
                            </div>
                            <div className="col-md-6">
                              <FormInput
                                name="subject"
                                label="Company"
                                placeholder="idesign.market"
                                isInvalid={!!errors?.subject}
                                error={errors?.subject?.message}
                                register={register("subject")}
                                className="formInput"
                              />
                            </div>
                          </div>

                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Please describe what you need help with
                          </label>

                        
                         <FormInput
                            rows="3"
                            as="textarea"
                            name="data"
                            isInvalid={!!errors?.data}
                            register={register("data")}
                            error={errors?.data?.message}
                            style={{ resize: "none"}}
                           
                          />
                    
                          

                          <div>
                            <input type="checkbox" name={"isagree"} />
                            {` I agree to receive all your updates over RCS, RBM, Whatsapp & Sms`}
                          </div>
                        </div>
                        <button className="ContactBtn mt-2" type="submit">
                          SUBMIT
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="main_contact">
            <div className="container">
              <div className="nextSection_contact">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h3 style={{ fontSize: "28px" }}>Contact us</h3>
                  <div
                    style={{
                      marginTop: "0.5rem",
                      color: "#212121",
                      textAlign: "center",
                    }}
                  >
                    Fill out the form below to be contacted by our sales
                    representative
                  </div>
                </div>

                <div className="container" style={{ marginTop: "2rem" }}>
                  <div className="d-flex">
                    <div
                      className="col-md-5"
                      style={{
                        boxShadow:
                          " 0px -1px 10px rgba(0, 0, 0, 0.25), 0px 4px 20px rgba(0, 0, 0, 0.25)",
                        padding: "1rem",
                        borderRadius: "8px",
                      }}
                    >
                      <form onSubmit={handleSubmit(querySubmit)}>
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-6">
                              <FormInput
                                name="name"
                                label="Full Name"
                                placeholder="John keen"
                                isInvalid={!!errors?.name}
                                error={errors?.name?.message}
                                register={register("name")}
                                className="formInput"
                              />
                            </div>
                            <div className="col-md-6">
                              <FormInput
                                name="phoneNumber"
                                label="Phone Number"
                                placeholder="123456789"
                                isInvalid={!!errors?.phoneNumber}
                                error={errors?.phoneNumber?.message}
                                register={register("phoneNumber")}
                                className="formInput"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <FormInput
                                name="email"
                                label="Email"
                                placeholder="John@gmail.com"
                                isInvalid={!!errors?.email}
                                error={errors?.email?.message}
                                register={register("email")}
                                className="formInput"
                              />
                            </div>
                            <div className="col-md-6">
                              <FormInput
                                name="subject"
                                label="Company"
                                placeholder="idesign.market"
                                isInvalid={!!errors?.subject}
                                error={errors?.subject?.message}
                                register={register("subject")}
                                className="formInput"
                              />
                            </div>
                          </div>

                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Please describe what you need help with
                          </label>

                          <FormInput
                            rows="3"
                            as="textarea"
                            name="data"
                            isInvalid={!!errors?.data}
                            register={register("data")}
                            error={errors?.data?.message}
                            style={{ resize: "none" }}
                          />

                          <div>
                            <input type="checkbox" name={"isagree"} />
                            {` I agree to receive all your updates over RCS, RBM, Whatsapp & Sms`}
                          </div>
                        </div>
                        <button className="ContactBtnMob mt-2" type="submit">
                          SUBMIT
                        </button>
                      </form>
                    </div>
                    <div className="col-md-7 ps-5 contact_sections" style={{marginTop:'3rem'}}>
                      <div style={{ width: "60%" }}>
                        <div className="addressContact">Bangalore address</div>
                        <div className="paraContact">
                          Brigade Innovations LLP 29th & 30th Floor, World Trade
                          Center, Brigade Gateway Campus, 26/1 Dr. Rajkumar
                          Road, Malleswaram – Rajajinagar, Bengaluru - 560055
                        </div>
                      </div>

                      <div style={{ marginTop: "2rem", width: "60%" }}>
                        <div className="addressContact">Delhi address</div>
                        <div className="paraContact">
                          Plot No. 342, Mehrauli- Gurgaon Road, Sultanpur, New
                          Delhi- 110030
                        </div>
                      </div>

                      <div style={{ marginTop: "2rem", width: "60%" }}>
                        <div className="addressContact">For general info</div>
                        <div className="paraContact">info@idesign.market</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isDesktop ? <Footer /> : <FooterMobile />}
        </>
      )}
    </>
  );
};

export default Contact;
