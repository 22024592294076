import React from 'react';
import styles from "../onBoardingCss/welcome.module.css";
import { useNavigate } from 'react-router-dom';
import { useState ,useEffect} from 'react';
import axios from 'axios';

const Welcome = () => {

  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  const [getData, setgetData] = useState([]);

  const GetName=async()=>{
    await axios.get("https://pro-api.idesign.market/user/profile",{
      headers: {
        "Content-Type": "application/json",
        Authorization: authTok,
      },
    }).then(function(response){
        console.log(response?.data?.data);
        setgetData(response?.data?.data);
    }).catch(function(error){
      console.log(error);
    })
  }

  console.log(getData)
  useEffect(() => {
    GetName();
  }, [])
  
    const navigate=useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
      Welcome {getData?.companyName}, Let’s get you Onboard
        </div>
      <div style={{display:'flex',gap:"1rem",marginTop:"2rem"}}>
        <button className={styles.btn1} onClick={()=>navigate("/personal")}>Complete Profile</button>
       <a href="https://pro.idesign.market/" className={styles.btn2}  style={{textDecoration:'none'}} >Go to Dashboard</a>
      </div>
    </div>
  )
}

export default Welcome