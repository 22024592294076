import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PhotoUpload from './PhotoUpload'
import SelectRoom from './SelectRoom'
import side from './3dImages/side.svg'
import UploadRefMain from './UploadRefMain'
import completed from './3dImages/completed.svg'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { toast, ToastContainer } from "react-toastify";

const TabButtonsMob = () => {
    const autocadFile = useSelector((state) => state.threedReducer.autocadFile);
    // const [active_tab1_mob, setActive_tab1_mob] = useState(true)
    // const [active_tab2_mob, setActive_tab2_mob] = useState(false)
    // const [active_tab3_mob, setActive_tab3_mob] = useState(false)
    const [boolClicked, setBoolClicked] = useState(false)
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [cross, setCross] = useState(true)

    const handleErr = () => {
        setError("Upload a valid file")
    }
    const handleErr2 = () => {
        setError("")
    }

    const navigate = useNavigate()
    const handleNext = async () => {
        if (autocadFile?.name) {
            // console.log(autocadFile)

            localStorage.setItem("tick", true);
            const file = new FormData();
            setIsLoading(true)
            file.append("files", autocadFile.fullFile);
            await axios.post(`https://3d-api.idesign.market/api/room/request-design?designerId=${localStorage.getItem("userId")}`,
                file
            ).then((res) => {
                localStorage.setItem("apiId", res.data._id)
                setIsLoading(false)
                navigate("/3dSelectRoom")
            }).catch((err) => setError(err))
        }
        else {
            // alert("mo")
            setError("Add a cad file")
        }
    }
    const handleBack = () => {
        navigate("/3dLanding")
    }

    return (
        <>
            <div className="d-block d-md-none tabDisplay">
                <ToastContainer />
                <div className="tab_main">
                    <div className="tab_mobile">
                        <Link className="d-flex align-items-center" to="/3dLanding" style={{ textDecoration: "none", color: "black" }}>
                            <span className="d-flex align-items-center">
                                <div className="me-3 d-flex align-items-center">
                                    <img style={{ width: "6px", height: "10px", marginLeft: "8px" }} src={side} />
                                </div>
                                <div className="page-Heading">3D Feature</div>
                            </span>
                        </Link>
                    </div>

                    <div className="tabs_mob_main">
                        <div className="tab_mob1-active" >
                            {boolClicked ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : <button className="no1" >1</button>}
                            <p className="no1_line" >Upload AutoCAD file</p>
                        </div>
                        <div className="tab_mob1-unactive">
                            <button className="no2">2</button>
                            <p className="no1_line d-none">Select room & views</p>
                        </div>
                        <div className="tab_mob1-unactive">
                            <button className="no2">3</button>
                            <p className="no1_line d-none">Upload reference PPT</p>
                        </div>
                    </div>
                    {<PhotoUpload err={error} function1={handleErr} function2={handleErr2} />}
                    <div className="tab_button">
                        {isLoading ? <button className="tab_next" disabled><Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /></button> : <button className="tab_next" onClick={handleNext}>Next</button>}
                        <button className="tab_back" onClick={handleBack}>Back</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TabButtonsMob