import React, { useEffect, useRef } from "react";
import "./SaasOnboarding.css";
import closeEye from "./SaasAssets/closeEye.svg";
import eye from "./SaasAssets/eye.svg";
import { FcGoogle } from "react-icons/fc";
import { useState } from "react";
import info from "./SaasAssets/info.svg";
import { signInWithGoogle } from "../../../Config/firebase";
import { getDeviceId } from "../../../Helpers/utils";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword, socialLogin } from "../../../Apis";
import { completeProfile, handleLogin } from "../../../Redux/Actions/auth";
import { Form, Modal, Overlay, Tooltip } from "react-bootstrap";
import FormInput from "../../../Components/Forms/FormInput";
import bgmain from "./SaasAssets/bg-main.svg";
import LoginHeader from "./LoginHeader";
import SignInMob from "../SaasOnboardingMobile/SignInMob";
const SaasLogin = (props) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [modal, setModal] = useState(false);
  const [link, setLink] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    emailErr: "",
    passwordErr: "",
  });

  const [show, setShow] = useState(0);
  const [show3, setShow3] = useState(0);
  const target = useRef(null);
  const target3 = useRef(null);

  const handleChange = (e) => {
    const name = e.target.name;
    setFormData((prev) => {
      return { ...prev, [name]: e.target.value };
    });
    if (formData?.email?.length > 0) {
      setErrors((prev) => {
        return { ...prev, emailErr: "" };
      });
    }
    if (formData?.password?.length > 0) {
      setErrors((prev) => {
        return { ...prev, passwordErr: "" };
      });
    }
  };
  const handleLoginMain = () => {
    if (formData.email.length < 1) {
      setErrors((prev) => {
        return { ...prev, emailErr: "You cannot leave email empty" };
      });
      setShow(1);
    } else if (formData.password.length < 1) {
      setErrors((prev) => {
        return { ...prev, passwordErr: "You cannot leave password empty" };
      });
      setShow3(1);
    } else {
      const payload = {
        email: formData.email,
        password: formData.password,
        deviceId: `${getDeviceId()}`,
      };
      dispatch(handleLogin(payload));
    }
  };

  const googleLogin = () => {
    signInWithGoogle()
      .then((response) => {
        socialLogin({
          email: response.user.email,
          googleId: response.user.uid,
          deviceId: `${getDeviceId()}`,
        }).then((res) => {
          window.localStorage.setItem("userId", res.data._id);
          localStorage.setItem("token", res.data.accessToken);
          window.localStorage.removeItem("sessionExpire");
          dispatch(completeProfile(res?.data));
          if (res.data.isProfileComplete) {
            return navigateTo("/lead");
          }
          navigateTo("/onboard-designer");
        });
      })
      .catch((err) => {
        toast.error("Cannot authenticate! try again");
      });
  };

  const forgotPassword = (e) => {
    e?.preventDefault();
    const email = e?.target[0]?.value;
    resetPassword({ email }).then(() => setLink(e?.target[0]?.value));
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else if (passwordType === "text") {
      setPasswordType("password");
    }
  };

  // console.log(passwordType)

  const handleNew = () => {
    // props.changeTab()
    navigateTo("/signup");
  };

  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      {isDesktop ? (
        <div>
          <ToastContainer />
          <LoginHeader />
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
              setLink(null);
            }}
            centered
            className="interFont"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {link ? "Email Sent" : "Forgot Password?"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {link ? (
                <p>
                  {`We have sent an email to ${link} with a link to reset
                your password.`}
                </p>
              ) : (
                <Form onSubmit={forgotPassword}>
                  <FormInput
                    name="email"
                    label="Enter Email Address"
                    type="email"
                    placeholder="Enter Email Address"
                    required
                  />
                  <button
                    className="btn-login m-0 p-2"
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    Request a reset link
                  </button>
                </Form>
              )}
            </Modal.Body>
          </Modal>
          <div
            className="w-100 main-saas"
            style={{
              backgroundImage: `url(${bgmain})`,
              backgroundRepeat: " no-repeat",
              backgroundSize: "100%",
              backgroundPositionY: "center",
            }}
          >
            <div className="d-flex flex-column a1">
              <div className="saas-signup-header">Welcome Back!</div>

              <form
                className="flex-column d-flex"
                style={{ marginTop: "1rem" }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  <input
                    className="saas-form"
                    type="email"
                    ref={target}
                    placeholder="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.emailErr.length > 0 && (
                    //   <div className=" d-flex align-items-center" style={{ position: "absolute", left: "23rem" }}>
                    //       <div style={{
                    //           height: "0",
                    //           borderLeft: "15px solid transparent",
                    //           borderBottom: "15px solid white",
                    //           // boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
                    //           marginTop: "auto"
                    //       }}></div>
                    //       <div className="err-body d-flex align-items-center p-2" style={{ color: "#575757", fontWeight: "400", fontSize: "12px", borderRadius: "4px 4px 4px 0px", boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.1)", lineHeight: "14px", width: "13rem" }}> <img src={info} alt="" style={{ height: "15px", width: "15px", marginRight: "5px" }} />{errors.emailErr}</div>
                    //   </div>
                    <Overlay
                      target={target.current}
                      show={show}
                      placement="right"
                    >
                      <Tooltip id="overlay-example">
                        <img
                          src={info}
                          alt=""
                          style={{
                            height: "15px",
                            width: "15px",
                            marginRight: "5px",
                            marginBottom: "auto",
                          }}
                        />{" "}
                        {errors.emailErr}
                      </Tooltip>
                    </Overlay>
                  )}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  <input
                    className="saas-form mb-2"
                    type={passwordType}
                    ref={target3}
                    placeholder="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {passwordType === "password" ? (
                    <img
                      src={eye}
                      alt=""
                      className="eye"
                      onClick={togglePassword}
                    />
                  ) : (
                    <img
                      src={closeEye}
                      alt=""
                      className="eye"
                      onClick={togglePassword}
                    />
                  )}
                  {errors.passwordErr.length > 0 && (
                    //   <div className=" d-flex align-items-center" style={{ position: "absolute", left: "23rem" }}>
                    //       <div style={{
                    //           height: "0",
                    //           borderLeft: "15px solid transparent",
                    //           borderBottom: "15px solid white",
                    //           // boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
                    //           marginTop: "auto"
                    //       }}></div>
                    //       <div className="err-body d-flex align-items-center p-2" style={{ color: "#575757", fontWeight: "400", fontSize: "12px", borderRadius: "4px 4px 4px 0px", boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.1)", lineHeight: "14px", width: "13rem" }}> <img src={info} alt="" style={{ height: "15px", width: "15px", marginRight: "5px" }} />{errors.passwordErr}</div>
                    //   </div>
                    <Overlay
                      target={target3.current}
                      show={show3}
                      placement="right"
                    >
                      <Tooltip id="overlay-example">
                        <img
                          src={info}
                          alt=""
                          style={{
                            height: "15px",
                            width: "15px",
                            marginRight: "5px",
                            marginBottom: "auto",
                          }}
                        />{" "}
                        {errors.passwordErr}
                      </Tooltip>
                    </Overlay>
                  )}
                </div>
                <div style={{ width: "100%", cursor: "pointer" }}>
                  <div
                    className="forgot-main d-flex justify-content-end mb-4 mt-1"
                    onClick={() => {
                      setModal(true);
                      //   setLink(null);
                    }}
                  >
                    Forgot Password
                  </div>
                </div>
              </form>
              <button className="create222" onClick={handleLoginMain}>
                Sign In
              </button>
              <div className="or-start">
                <div className="empty-line"></div>
                <span className="saas-or">or</span>
                <div className="empty-line"></div>
              </div>
              <button className="create2-main" onClick={googleLogin}>
                {" "}
                <FcGoogle className="googgle" /> Sign In with Google
              </button>
              <div className="saas-by mt-3">
                On signing you agree to our{" "}
                <Link
                  to="/terms"
                  style={{
                    fontWeight: "500",
                    textDecoration: "underline",
                    color: "#888888",
                  }}
                >
                  
                  Terms of use
                </Link>
                and
                <Link
                  to="/privacy-policy"
                  style={{
                    fontWeight: "500",
                    textDecoration: "underline",
                    color: "#888888",
                  }}
                >
                  
                  Privacy Policy
                </Link>
              </div>
              {/* <div className="saas-by mt-3">
                Don’t have an account yet?
                <span
                  style={{
                    fontWeight: "500",
                    color: "#3B5998",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleNew}
                >
                  Sign up
                </span>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <SignInMob />
      )}
    </>
  );
};

export default SaasLogin;
