import React, { useRef } from "react";
import styles from "../onBoardingCss/companydetail.module.css";
import main from "../footerImg/task/task.png";
import icon from "../footerImg/task/Vector.png";
import icon1 from "../footerImg/task/Vector.png";
import icon2 from "../footerImg/reports/Vector.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import logo from "../footerImg/task/Frame 2611544.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import idesign from "../../NewLandingPage/imagesAnj/logo.png";
import axios from "axios";
import { Dropdown } from "react-bootstrap";

const CompanyDetails = () => {
  const [dropDown, setdropDown] = useState([]);

  const navigate = useNavigate();
  const uploadRef = useRef(null);

  const [imageData, setImageData] = useState();
  const [imageUrl, setImageUrl] = useState();

  const selectCompanyType = (ct) => {
    if (!dropDown.includes(ct)) {
      setdropDown((prev) => {
        return [...prev, ct];
      });
    }
  };

  const uploadImage = async (event) => {
    console.log(event.target.files);
    setImageData(event.target.files[0]);

    let imgs = new FormData();
    imgs.append("bucketName", "idesignchat");
    imgs.append("files", event.target.files[0]);
    await axios
      .put("https://s3-service.idesign.market/api/upload", imgs)
      .then(function (response) {
        setImageUrl(response.data.locations[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [input, setinput] = useState({
    compName: "",
    compType: "",
    gst: "",
    address: "",
  });

  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  const postData = async () => {
    const payload = new FormData();
    payload.append("companyName", input.compName);
    payload.append("companyType", dropDown);
    payload.append("gstNumber", input.gst);
    payload.append("companyLogo", imageUrl);
    payload.append("address", input.address);

    await axios
      .post(`https://pro-api.idesign.market/user/updateProfile`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (res) {
        console.log(res?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleinput = (event) => {
    const { name, value } = event.target;

    if (name === "gst") {
      if (value.length < 16) {
        setinput((prev) => {
          return {
            ...prev,
            gst: value,
          };
        });
      }
    } else {
      if (value.length < 28) {
        setinput((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    }
  };

  // company errror
  const [activeErr, setactiveErr] = useState("notActive");
  const companyError = () => {
    if (input.compName === "") {
      setactiveErr("Active");
    } else {
      setactiveErr("");
    }
    if (
      input.compName !== "" &&
      input.compType !== "" &&
      input.gst !== "" &&
      input.address !== ""
    ) {
      navigate("/complete");
    }
  };

  // company type error
  const [compTypeErr, setcompTypeErr] = useState("notActive");
  const companyTypeError = () => {
    if (dropDown.length === 0) {
      setcompTypeErr("Active");
    } else {
      setcompTypeErr("");
    }
    if (
      input.compName !== "" &&
      dropDown !== "" &&
      input.gst !== "" &&
      input.address !== ""
    ) {
      navigate("/complete");
    }
  };
  // company logo
  const [logoErr, setlogoErr] = useState("notActive");
  const companyLogoError = () => {
    if (imageUrl === undefined) {
      setlogoErr("Active");
    } else {
      setlogoErr("");
    }
    if (
      input.compName !== "" &&
      dropDown !== "" &&
      input.gst !== "" &&
      input.address !== "" &&
      imageUrl !== undefined
    ) {
      navigate("/complete");
    }
  };
  // gst error
  const [gstErr, setgstErr] = useState("notActive");
  const GStError = () => {
    if (input.gst === "") {
      setgstErr("Active");
    } else {
      setgstErr("");
    }
    if (
      input.compName !== "" &&
      input.compType !== "" &&
      input.gst !== "" &&
      input.address !== ""
    ) {
      navigate("/complete");
    }
  };

  // address error
  const [addressErr, setaddressErr] = useState("notActive");
  const AddError = () => {
    if (input.address === "") {
      setaddressErr("Active");
    } else {
      setaddressErr("");
    }
    if (
      input.compName !== "" &&
      input.compType !== "" &&
      input.gst !== "" &&
      input.address !== ""
    ) {
      navigate("/complete");
    }
  };

  // console.log(imageData)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          {/* <img src={idesign} alt="" className={styles.idesignn}/> */}
          <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "7.5rem",
              width: "80%",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%283%29.svg"
                  alt="Second slide"
                  className={styles.imgg}
                />
              </div>
              <div
                style={{ backgroundColor: "#E9EFFB", paddingLeft: "2.5rem" }}
              >
                <div>
                  <img src={icon1} alt="" className={styles.icon} />
                </div>
                <div className={styles.heading}>TASK MANAGEMENT</div>
                <div className={styles.para}>
                  Optimize resource usage and ensures that projects are
                  completed efficiently and on time.
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207465%20%282%29.svg"
                  alt="Third slide"
                  className={styles.imgg}
                />
              </div>

              <div
                style={{ backgroundColor: "#E9EFFB", paddingLeft: "2.5rem" }}
              >
                <div>
                  <img src={icon2} alt="" className={styles.icon} />
                </div>
                <div className={styles.heading}>DAILY PROJECT REPORT</div>
                <div className={styles.para}>
                  Ensures that all team members are aware of the current status
                  of the project and can make informed decisions accordingly.
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.amazonaws.com/Rectangle%207461%20%284%29.svg"
                  alt="First slide"
                  className={styles.imgg}
                />
              </div>
              <div
                style={{ backgroundColor: "#E9EFFB", paddingLeft: "2.5rem" }}
              >
                <div>
                  <img src={icon} alt="" className={styles.icon} />
                </div>
                <div className={styles.heading}>FILE MANAGER</div>
                <div className={styles.para}>
                  Easily organize and manage all of your important documents
                  with our file manager feature.
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <div className={styles.personal_head}>Company Details</div>
              <button
                className={styles.backBtn}
                onClick={() => navigate("/personal")}
              >
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button>
            </div>

            <input
              type="text"
              placeholder="Company Name"
              className={
                activeErr === "Active" ? styles.inputsErr : styles.inputs
              }
              onChange={handleinput}
              name="compName"
              value={input.compName}
            />

            <Dropdown
              className={
                compTypeErr === "Active"
                  ? styles.inputsErrDrop
                  : styles.inputsDrop
              }
            >
              <Dropdown.Toggle
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#7a7a7a",
                  width: "100%",
                  textAlign: "start",
                }}
              >
                {`${dropDown.length === 0 ? "Company Type" : dropDown}`}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item onClick={() => selectCompanyType("Build")}>
                  Build
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => selectCompanyType("Manufacturing")}
                >
                  Manufacturing
                </Dropdown.Item>
                <Dropdown.Item onClick={() => selectCompanyType("Designer")}>
                  Designer
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <input
              type="text"
              placeholder="GST Number"
              className={gstErr === "Active" ? styles.inputsErr : styles.inputs}
              onChange={handleinput}
              name="gst"
              value={input.gst}
            />

            <input
              type="text"
              placeholder="Permanent Address"
              className={
                addressErr === "Active" ? styles.inputsErr : styles.inputs
              }
              onChange={handleinput}
              name="address"
              value={input.address}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "22px",
                color: "#4d4d4d",
              }}
              className={
                logoErr === "Active" ? styles.inputsErrLogo : styles.inputsLogo
                // styles.inputsLogo
              }
            >
              {imageUrl ? (
                <a
                  style={{
                    width: "70%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textDecoration: "none",
                    color: "#9e9999",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                  target="_blank"
                  href={imageUrl}
                >
                  {imageData.name}
                </a>
              ) : (
                <div
                  style={{
                    color: "#9e9999",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                >
                  Company Logo
                </div>
              )}

              <img
                src={logo}
                alt=""
                className={styles.upload}
                onClick={() => {
                  uploadRef.current.click();
                }}
              />
            </div>
            <input
              type="file"
              className="d-none"
              ref={uploadRef}
              onChange={uploadImage}
            />

            <span className={styles.logo_text}>
              Allowed file types are .JPG, .PNG and max file size is 5 MB
            </span>
            <button
              className={styles.btn}
              onClick={() => {
                companyError();
                companyTypeError();
                companyLogoError();
                GStError();
                AddError();
                postData();
              }}
            >
              NEXT
            </button>
            <a
              href="https://pro.idesign.market/"
              className={styles.skip}
              style={{ textDecoration: "none" }}
            >
              Skip Complete Profile
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
