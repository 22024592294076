import React from "react";
import facebook from "../SaasAssets/facebook.svg";
import whatspp from "../SaasAssets/whatspp.svg";
import insta from "../SaasAssets/insta.svg";
import utube from "../SaasAssets/utube.svg";
import header from "../SaasAssets/header.svg";
import { useState } from "react";
import { submitQuery } from "../../../../Apis";
import { toast } from "react-toastify";
import { ToastContainer } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../NewLandingPage/imagesAnj/logo.png";
import icon1 from "./footerImg/Facebook.svg";
import icon2 from "./footerImg/Instagram.svg";
import icon3 from "./footerImg/Youtube.svg";
import icon4 from "./footerImg/Whatsapp.svg";

// const companyLinks = [

//     { label: "About", to: ROUTES.ABOUT_US },
//     {
//         label: "Careers",
//         to: ROUTES.CAREER,
//         external: true,
//     },
//     { label: "Privacy Policy", to: ROUTES.PRIVACY_POLICY },
//     { label: "Terms & Conditions", to: ROUTES.TERMS_AND_CONDITIONS },
//     { label: "Refund & Cancellation", to: ROUTES.REFUNDS_RETURN },
//     { label: "Cookie policy", to: ROUTES.COOKIE_POLICY },
//     { label: "Contact", to: ROUTES.CONTACT },
// ];

// const homeownersLinks = [
//     { label: "Find Professionals", to: ROUTES.PROFESSIONAL_B2B },
//     { label: "Explore Projects", to: ROUTES.EXPLORE_B2B },
//     { label: "Magazine", to: ROUTES.MAGAZINES },
//     // { label: "Sign In", to: ROUTES.LOGIN },
// ];

// const designLinks = [
//     { label: "Join iDesign Pro", to: ROUTES.ID },
//     { label: "Pricing", to: ROUTES.PRICING },
//     { label: "Sign In", to: ROUTES.LOGIN },
// ];

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const newsLetterSubmit = () => {
    if (email.length > 0) {
      submitQuery({ type: 1, data: email }).then((res) =>
        toast.success("Newsletter subscribed")
      );
    } else {
      toast.error("Please enter email");
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="" style={{ backgroundColor: "#ffffff" }}>
        <div
          className="d-flex py-5 justify-content-between"
          style={{paddingRight:"5rem",paddingLeft:'5rem'}}
        >
          <div className="d-flex flex-column mt-5 " style={{ width: "19%" }}>
            <img
              src={logo}
              style={{ width: "12rem", height: "2.2rem"}}
            />

            <div
              style={{width: "15rem", marginTop: "1rem" }}
            >
              <div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#212121",
                  }}
                >
                  Bangalore
                </div>
                <p
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.0015em",
                    color: "#3C3C3C",
                  }}
                >
                  Brigade Innovations LLP 29th & 30th Floor, World Trade Center,
                  Brigade Gateway Campus, 26/1 Dr. Rajkumar Road, Malleswaram –
                  Rajajinagar, Bengaluru - 560055
                </p>
              </div>

              <div style={{ marginTop: "1rem" }}>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#212121",
                  }}
                >
                  Delhi
                </div>
                <p
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.0015em",
                    color: "#3C3C3C",
                  }}
                >
                  Plot No. 342, Mehrauli- Gurgaon Road, Sultanpur, New Delhi-
                  110030
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex-flex-column mt-5">
            <div className="footer-header-web mb-3">Company</div>
            <Link
              style={{ color: "#0A142F", textDecoration: "none" }}
              to="/about"
            >
              <div className="footer-span-web">About </div>
            </Link>
            <a
              style={{ color: "#0A142F", textDecoration: "none" }}
              href="https://angel.co/company/idesign-market-1"
            >
              <div className="footer-span-web">Careers </div>
            </a>
            <Link
              style={{ color: "#0A142F", textDecoration: "none" }}
              to="/privacy-policy"
            >
              <div className="footer-span-web">Privacy Policy </div>
            </Link>
            <Link
              style={{ color: "#0A142F", textDecoration: "none" }}
              to="/terms"
            >
              <div className="footer-span-web">Terms & Conditions </div>
            </Link>
            <Link
              style={{ color: "#0A142F", textDecoration: "none" }}
              to="/cookie-policy"
            >
              <div className="footer-span-web">Cookie Policy </div>
            </Link>
            <Link
              style={{ color: "#0A142F", textDecoration: "none" }}
              to="/contact"
            >
              <div className="footer-span-web">Contact Us </div>
            </Link>
          </div>
          <div className="d-flex-flex-column mt-5">
            <div className="footer-header-web mb-3">Design Professionals</div>
            <Link
              style={{ color: "#0A142F", textDecoration: "none" }}
              to="/signup"
            >
              <div className="footer-span-web">Join iDesign Pro</div>
            </Link>
            <Link
              style={{ color: "#0A142F", textDecoration: "none" }}
              to="/pricing"
            >
              <div
                className="footer-span-web"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Pricing
              </div>
            </Link>

            <a
              href="https://magazine.idesign.market/"
              style={{ color: "#0A142F", textDecoration: "none" }}
            >
              <div className="footer-span-web">Magazine </div>
            </a>
            {/* <Link
              style={{ color: "#0A142F", textDecoration: "none" }}
              to="/login"
            >
              <div className="footer-span-web">Sign In </div>
            </Link> */}
          </div>
          <div className="d-flex-flex-column mt-5">
            <div className="footer-header-web mb-3">Contact Us</div>
            <div className="footer-span-web">mail :support@idesign.market</div>
            <div className="footer-span-web">Contact :+91-6584987256</div>
          </div>

          <div
            className="d-flex mt-5"
            style={{  flexDirection: "column" }}
          >
            <div>
              <div className="footer-header-web mb-3">Social</div>
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.facebook.com/idesign.market/"
              >
                <img style={{ height: "22px" }} src={icon1} />
              </a>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=%2B919289347893&text=Hi%2C+pls+resolve+my+below+query&app_absent=0"
              >
                <img
                  style={{ height: "22px", marginLeft: "1rem" }}
                  src={icon4}
                />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/iDesign.market/"
              >
                <img
                  style={{ height: "22px", marginLeft: "1rem" }}
                  src={icon2}
                />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCZYsSoot4r9eZSPJk6F7-xw"
              >
                <img
                  style={{ height: "22px", marginLeft: "1rem" }}
                  src={icon3}
                />
              </a>
            </div>
          </div>
        </div>
        <div
          className=" d-flex justify-content-center footer_bottomWeb"
          style={{ background: "#ffffff" }}
        >
          <div
            style={{
              paddingTop: "2rem",
              paddingBottom: "4rem",
              width: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTop: "2px solid #f7f6f6",
              fontsize: "14px",
            }}
          >
            {new Date().getFullYear().toString()} © iDesign | All rights
            reserved
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
