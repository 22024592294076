import React, { useEffect, useState } from 'react';
import HeaderOnboard from './HeaderOnboard';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { emailVerfy, handleRegister } from '../../../Redux/Actions/auth';
import axios from 'axios';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify'

export default function OtpVerifyMob() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch()
  const [otp, setOtp] = useState("")
  const location = useLocation();


  const handleChange = (e) => {
    e.preventDefault()
    if (e.target.value.length <= 4) {
      setOtp(e.target.value)
    }
  }

  useEffect(() => {
    localStorage.setItem("pathname", location.pathname)
  }, [])

  const payload = {
    deviceId: localStorage.getItem("deviceId"),
    email: localStorage.getItem("email"),
    password: localStorage.getItem("password")
  }

  const handleCheck = (e) => {
    e.preventDefault()
    axios.post('https://email-api.idesign.market/api/verify', {
      email: localStorage.getItem("email"),
      "otp": otp
    }).then((res) => {
      console.log(res)
      if (res.status == 200) {
        dispatch(handleRegister(payload))
        localStorage.removeItem("deviceId")
        localStorage.removeItem("email")
        localStorage.removeItem("password")
        window.location.href = "/profilebuild";
      } else if (res.status == 203) {
        toast.error("Enter correct otp")
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const goTo = () => {
    localStorage.removeItem("pathname")
    localStorage.setItem('token', 'null');
    navigateTo('/signup')
  }
  
    return (
        
        <div className='w-100'>
            <ToastContainer />  
            <HeaderOnboard />
            <div className='welcome'>
                OTP Verification
            </div>
        <div className='otp mt-3'>We have sent an One Time Password on your registered Email ID <strong>{localStorage.getItem("email")} </strong> </div>
            <div className='mt-4 input_div'>
                <input type="number" className='input_tag' value={otp} onChange={handleChange}  />
                <div className='btn_back mt-4'>
                    <button className='btn-login' style={{ background: '#3B5998', color: '#ffffff', padding: '0.7rem',width:'99%',margin:'0rem',fontSize:'12px' }} onClick={handleCheck}>Verify</button>
                </div>
            </div>
            <div className='resend' onClick={() => { emailVerfy(payload, navigateTo) }}>Resend OTP</div>
            <div className='change' onClick={goTo}>Change Email address</div>
        </div>
    )
}