import React from "react";
import styles from "../../onBoardingCss/DashboardCssNew/dashboardMid.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Task from "./Task";
import Pendencies from "./Pendencies";
import Financials from "./Financials";

import UpdatesTab from "./UpdatesTab";
import ManageLeads from "./ManageLeads";
import DashBoardLast from "./DashBoardLast";

const DashBoardMiddle = () => {
  const [tabActive, settabActive] = useState("active");

  const [TaskData, setTaskData] = useState([]);
 
  const AllTaskData = async () => {
    await axios
      .get(
        `https://pmt.idesign.market/api/projects/get-project-summary?userId=${localStorage.getItem(
          "userId"
        )}&status=1`
      )
      .then(function (response) {
        let x = response?.data;
        x.forEach((elem) => {
          elem["openTab"] = "task";
        });
        console.log(response?.data);
        setTaskData([...x]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    AllTaskData();
  }, []);

  const changeTab = (tab, index) => {
    let x = TaskData;
    x[index]["openTab"] = tab;
    setTaskData([...x]);
  };
  return (
    <>
      <div className={styles.head_div}>
        <div
          className={
            tabActive === "active"
              ? styles.heading_tab_Active
              : styles.heading_tab
          }
          onClick={() => settabActive("active")}
        >
          Active Projects
        </div>
        <div
          className={
            tabActive === "active2"
              ? styles.heading_tab_Active
              : styles.heading_tab
          }
          onClick={() => settabActive("active2")}
        >
          Manage Leads
        </div>
      </div>

      <div className={styles.container}>
        {tabActive === "active" ? (
          TaskData.length > 0 ? (
            TaskData?.slice(0, 5)?.map((curelem, index) => {
              let final = Math.round(
                (new Date(curelem.endDate).getTime() - new Date().getTime()) /
                  (1000 * 60 * 60 * 24)
              );
              return (
                <>
                  <div className={styles.content_div_show}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      <div style={{ display: "flex", gap: "2rem" }}>
                        <div>
                          <div className={styles.admin_name}>
                            {curelem?.projectName}
                          </div>
                          <div className={styles.place}>
                            {curelem?.projectLocation}
                          </div>
                        </div>
                        {final < 0 ? (
                          <button className={styles.daysBtnRed}>
                            {final} Days Delayed
                          </button>
                        ) : (
                          <button className={styles.daysBtn}>
                            {final} Days to Handover
                          </button>
                        )}
                      </div>
                      <div className={styles.lastUpdated}>
                        Last Updated :
                        {new Date(curelem?.lastUpdated).toLocaleDateString(
                          "en-En",
                          { year: "numeric", month: "short", day: "numeric" }
                        )}
                      </div>
                    </div>

                    {/* three btn div */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "1rem",
                      }}
                    >
                      <div className={styles.threeBtnDiv1}>
                        <div>My Tasks</div>
                        <div>{curelem?.myTasks?.length}</div>
                      </div>

                      <div className={styles.threeBtnDiv2}>
                        <div>Pendency</div>
                        <div>{curelem?.pendencies?.length}</div>
                      </div>

                      <div className={styles.threeBtnDiv3}>
                        <div>Delayed Task</div>
                        <div>{curelem?.delayTasks?.length}</div>
                      </div>
                    </div>

                    {/* table */}
                    <div className={styles.tableDiv}>
                      <div className={styles.tableTabs}>
                        <div
                          className={
                            curelem.openTab === "task"
                              ? styles.eachTabActive
                              : styles.eachTab
                          }
                          onClick={() => changeTab("task", index)}
                        >
                          Tasks
                        </div>
                        <div
                          className={
                            curelem.openTab === "pendency"
                              ? styles.eachTabActive
                              : styles.eachTab
                          }
                          onClick={() => changeTab("pendency", index)}
                        >
                          Pendencies
                        </div>
                        <div
                          className={
                            curelem.openTab === "financial"
                              ? styles.eachTabActive
                              : styles.eachTab
                          }
                          onClick={() => changeTab("financial", index)}
                        >
                          Financials
                        </div>
                        <div
                          className={
                            curelem.openTab === "updates"
                              ? styles.eachTabActive
                              : styles.eachTab
                          }
                          onClick={() => changeTab("updates", index)}
                        >
                          Updates
                        </div>
                      </div>
                      <div style={{ overflowY: "scroll" }}>
                        {curelem.openTab === "task" ? (
                          <Task task={curelem} />
                        ) : curelem.openTab === "pendency" ? (
                          <Pendencies pendency={curelem} />
                        ) : curelem.openTab === "financial" ? (
                          <Financials projectId={curelem?.projectId}/>
                        ) : curelem.openTab === "updates" ? (
                          <UpdatesTab projectId={curelem?.projectId}/>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div>
              <div className={styles.content_div}>
                <div className={styles.noProject}>No Project to show</div>
                <a
                  href="https://pro.idesign.market/pmt-beta/"
                  className={styles.btn}
                  style={{ textDecoration: "none" }}
                >
                  Create a Project
                </a>
              </div>

              <DashBoardLast />
            </div>
          )
        ) : (
          <ManageLeads />
        )}
      </div>
    </>
  );
};

export default DashBoardMiddle;
