import React from "react";
import styles from "../onboardingMobCss/personalMob.module.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

const PersonalMob1 = () => {
  const [dropDown, setdropDown] = useState("");
  const navigate = useNavigate();
  const [input, setinput] = useState({
    fname: "",
    profession: "",
    city: "",
    email: "",
    number: "",
  });

  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";
  const postData = async () => {
    const payload = new FormData();
    payload.append("firstName", input.fname);
    payload.append("email", input.email);
    payload.append("type", dropDown);
    payload.append("city", input.city);
    payload.append("phoneNumber", input.number);

    await axios
      .post(`https://pro-api.idesign.market/user/updateProfile`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (res) {
        console.log(res?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleInput = (event) => {
    const { name, value } = event.target;
    if (name === "number") {
      if (value.length < 11) {
        setinput((prev) => {
          return {
            ...prev,
            number: value,
          };
        });
      }
    } else {
      if (value.length < 28) {
        setinput((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    }
  };

  const [getData, setgetData] = useState([]);

const GetName=async()=>{
  await axios.get("https://pro-api.idesign.market/user/profile",{
    headers: {
      "Content-Type": "application/json",
      Authorization: authTok,
    },
  }).then(function(response){
      console.log(response?.data?.data);
      setgetData(response?.data?.data);
  }).catch(function(error){
    console.log(error);
  })
}

console.log(getData)
useEffect(() => {
  GetName();
}, [])

useEffect(() => {
  let x=input;
  x["fname"]=getData?.companyName;
  x["city"]=getData?.city;
  x["email"]=getData?.email;
  x["number"]=getData?.phoneNumber;
  
}, [getData])

  // fullname validation
  const [fullnameErr, setfullnameErr] = useState("notActive");

  const fullnameError = () => {
    if (input.fname === "") {
      setfullnameErr("Active");
    } else {
      setfullnameErr("");
    }
    if (
      input.fname !== "" &&
      input.profession !== "" &&
      input.number !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };

  //profession
  const [professErr, setprofessErr] = useState("notActive");
  const professionError = () => {
    if (dropDown === "") {
      setprofessErr("Active");
    } else {
      setprofessErr("");
    }
    if (
      input.fname !== "" &&
      dropDown !== "" &&
      input.number !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };

  // phone no. validation
  const [phoneErr, setphoneErr] = useState("notActive");
  const phoneNumberError = () => {
    if (input.number === "") {
      setphoneErr("Active");
    } else {
      setphoneErr("");
    }
    if (
      input.fname !== "" &&
      input.profession !== "" &&
      input.number !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };
  // city validation
  const [cityErr, setcityErr] = useState("notActive");
  const cityError = () => {
    if (input.city === "") {
      setcityErr("Active");
    } else {
      setcityErr("");
    }
    if (
      input.fname !== "" &&
      input.profession !== "" &&
      input.number !== "" &&
      input.city !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };

  // email validate
  const [emErr, setemErr] = useState("notActive");
  const EmailError = () => {
    if (input.email === "") {
      setemErr("Active");
    } else if (emailErr != "") {
      setEmailErr("Invalid Email");
    } else {
      setemErr("");
      setEmailErr("");
    }
    if (
      input.fname !== "" &&
      input.profession !== "" &&
      input.number !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };

  //   email validation
  const [emailErr, setEmailErr] = useState("");
  const emailValidation = () => {
    return /\S+@\S+\.\S+/.test(input.email);
  };

  useEffect(() => {
    const mailE = emailValidation();
    if (!mailE) {
      setEmailErr("Invalid Email");
    } else {
      setEmailErr("");
    }
  }, [input.email]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              // border:"2px solid red",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
            // interval={10000000}
          >
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.amazonaws.com/Rectangle%207461%20%284%29.svg"
                  alt="First slide"
                  className={styles.imgg}
                />
              </div>
              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>FILE MANAGER</div>
                <div className={styles.para}>
                  Easily organize and manage all of your important documents
                  with our file manager feature.
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%283%29.svg"
                  alt="Second slide"
                  className={styles.imgg}
                />
              </div>
              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>TASK MANAGEMENT</div>
                <div className={styles.para}>
                  Optimize resource usage and ensures that projects are
                  completed efficiently and on time.
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207465%20%282%29.svg"
                  alt="Third slide"
                  className={styles.imgg}
                />
              </div>

              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>DAILY PROJECT REPORT</div>
                <div className={styles.para}>
                  Ensures that all team members are aware of the current status
                  of the project and can make informed decisions accordingly.
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <div className={styles.personal_head}>Personal Details</div>
            </div>
            <input
              type="text"
              placeholder="Full Name"
              className={
                fullnameErr === "Active" ? styles.inputsErr : styles.inputs
              }
              value={input.fname}
              name="fname"
              required
              onChange={handleInput}
            />

            <Dropdown
              className={
                professErr === "Active"
                  ? styles.inputsErrDrop
                  : styles.inputsDrop
              }
            >
              <Dropdown.Toggle
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#000000ac",
                  width: "100%",
                  textAlign: "start",
                }}
              >
                {dropDown === 2
                  ? "Contractor"
                  : dropDown === 1
                  ? "Designer"
                  : dropDown === 3
                  ? "Modular Factory"
                  : "Profession"}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item onClick={() => setdropDown(2)}>
                  Contractor
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setdropDown(1)}>
                  Designer
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setdropDown(3)}>
                  Modular Factory
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <input
              type="text"
              placeholder="E-Mail Address"
              className={emErr === "Active" ? styles.inputsErr : styles.inputs}
              value={input.email}
              name="email"
              required
              onChange={handleInput}
            />

            <input
              type="text"
              placeholder="City"
              className={
                cityErr === "Active" ? styles.inputsErr : styles.inputs
              }
              value={input.city}
              name="city"
              required
              onChange={handleInput}
            />

            <input
              type="number"
              placeholder="Phone Number"
              className={
                phoneErr === "Active" ? styles.inputsErr : styles.inputs
              }
              value={input.number}
              name="number"
              required
              onChange={handleInput}
            />

            <button
              className={styles.btn}
              onClick={() => {
                fullnameError();
                professionError();
                phoneNumberError();
                cityError();
                EmailError();
                postData();
              }}
              // disabled={
              //   input.fname === "" ||
              //   input.profession === "" ||
              //   input.number === "" ||
              //   input.email === "" ||
              //   emailErr
              // }
            >
              NEXT
            </button>
            <a
              href="https://pro.idesign.market/"
              className={styles.skip}
              style={{ textDecoration: "none" }}
            >
              Skip Complete Profile
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalMob1;
