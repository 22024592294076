import React from 'react';
import { useState, useEffect } from "react";
import HeaderMob from "../onboardingMob/HeaderMob";
import WelcomeMob from "../onboardingMob/WelcomeMob";
import Welcome from "../onBoarding/Welcome";

const WelcomeCombined = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  return (
    <>
    {isDesktop? <Welcome/>:<>
      <HeaderMob/>    
      <WelcomeMob/>
    </>}
    
    </>
  )
}

export default WelcomeCombined;