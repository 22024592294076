const B2B_URL = "https://www.idesign.market";

export const ROUTES = {
  APP: "/",
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  PRODUCTS: "/products",
  PRICING: "/pricing",
  // BRAND_OFFERS: "/brand-offers",
  PROFILE_BUILD: "/profile-build",
  ABOUT_US: "/aboutidesign",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE_POLICY: "/cookie-policy",
  TERMS_AND_CONDITIONS: "/terms",
  TERMS_INTERNAL: "/terms-condition",
  REFUNDS_RETURN: "/refunds",
  REQUEST: "/request",
  CONTACT: "/contact",

  ID: "/",

  DASHBOARD: "/dashboard",
  UPLOAD_PROJECT: "/upload-project",
  LEADS: "/leads",
  ORDER_PLACED: "/order-placed",
  CHECKOUT: "/checkout",
  PLANS: "/plans",
  PROJECT_SUMMARY: "/project-summary",
  TAG_PROJECT: "/tag-project",
  DISCOUNT_DOCUMENT: "/dicount-document",
  BRAND: "/brand",
  BRAND_DETAILS: "/brand-details",
  MY_PROFILE: "/my-profile",
  COMPLETE_PROFILE: "/complete-profile",
  ALL_PROJECTS: "/all-projects",
  STEP_PLAN: "/step-plan",
  GO_PREMIUM: "/go-premium",
  CONTRACTOR_PROFILE: "/contractor-profile",
  ADD_PHOTOS: "/add-photos",

  BRAND_OFFERS: `${B2B_URL}/brands`,
  MAGAZINES: `https://magazine.idesign.market/`,
  PROFESSIONAL_B2B: "https://www.idesign.market/find-professionals",
  // PROFESSIONAL_B2B: `${B2B_URL}/findprofessionals`,
  EXPLORE_B2B: "https://www.idesign.market/explore-projects",
  // EXPLORE_B2B: `${B2B_URL}/exploreprojects`,
  COMMUNITY: "https://pro.idesign.market/community/",
  CAREER: "https://angel.co/company/idesign-market-1",
};
