// import React, { useState } from "react";
// import Testimonial from "../../../Components/Common/Testimonial";
// import mobileone from "../../../Assets/profile/mobileone.svg";
// import build from "../../../Assets/Product/build.svg";
// import genrate from "../../../Assets/Product/genrate.svg";
// import bqq from "../../../Assets/Product/bqq.svg";
// import { Link, Navigate } from "react-router-dom";
// import { ROUTES } from "../../../Router/routes";
// import { Form, Row, Col } from "react-bootstrap";
// import FormInput from "../../../Components/Forms/FormInput";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { profileBuildSchema } from "../../../Constants";
// import { useDispatch, useSelector } from "react-redux";
// import { completeProfile } from "../../../Redux/Actions/auth";
// import {
//   SIGN_UP_TYPE,
//   CITIES_DROPDOWN,
//   WORK_EXPERIENCE_DROPDOWN,
// } from "../../../Constants/enums";
// import { updateUserProfile } from "../../../Apis";
// import { toast } from "react-toastify";
import React from "react";
import firstPageImage from "../../Images/profileBuildImage.svg";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import context from "react-bootstrap/esm/AccordionContext";
import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import { updateUserProfile } from "../../Apis";
import backgroundImage from "../../Images/backgroundImage.png"
import DesignerProfileWeb from "../OnboardingWebScreens/DesignerProfileWeb";
import { useLocation } from "react-router-dom";

const cities = ["Ahmedabad", "Bengaluru", "Chandigarh", "Chennai", "Delhi", "Faridabad", "Ghaziabad", "Gurgaon", "Hyderabad", "Indore", "Jaipur", "Kolkata", "Lucknow", "Mumbai", "Noida", "Pune"];

const OemProfileBuildMob = () => {
  const [initialData, setInitialData] = useState({
    // prof: "Interior Designer",
    company: "",
    mobile: "",
    isWhatsapp: "",
    whatsappNumber: "",
    city: "",
    pin: "",
    // website: "",
    workExp: "",
    NumberOfProjectsDone: "",
    website: "",
  });

  const [errors, setErrors] = useState({
    profErr: "",
    companyErr: "",
    mobErr: "", 
    whaErr: "",
    cityErr: "",
    pinErr: "",
    workExpErr: "",
    projNumErr: "",
  });
  const [isWhatsappNum, setIsWhatsappNum] = useState(true);

  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  useEffect(() => {
    isWhatsappNum && setInitialData({ ...initialData, whatsappNumber: initialData.mobile });
    isWhatsappNum == false && setInitialData({ ...initialData, whatsappNumber: "" });
  }, [isWhatsappNum]);

  function handleFunction(e) {
    let name = e.target.name;
    
    if (name === "company") {
      if (e.target.value.length <= 30) {
        setInitialData((prev) => {
          return { ...prev, ["company"]: e.target.value };
        });
      }
    }
    else if (name === "pin") {
      if (e.target.value.length <= 6) {
        setInitialData((prev) => {
          return { ...prev, ["pin"]: e.target.value };
        });
      }
    }
    else if (name === "mobile") {
      if (e.target.value.length <= 10) {
        setInitialData((prev) => {
          return { ...prev, ["mobile"]: e.target.value };
        });
      }
    }
    else if (name === "whatsappNumber") {
      if (e.target.value.length <= 10) {
        setInitialData((prev) => {
          return { ...prev, ["whatsappNumber"]: e.target.value };
        });
      }
    }
    else {
      setInitialData((prev) => {
        return { ...prev, [name]: e.target.value };
      });
    }
  }

  function handleCheck(e) {
    if (e.target.checked) {
      setInitialData({ ...initialData, whatsappNumber: initialData.mobile })
    } else {
      setInitialData({ ...initialData, whatsappNumber: "" })
    }
    // e.target.checked = !e.target.checked
    setIsWhatsappNum(!isWhatsappNum);
  }

  function handleClick() {
    const data = JSON.stringify(initialData);
  }

  function handleSubmit1(event) {
    event.preventDefault();
    if (initialData.company.length < 1) {
      setErrors((prev) => {
        return { ...prev, companyErr: "You cannot leave company name empty" };
      });
    } else if (initialData.mobile.length < 10) {
      setErrors((prev) => {
        return { ...prev, mobErr: "Invalid mobile number" };
      });
    } 
    // else if (initialData.whatsappNumber.length < 10) {
    //   setErrors((prev) => {
    //     return { ...prev, whaErr: "Invalid whatsapp number" };
    //   });
    // }
    else if (initialData.pin.length < 1 || initialData.pin.length > 6) {
      setErrors((prev) => {
        return { ...prev, pinErr: "Invalid PIN" };
      });
    } else if (initialData.city.length < 1) {
      setErrors((prev) => {
        return { ...prev, cityErr: "Please select a city" };
      });
    } else if (initialData.workExp.length < 1) {
      setErrors((prev) => {
        return { ...prev, workExpErr: "Please select work experience" };
      });
    } else if (initialData.NumberOfProjectsDone.length < 1) {
      setErrors((prev) => {
        return { ...prev, projNumErr: "Please select number of projects" };
      });
    } else {
      context.setData((pre) => ({ ...pre, userData: initialData }));
      const userData = context.data.userData;
      // const type = userData?.prof == "Interior Designer" ? 1 : 2;
      // console.log(userData)
      const payload = new FormData();
      // payload.append("")
      payload.append("NumberOfProjectsDone", initialData.NumberOfProjectsDone);
      payload.append("city", initialData.city);
      payload.append("companyName", initialData.company);
      payload.append("phoneNumber", initialData.mobile);
      payload.append("pinCode", initialData.pin);
      payload.append("type", 3);
      // payload.append("webSite", localStorage.getItem(""));
      payload.append("whatsappNumber", isWhatsappNum ? initialData.mobile : initialData.whatsappNumber);
      payload.append("workExperience", initialData.workExp);
      updateUserProfile(payload).then((res) => {
        console.log(res);
        navigateTo("/oemwardrobe")
        // if(localStorage.getItem("type") == 1){
        //     navigateTo("/comp2");
        // }else if(localStorage.getItem("type") == 2){
        //     navigateTo("/falseceiling")
        // }else{
        //     navigateTo("/")
        // }
      });
    }
  }
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const exceptThisSymbols2 = ["~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "+", "=", "[", "]", "'", ";", "/", "{", "}", "|", , "<", ">", "?"]
 
  const [inputChange, setInputChange] = useState("");
  const [places, setPlaces] = useState("");


  const handleChange = async (e) => {

    setInputChange(e.target.value);

    const response = await axios.get(`https://pro-api.idesign.market/api/getLocations?q=${e.target.value}`, {
    }).then((res) => {
      // console.log(res);
      setPlaces(res.data.data.predictions);
      // console.log(places);
    }).catch((err) => {

      console.log(err);
    })
  }


  const handleSelect = (index, name) => {
    
    setInputChange(places[index].description);
    setInitialData((prev) => {
      return { ...prev, [name]: inputChange};
    });

    setPlaces(null)
  }
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center" style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <form className="main-container overflow-hidden bg-white" >
          <div className="w-100 p-3 mt-4 upper-panel">
            <div className="header1">
              <div className="header-image">
                <img style={{ width: "13rem" }} src={firstPageImage} alt="" />
              </div>
              <div className="header-text">
                Create your listing in a few <br />
                easy steps
              </div>
              <div className="header-subtext mt-2">(These details will be visible to clients)</div>
            </div>
            <div className="input-fields">
              {/* <select value={initialData.prof} style={initialData.prof === "" ? {color: "#888888"} : {color: "black"}} onChange={handleFunction} name="prof" className="f">
                <option value="">Choose your Profession</option>
                <option value="Interior Designer">Interior Designer</option>
                <option value="Contractor">Contractor</option>
              </select> */}
              {/* {initialData.prof.length < 1 && <span className="error">{errors.profErr}</span>} */}

              <input onChange={handleFunction} onKeyDown={(e) =>
                exceptThisSymbols2.includes(e.key) && e.preventDefault()} name="company" value={initialData.company} className="f" type="text" placeholder="Company name or Full Name" />
              {initialData.company.length < 1 && <span className="error" style={{ marginTop: "0px", fontSize: "10px" }}>{errors.companyErr}</span>}

              <input onChange={handleFunction} name="mobile" value={initialData.mobile} className="f" required type="number" placeholder="Mobile Number (10 Digit)" onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()} />
              {initialData.mobile.length < 10 && <span className="error" style={{ marginTop: "0px", fontSize: "10px" }}>{errors.mobErr}</span>}

              <div className="checkbox">
                <input onChange={handleCheck} name="isWhatsapp" checked={isWhatsappNum} className="me-2 whatsappCheckbox" type="checkbox" id="whatsappCheck" />
                <label htmlFor="whatsappCheck" style={{ fontSize: "14px", margin: "10px 0", color: "#888888" }}>This is my Whatsapp Number</label>
              </div>    {!isWhatsappNum && <input name="whatsappNumber" onChange={handleFunction} value={initialData.whatsappNumber} className="f" type="number" placeholder="Enter your Whatsapp number" onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                // {initialData.whatsappNumber.length < 10 && <span className="error" style={{ marginTop: "0px", fontSize: "10px" }}>{errors.whaErr}</span>}
              }

              <div className=" cityContainer ">
                
                <div className="w-50 px-2 pr-0" >

                  <select style={initialData.city === "" ? { color: "#888888", marginRight: "20%" } : { color: "black", marginRight: "20%" }} name="city" value={initialData.city} onChange={handleFunction} className=" f">
                    <option value="">City</option>
                    {cities.map((city, i) => {
                      return (
                        <option key={i} value={city}>
                          {city}
                        </option>
                      );
                    })}
                  </select>

{/* 
                  <input className="f" style={{ width: "100%", border: " 1px solid #DDDDDD",  fontSize:'14px', marginLeft:'-7px' }} onChange={handleChange} name="place" value={inputChange} type="text" placeholder="Search City" />

                  {places && inputChange.length > 0 && <div style={{ border: '1px solid #c1c1c1', padding: '2px', borderRadius: '3px', position:"absolute", backgroundColor:'white', width:'88%' }}>
                    
                    {places.length !== 0 ? places.map((element, index) => {
                      return (

                        <div role="button" id="place" onClick={() => handleSelect(index, "city")} style={{ borderRadius: '3px' , padding:"0px 8px"}} >{element.description}</div>
                      )
                    }) : <p style={{padding: '6px', textAlign: "center"}}>No places found</p>}
                  </div>} */}

                </div>

                <div className="w-50 px-2 pl-0">
                  <input onChange={handleFunction} name="pin" value={initialData.pin} className="f" type="number" placeholder="Pin code" onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                </div>

              </div>
              {initialData.pin.length < 1 && <span className="error" style={{ marginTop: "0px", fontSize: "10px" }}>{errors.pinErr}</span>}
              {initialData.city.length < 1 && <span className="error" style={{ marginTop: "0px", fontSize: "10px" }}>{errors.cityErr}</span>}
              {/* <input onChange={handleFunction} name="website" value={initialData.website} className="f" type="text" placeholder="Your Website link" /> */}
              <select style={initialData.workExp === "" ? { color: "#888888" } : { color: "black" }} onChange={handleFunction} name="workExp" value={initialData.workExp} className="f">
                <option value="">Work Experience (Years)</option>
                <option value="0-2">0-2 years</option>
                <option value="2-5">2-5 years</option>
                <option value="Above 5">Above 5 years</option>
              </select>
              {initialData.workExp.length < 1 && <span className="error" style={{ marginTop: "0px", fontSize: "10px" }}>{errors.workExpErr}</span>}

              <select style={initialData.NumberOfProjectsDone === "" ? { color: "#888888" } : { color: "black" }} onChange={handleFunction} name="NumberOfProjectsDone" value={initialData.NumberOfProjectsDone} className="f">
                <option value="">How many project have you done yet ?</option>
                <option value="Less than 25">less than 25</option>
                <option value="25 to 50">25 to 50</option>
                <option value="50 to 100">50 to 100</option>
                <option value="More than 100">More than 100</option>
              </select>
              {initialData.NumberOfProjectsDone.length < 1 && <span className="error" style={{ marginTop: "0px", fontSize: "10px" }}>{errors.projNumErr}</span>}
            </div>
          </div>
          <div style={{ width: "100%" }} className="btn-container1_profile ">
            <button style={{ width: "100%", marginBottom: "1rem", marginLeft: "1rem", marginRight: "1rem" }} type="submit" className="nextbutton" onClick={handleSubmit1}>
              Next
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default OemProfileBuildMob;

// planId : {type: Schema.ObjectId, sparse: true, ref : 'Plans'},
//     planBuyDate : {type: Date},
//     planExpireDate : {type: Date},
//     type : {type: Number, sparse: true,default:1}, // 1-designer,2-contractor
//     fullName : {type: String, trim: true, sparse: true}, // first + last
//     firstName : {type: String, trim: true},
//     lastName : {type: String, trim: true},
//     phoneNumber :{type:String,sparse:true},
//     countryCode :{type:String,default :"+91"},
//     link:{type:String},
//     services : {
//         isResidential: {type: Boolean, default: false},
//         isOffice: {type: Boolean, default:false},
//         isShowrooms: {type: Boolean, default:false},
//         isHighEndRetail: {type: Boolean, default:false},
//         isFarmHouse: {type: Boolean, default:false},
//         isFactoryInteriors: {type: Boolean, default:false},
//         isHotels: {type: Boolean, default:false},
//         isRestaurants: {type: Boolean, default:false},
//     },
//     designStyle : {
//         isScandinavian: {type: Boolean, default: false},
//         isGlamAndLuxurious: {type: Boolean, default:false},
//         isBohemian: {type: Boolean, default:false},
//         isModernMinimal: {type: Boolean, default:false},
//         isIndian: {type: Boolean, default:false},
//         isRustic: {type: Boolean, default:false},
//         isClassic: {type: Boolean, default:false},
//         isIndustrial: {type: Boolean, default:false},
//     },
//     interiors: {type:String},
//     email: {type: String, trim: true,  index : true},
//     googleId: {type: String},
//     companyId: {type: String},  // from sheet
//     companyName: {type: String},
//     city: {type: String},
//     address: {type: String},
//     pinCode: {type: String},
//     profession: {type: String},
//     workExperience: {type: String},
//     password: {type: String},
//     discountForDesigner: {type: Number}, // in percentage for contractor only
//     fees : {
//         zoomPrice: {type: Number}, // for designer
//         designRoomPrice: {type: Number},// for designer
//         designAreaPrice: {type: Number},// for designer
//         designFeePercentage: {type: Number},// for designer

//         minimumProjectValue: {type: Number}, // for contractor
//         popFee: {type: Number},// for contractor
//         tilesFixFee: {type: Number},// for contractor
//         paintFee: {type: Number},// for contractor
//         wardrobeAlmiraFee: {type: Number},// for contractor
//         kitchenFee: {type: Number},// for contractor

//     },
//     isWhatsapp: {type: Boolean,default:true},
//     whatsappNumber: {type: String},
//     isEmailVerified: {type: Boolean, default: false},
//     isPhoneVerified: {type: Boolean, default: false},
//     isProfileComplete: {type: Boolean, default: false},
//     isPricesAdded: {type: Boolean, default: false},
//     isProjectsAdded: {type: Boolean, default: false},
//     lastSeen: {type: Date},
//     freeLeads : { type:Number , default: 0},
//     lastLeadAssignedAt : {type: Date},
//     isleadLimitReached : {type:Boolean,default : false},
//     projectCount : {type: Number,index:true},
//     masterRateFile : { // for contractor only
//         original  : {type : String,default : ""},
//         thumbnail : {type : String,default : ""}
//     },
//     imageUrl : {
//         original  : {type : String,default : ""},
//         thumbnail : {type : String,default : ""}
//     },
//     coverImage : {
//         original  : {type : String,default : ""},
//         thumbnail : {type : String,default : ""}
//     },
//     addedFrom : {type: Number, default : 1}, //1 - by web/App, 2 - by vendor
//     preferredLanguage : {type: String, default : Config.APP_CONSTANTS.DATABASE.APP_LANGUAGE.English},
//     isBlocked: {type: Boolean, default: false},
//     isDeleted: {type: Boolean, default: false}