import React from "react";
import styles from "../onboardingMobCss/profileMob.module.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

const ProfileList = () => {
  const navigate = useNavigate();
  const [dropDown, setdropDown] = useState("");
  const [dropDown2, setdropDown2] = useState("");
  const [input, setinput] = useState({
    exp: "",
    project: "",
    fees: "",
    squt: "",
  });

  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  const postData = async () => {
    const payload = new FormData();
    payload.append("workExperience", input.exp);
    payload.append("NumberOfProjectsDone", input.project);
    payload.append(
      "fees",
      JSON.stringify({
        designAreaPrice: input.squt,
        designRoomPrice: input.fees,
      })
    );

    await axios
      .post(`https://pro-api.idesign.market/user/updateProfile`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (res) {
        console.log(res?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleinput = (event) => {
    const { name, value } = event.target;
    if (name === "squt") {
      if (value.length < 6) {
        setinput((prev) => {
          return {
            ...prev,
            squt: value,
          };
        });
      }
    } else if (name === "fees") {
      if (value.length < 7) {
        setinput((prev) => {
          return {
            ...prev,
            fees: value,
          };
        });
      }
    } else {
      setinput((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  //   experience error
  const [professErr, setprofessErr] = useState("notActive");
  const professionError = () => {
    if (dropDown === "") {
      setprofessErr("Active");
    } else {
      setprofessErr("");
    }
    if (
      input.exp !== "" &&
      dropDown !== "" &&
      input.project !== "" &&
      input.fees !== "" &&
      input.squt
    ) {
      navigate("/create");
    }
  };

  //   project error
  const [projectErr, setprojectErr] = useState("notActive");
  const ProjectError = () => {
    if (dropDown2 === "") {
      setprojectErr("Active");
    } else {
      setprojectErr("");
    }
    if (
      input.exp !== "" &&
      dropDown !== "" &&
      input.project !== "" &&
      input.fees !== "" &&
      input.squt
    ) {
      navigate("/create");
    }
  };

  //   fees error
  const [feesErr, setfeesErr] = useState("notActive");
  const feesVal = () => {
    if (input.fees === "") {
      setfeesErr("active");
    } else {
      setfeesErr("");
    }

    if (
      input.exp !== "" &&
      input.project !== "" &&
      input.fees !== "" &&
      input.squt !== ""
    ) {
      navigate("/create");
    }
  };

  //   squarefeet error
  const [squareErr, setsquareErr] = useState("notActive");
  const square = () => {
    if (input.squt === "") {
      setsquareErr("active");
    } else {
      setsquareErr("");
    }

    if (
      input.exp !== "" &&
      input.project !== "" &&
      input.fees !== "" &&
      input.squt !== ""
    ) {
      navigate("/create");
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          <Carousel
            style={{
              display: "flex",
              width: "85%",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
            // interval={10000000}
          >
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.amazonaws.com/Rectangle%207461%20%284%29.svg"
                  alt="First slide"
                  className={styles.imgg}
                />
              </div>
              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>FILE MANAGER</div>
                <div className={styles.para}>
                  Easily organize and manage all of your important documents
                  with our file manager.
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%283%29.svg"
                  alt="Second slide"
                  className={styles.imgg}
                />
              </div>
              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>TASK MANAGEMENT</div>
                <div className={styles.para}>
                  Optimize resource usage and ensures that projects are
                  completed efficiently and on time.
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207465%20%282%29.svg"
                  alt="Third slide"
                  className={styles.imgg}
                />
              </div>

              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>DAILY PROJECT REPORT</div>
                <div className={styles.para}>
                  Ensures that all team members are aware of the current status
                  of the project.
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <div className={styles.personal_head}>Profile Listing</div>
            </div>

            <Dropdown
              className={
                professErr === "Active"
                  ? styles.inputsErrDrop
                  : styles.inputsDrop
              }
            >
              <Dropdown.Toggle
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#4d4d4d",
                  width: "100%",
                  textAlign: "start",
                }}
              >
                {dropDown === "con"
                  ? "0-2"
                  : dropDown === "des"
                  ? "2-5"
                  : dropDown === "fac"
                  ? "Above 5"
                  : "Total Experience"}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item
                  onClick={() => {
                    setinput((prev) => {
                      return {
                        ...prev,
                        ["exp"]: "0-2",
                      };
                    });
                    setdropDown("con");
                  }}
                >
                  0-2
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setinput((prev) => {
                      return {
                        ...prev,
                        ["exp"]: "2-5",
                      };
                    });
                    setdropDown("des");
                  }}
                >
                  2-5
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setinput((prev) => {
                      return {
                        ...prev,
                        ["exp"]: "Above 5",
                      };
                    });
                    setdropDown("fac");
                  }}
                >
                  Above 5
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className={
                projectErr === "Active"
                  ? styles.inputsErrDrop
                  : styles.inputsDrop
              }
            >
              <Dropdown.Toggle
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#4d4d4d",
                  width: "100%",
                  textAlign: "start",
                }}
              >
                {dropDown2 === "con"
                  ? "Less than 25"
                  : dropDown2 === "des"
                  ? "25 to 50"
                  : dropDown2 === "fac"
                  ? "50 to 100"
                  : dropDown2 === "4"
                  ? " More than 100"
                  : "Number Project done"}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item
                  onClick={() => {
                    setinput((prev) => {
                      return {
                        ...prev,
                        ["project"]: "Less than 25",
                      };
                    });
                    setdropDown2("con");
                  }}
                >
                  Less than 25
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setinput((prev) => {
                      return {
                        ...prev,
                        ["project"]: "25 to 50",
                      };
                    });
                    setdropDown2("des");
                  }}
                >
                  25 to 50
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setinput((prev) => {
                      return {
                        ...prev,
                        ["project"]: "50 to 100",
                      };
                    });
                    setdropDown2("fac");
                  }}
                >
                  50 to 100
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setinput((prev) => {
                      return {
                        ...prev,
                        ["project"]: "More than 100",
                      };
                    });
                    setdropDown2("4");
                  }}
                >
                  More than 100
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <input
              type="number"
              placeholder="Design Fees per Room"
              className={
                feesErr === "active" ? styles.inputsErr : styles.inputs
              }
              onChange={handleinput}
              value={input.fees}
              name="fees"
            />
            <input
              type="number"
              placeholder="Design Fees per Sq.ft"
              className={
                squareErr === "active" ? styles.inputsErr : styles.inputs
              }
              onChange={handleinput}
              value={input.squt}
              name="squt"
            />

            <button
              className={styles.btn}
              onClick={() => {
                professionError();
                ProjectError();
                feesVal();
                square();
                postData();
              }}
            >
              NEXT
            </button>
            <a
              href="https://pro.idesign.market/"
              className={styles.skip}
              style={{ textDecoration: "none" }}
            >
              Skip Project Listing
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileList;
