import React from 'react'
import img1 from '../../Images/DesignerProfessionPic.png'
import img2 from '../../Images/ContractorProfessionPic.png'
import img3 from '../../Images/ModularFactoryPic.png'

import firstPageImage from '../../Images/HomePage.png'


import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import context from "react-bootstrap/esm/AccordionContext";
import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import OemProfileBuildMob from './oemProfileBuildMob'
import { updateUserProfile } from "../../Apis";
import { useLocation } from 'react-router-dom'
// import backgroundImage from "../../../Images/backgroundImage.png"

const cities = ["Ahmedabad", "Bengaluru", "Chandigarh", "Chennai", "Delhi", "Faridabad", "Ghaziabad", "Gurgaon", "Hyderabad", "Indore", "Jaipur", "Kolkata", "Lucknow", "Mumbai", "Noida", "Pune"];


export default function OemProfileBuild() {
  const context = useContext(OnboardingContext);
  const [initialData, setInitialData] = useState({
    prof: "Interior Designer",
    company: "",
    mobile: "",
    isWhatsapp: "",
    whatsappNumber: "",
    city: "",
    pin: "",
    website: "",
    workExp: "",
    NumberOfProjectsDone: "",
    website: "",
  });

  const [errors, setErrors] = useState({
    profErr: "",
    companyErr: "",
    mobErr: "",
    whaErr: "",
    cityErr: "",
    pinErr: "",
    workExpErr: "",
    projNumErr: "",
  });
  const [isWhatsappNum, setIsWhatsappNum] = useState(true);


  const navigateTo = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  useEffect(() => {
    isWhatsappNum && setInitialData({ ...initialData, whatsappNumber: initialData.mobile });
    isWhatsappNum == false && setInitialData({ ...initialData, whatsappNumber: "" });
  }, [isWhatsappNum]);

  function handleFunction(e) {
    let name = e.target.name;

    if (name === "company") {
      if (e.target.value.length <= 30) {
        setInitialData((prev) => {
          return { ...prev, ["company"]: e.target.value };
        });
      }
    }
    else if (name === "pin") {
      if (e.target.value.length <= 6) {
        setInitialData((prev) => {
          return { ...prev, ["pin"]: e.target.value };
        });
      }
    }
    else if (name === "mobile") {
      if (e.target.value.length <= 10) {
        setInitialData((prev) => {
          return { ...prev, ["mobile"]: e.target.value };
        });
      }
    }
    else if (name === "whatsappNumber") {
      if (e.target.value.length <= 10) {
        setInitialData((prev) => {
          return { ...prev, ["whatsappNumber"]: e.target.value };
        });
      }
    }
    else {
      setInitialData((prev) => {
        return { ...prev, [name]: e.target.value };
      });
    }
  }

  function handleCheck(e) {
    if (e.target.checked) {
      setInitialData({ ...initialData, whatsappNumber: initialData.mobile })
    } else {
      setInitialData({ ...initialData, whatsappNumber: "" })
    }
    // e.target.checked = !e.target.checked
    setIsWhatsappNum(!isWhatsappNum);
  }

  function handleClick() {
    const data = JSON.stringify(initialData);
  }

  function handleSubmit1(event) {
    event.preventDefault();
    // navigateTo("/interiorservicesdesignerweb");
    if (initialData.prof.length < 1) {
      setErrors((prev) => {
        return { ...prev, profErr: "Please select a profession" };
      });
    } else if (initialData.company.length < 1) {
      setErrors((prev) => {
        return { ...prev, companyErr: "You cannot leave company name empty" };
      });
    } else if (initialData.mobile.length < 10) {
      setErrors((prev) => {
        return { ...prev, mobErr: "Invalid mobile number" };
      });
    } 
    // else if (initialData.whatsappNumber.length < 10) {
    //   setErrors((prev) => {
    //     return { ...prev, whaErr: "Invalid whatsapp number" };
    //   });
    // }
    else if (initialData.pin.length < 1 || initialData.pin.length > 6) {
      setErrors((prev) => {
        return { ...prev, pinErr: "Invalid PIN" };
      });
    } else if (initialData.city.length < 1) {
      setErrors((prev) => {
        return { ...prev, cityErr: "Please select a city" };
      });
    } else if (initialData.workExp.length < 1) {
      setErrors((prev) => {
        return { ...prev, workExpErr: "Please select work experience" };
      });
    } else if (initialData.NumberOfProjectsDone.length < 1) {
      setErrors((prev) => {
        return { ...prev, projNumErr: "Please select number of projects" };
      });
    } else {
      context.setData((pre) => ({ ...pre, userData: initialData }));
      const userData = context.data.userData;
      const type = userData?.prof == "Interior Designer" ? 1 : 2;
      // console.log(userData)
      const payload = new FormData();
      // payload.append("")
      payload.append("NumberOfProjectsDone", initialData.NumberOfProjectsDone);
      payload.append("city", initialData.city);
      payload.append("companyName", initialData.company);
      payload.append("phoneNumber", initialData.mobile);
      payload.append("pinCode", initialData.pin);
      payload.append("type", 3);
      // payload.append("webSite", localStorage.getItem(""));
      payload.append("whatsappNumber", isWhatsappNum ? initialData.mobile : initialData.whatsappNumber);
      payload.append("workExperience", initialData.workExp);
      updateUserProfile(payload).then((res) => {
        console.log(res);
        navigateTo("/oemwardrobe");
      });
    }
  }

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const exceptThisSymbols2 = ["~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "+", "=", "[", "]", "'", ";", "/", "{", "}", "|", , "<", ">", "?"]

  const [inputChange, setInputChange] = useState("");
  const [places, setPlaces] = useState("");

  const handleChange = async (e) => {

    setInputChange(e.target.value);

    const response = await axios.get(`https://pro-api.idesign.market/api/getLocations?q=${e.target.value}`, {
    }).then((res) => {
      // console.log(res);
      setPlaces(res.data.data.predictions);
      console.log(places);
    }).catch((err) => {

      console.log(err);
    })
  }


  const handleSelect = (index, name) => {

    setInputChange(places[index].description);
    setInitialData((prev) => {
      return { ...prev, [name]: inputChange};
    });
    setPlaces(null)
  }


  return (
    <>
      {window.screen.width < "768" ?
        <OemProfileBuildMob />
        :
        <div className="main-designerprofileContainer-web d-flex justify-content-center" style={{ background: "#EBF0F9"}}>
          <div style={{ background: "white" }} className="main-div row">
            <div className="chooseprofessionweb col d-flex justify-content-center align-items-center">
              <div className="header1">
                <div className="header-image">
                  <img style={{ width: "50%", marginBottom: "20px" }} src={firstPageImage} alt="" />
                </div>
                <h3 style={{ fontWeight: "500", fontSize: "32px", width: "96%", marginBottom: "20px" }} className="header-text">
                  Create your listing in a few easy steps

                </h3>
                <div style={{ fontWeight: "500", fontSize: "14px", color: "#383F45", textAlign: "center" }} className="header-subtext">These details will be visible to clients while <br /> connecting with you</div>
              </div>
            </div>
            <div style={{ background: "#F7F7F7", borderRadius: "10px" }} className="col d-flex justify-content-center align-items-center flex-column">
              <form onSubmit={handleSubmit1} className="col-9 deignerprofile-main-container">
                <div className="designerprofile-input-fields">
                  <input onChange={handleFunction} onKeyDown={(e) =>
                    exceptThisSymbols2.includes(e.key) && e.preventDefault()} name="company" value={initialData.company} className="designprofile-input" required type="text" placeholder="Company name or Full Name" size={10} />
                  {initialData.company.length < 1 && <span className="error">{errors.companyErr}</span>}

                  <input onChange={handleFunction} name="mobile" value={initialData.mobile} className="designprofile-input" required type="number" placeholder="Mobile Number (10 Digit)" onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                  {initialData.mobile.length < 10 && <span className="error">{errors.mobErr}</span>}

                  <div className="designerprofile-checkbox ">
                    <input style={{ border: "2px solid #DDDDDD", marginBottom: "2px" }} onChange={handleCheck} name="isWhatsapp" checked={isWhatsappNum} className="" type="checkbox" />
                    <div style={{ fontSize: "12px", marginLeft: "0.4rem", color: "#a7a7a7", marginTop: "0.6rem" }}>This is my Whatsapp Number</div>
                  </div>
                  {!isWhatsappNum && <input name="whatsappNumber" onChange={handleFunction} value={initialData.whatsappNumber} className="f" type="number" placeholder="Enter your Whatsapp number" onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                    // {initialData.whatsappNumber.length < 10 && <span className="error">{errors.whaErr}</span>}
                  }
                  <div style={{ border: "none", paddingLeft: "0" }} className="designprofile-input designerprofile-cityContainer ">
                    <div style={{ width: "50%", paddingRight: "5px" }}>


                    <select style={{ width: "100%", height: "2rem", border: " 1px solid #DDDDDD", borderRadius: "3px", fontSize: "12px", color: "#888888", paddingLeft: "16px" }} name="city" value={initialData.city} onChange={handleFunction} className="">
                      <option value="">City</option>
                      {cities.map((city, i) => {
                        return (
                          <option style={{ fontSize: "12px" }} key={i} value={city}>
                            {city}
                          </option>
                        );
                      })}
                    </select>

                    {/* Abhay code */}
{/* 
                      <input style={{ width: "100%", height: "2rem", border: " 1px solid #DDDDDD", borderRadius: "3px", paddingLeft: "16px" }} onChange={handleChange} name="place" value={inputChange} type="text" placeholder="Search City" />


                      {places && inputChange.length > 0 && <div style={{ border: '1px solid #c1c1c1', padding: '2px', borderRadius: '3px', position:"absolute", backgroundColor:'white', width:'23%' }}>
                        
                        {places.length !== 0 ? places.map((element, index) => {
                          return (

                            <div role="button" id="place" onClick={() => handleSelect(index,  "city")} style={{ borderRadius: '3px' , padding:"0px 8px"}} >{element.description}</div>
                          )
                        
                      }) : <p style={{padding: '6px', textAlign: "center"}}>No places found</p>}
                      </div>} */}

                    </div>
                    <div style={{ width: "50%", paddingLeft: "5px" }}>
                      <input style={{ width: "100%", height: "2rem", border: " 1px solid #DDDDDD", borderRadius: "3px", paddingLeft: "16px" }} onChange={handleFunction} name="pin" value={initialData.pin} className="" type="number" placeholder="Pin code" onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                    </div>
                  </div> 
                  {initialData.city.length < 1 && <span className="error">{errors.cityErr}</span>}
                  {initialData.pin.length < 6 && <span className="error">{errors.pinErr}</span>}
        
                  <input onChange={handleFunction} name="website" value={initialData.website} className="designprofile-input" type="text" placeholder="Your Website link (Optional)" />
                  <select style={{ fontSize: "12px",  color: "#888888" }} onChange={handleFunction} name="workExp" value={initialData.workExp} className="designprofile-input">
                    <option value="">Work Experience (Years)</option>
                    <option value="0-2">0-2</option>
                    <option value="2-5">2-5</option>
                    <option value="Above 5">Above 5</option>
                  </select>
                  {initialData.workExp.length < 1 && <span className="error">{errors.workExpErr}</span>}

                  <select style={{ fontSize: "12px", color: "#888888" }} onChange={handleFunction} name="NumberOfProjectsDone" value={initialData.NumberOfProjectsDone} className="designprofile-input">
                    <option value="">How many project have you done yet ?</option>
                    <option value="less than 25">less than 25</option>
                    <option value="25 to 50">25 to 50</option>
                    <option value="50 to 100">50 to 100</option>
                      <option value="More than 100">More than 100</option>
                  </select>
                  {initialData.NumberOfProjectsDone.length < 1 && <span className="error">{errors.projNumErr}</span>}
                  <button onClick={handleSubmit1} style={{ background: "#3B5998", height: "2rem", marginTop: "2rem", borderRadius: "5px", border: "none", color: "white", fontSize: "12px" }} className="next designprofile-input">
                    Next
                  </button>
                </div>

                {/* <div style={{ width: "100%" }} className="btn-container ">
            <button style={{background:"#3B5998"}}  type="submit" className="next designprofile-input">
              Next
            </button>
          </div> */}
              </form>
            </div>
          </div>
        </div>
      }
    </>
  )
}
