import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import uploadRef from './3dImages/UploadRef.svg'
import UploadReference0 from './UploadReference0'
import UploadReference2 from './UploadReference2'
import { useDispatch, useSelector } from 'react-redux'
import { stateChange, uploadRefImg, UploadPdf, clearAll } from '../Lms/Actions/threedActions'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import FileResizer from 'react-image-file-resizer'
import { Spinner } from 'react-bootstrap'
import { toast, ToastContainer } from "react-toastify";


const UploadReference1 = () => {
    const uploadImageRef = useRef()
    const [isDesktop, setDesktop] = useState(window.innerWidth > 767);
    const refImgArr = useSelector((state) => state.threedReducer.uploadRefImg)
    const refImgArr1 = useSelector((state) => state.threedReducer.uploadRefImg1)
    const refImgArr2 = useSelector((state) => state.threedReducer.uploadRefImg2)
    const refImgArr3 = useSelector((state) => state.threedReducer.uploadRefImg3)
    const refImgArr4 = useSelector((state) => state.threedReducer.uploadRefImg4)
    const refImgArr5 = useSelector((state) => state.threedReducer.uploadRefImg5)
    const refImgArr6 = useSelector((state) => state.threedReducer.uploadRefImg6)
    const refImgArr7 = useSelector((state) => state.threedReducer.uploadRefImg7)
    const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
    const api_id = useSelector((state) => state.threedReducer.apiID);
    const [isLoading, setIsLoading] = useState(false)
    const [temp, setTemp] = useState([]);
    const [error, setError] = useState("")
    // let g = 0
    // let o = false
    // let q = ''

    const updateMedia = () => {
        setDesktop(window.innerWidth > 767);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    const naviagte = useNavigate()
    useEffect(() => {
        if (!localStorage.getItem("livingInput1") && !localStorage.getItem("bedroomInput1") && !localStorage.getItem("bathroomInput1") && !localStorage.getItem("kitchenInput1") && !localStorage.getItem("dInput1") && !localStorage.getItem("bathroomInput1") && !localStorage.getItem("dInput2") && !localStorage.getItem("dInput3") && !localStorage.getItem("dInput4")) {
            // console.log("yes")
            naviagte("/3dCart")
        }
    }, [])

    var [g, setG] = useState(0)
    const [o, setO] = useState(false)
    const [q, setQ] = useState('')


    const stateC1 = useSelector((state) => state.threedReducer.stateChange)
    const dispatch = useDispatch();
    const [val, setVal] = useState([])
    const [apiState, setApiState] = useState(false)
    // console.log(api_id)
    const [error1, setError1] = useState(false)
    const [error2, setError2] = useState(false)
    const [error3, setError3] = useState(false)
    const [error4, setError4] = useState(false)
    const [error5, setError5] = useState(false)
    const [error6, setError6] = useState(false)
    const [error7, setError7] = useState(false)
    const [error8, setError8] = useState(false)

    const handleUpload = (e) => {
        if (stateC1 === "state1") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                    setError1(false)
                    setError("")
                }
                else {
                    setError1(true)
                }
            }
        }
        if (stateC1 === "state2") {
            // console.log(e.target.files)
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                    setError2(false)
                    setError("")
                }
                else {
                    setError2(true)
                }
            }
        }
        if (stateC1 === "state3") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                    setError3(false)
                    setError("")
                }
                else {
                    setError3(true)
                }
            }
        }
        if (stateC1 === "state4") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                    setError4(false)
                    setError("")
                }
                else {
                    setError4(true)
                }
            }
        }
        if (stateC1 === "state5") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                    setError5(false)
                    setError("")
                }
                else {
                    setError5(true)
                }
            }
        }
        if (stateC1 === "state6") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                    setError6(false)
                    setError("")
                }
                else {
                    setError6(true)
                }
            }
        }
        if (stateC1 === "state7") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                    setError7(false)
                    setError("")
                }
                else {
                    setError7(true)
                }
            }
        }
        if (stateC1 === "state8") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                    setError8(false)
                    setError("")
                }
                else {
                    setError8(true)
                }
            }
        }
    }

    // console.log(refImgArr)
    const navigate = useNavigate()
    var description = "No description"

    const imgArr = [
        { roomPics: refImgArr, roomN: localStorage.getItem("livingInput1") },
        { roomPics: refImgArr1, roomN: localStorage.getItem("bedroomInput1") },
        { roomPics: refImgArr2, roomN: localStorage.getItem("bathroomInput1") },
        { roomPics: refImgArr3, roomN: localStorage.getItem("kitchenInput1") },
        { roomPics: refImgArr4, roomN: localStorage.getItem("dInput1") },
        { roomPics: refImgArr5, roomN: localStorage.getItem("dInput2") },
        { roomPics: refImgArr6, roomN: localStorage.getItem("dInput3") },
        { roomPics: refImgArr7, roomN: localStorage.getItem("dInput4") },
    ]

    const fArr = imgArr.filter((currElem) => {
        return currElem.roomN !== ""
    })
    // console.log(fArr)

    const mArr = [
        { roomName: localStorage.getItem("livingInput1"), stateName: "state1" },
        { roomName: localStorage.getItem("bedroomInput1"), stateName: "state2" },
        { roomName: localStorage.getItem("bathroomInput1"), stateName: "state3" },
        { roomName: localStorage.getItem("kitchenInput1"), stateName: "state4" },
        { roomName: localStorage.getItem("dInput1"), stateName: "state5" },
        { roomName: localStorage.getItem("dInput2"), stateName: "state6" },
        { roomName: localStorage.getItem("dInput3"), stateName: "state7" },
        { roomName: localStorage.getItem("dInput4"), stateName: "state8" }
    ]

    const a = JSON.parse(localStorage.getItem("room_ids"))
    // console.log(a)

    // const [newArr, setNewArr] =useState([])

    const newArr = fArr?.map((d, i) => {
        return { roomDetails: d, Rid: a[i] }
    })

    // useEffect(() => {
    //     const newArr2 = fArr?.map((d, i) => {
    //         return { roomDetails: d, Rid: a[i] }
    //     })
    //     // setNewArr(newArr1)
    // }, [JSON.parse(localStorage.getItem("room_ids"))])


    // console.log(newArr[0].roomDetails)

    const handleSubmit = () => {
        // console.log(newArr[0]?.roomDetails)
        var arr = []
        arr = newArr.map((item) => {
            return item
        })
        // console.log(arr)
        var pics = arr.map((item2) => {
            return item2.roomDetails?.roomPics
        })
        // console.log(pics)

        var isTrue = pics.every((currElem) => {
            if (currElem.length > 0) {
                return true
            }
            return false
        })

        // console.log(isTrue)

        // if (isTrue === false){
        //     return;
        // }

        // else{
        //     alert("yo")
        // }

        //description mandatory condition
        // if (arr.includes('') || arr.length === 0 || arr.includes(undefined)) {
        //     return;
        // }
        // console.log(arr)
        if (isTrue === false) {
            // toast.error("Upload pictures of all the rooms selected")
            setError("Upload pictures of all the rooms selected")
        }
        else {
            setIsLoading(true)
            if (localStorage.getItem("saveLater")) {
                newArr.map(async (data) => {
                    const files = new FormData();
                    const t = data.roomDetails.roomPics.map((d2) => {
                        const filet = d2.image.fullFile;
                        files.append("files", filet)
                        return files

                    });
                    const t1 = data.roomDetails.roomPics.map((d2) => {
                        if (d2?.desc === "" || d2?.desc?.length === 0 || d2?.desc === undefined) {
                            files.append("description", description)
                            return files
                        }
                        else {
                            files.append("description", d2.desc)
                            return files
                        }
                    });

                    await axios.put(`https://3d-api.idesign.market/api/room/add-room-images?id=${localStorage.getItem("apiId")}&roomNameId=${data.Rid.id}`, files
                    ).then((res) => {
                        setError("")
                        console.warn(res)
                        delete files.files;
                        delete files.description;
                    }).catch((err) => setError(err))

                })
                setTimeout(async () => {
                    await axios.put(`https://3d-api.idesign.market/api/room/add-pdf-for-design?id=${localStorage.getItem("apiId")}`).then((res) => {
                        console.warn(res, 1)
                        localStorage.removeItem('key')
                        localStorage.removeItem("livingInput1")
                        localStorage.removeItem("bedroomInput1")
                        localStorage.removeItem("bathroomInput1")
                        localStorage.removeItem("kitchenInput1")
                        localStorage.removeItem("dInput1")
                        localStorage.removeItem("dInput2")
                        localStorage.removeItem("dInput3")
                        localStorage.removeItem("dInput4")
                        // localStorage.removeItem("room_ids")
                        dispatch(clearAll())
                        localStorage.removeItem("saveLater")
                        setIsLoading(false)
                        navigate("/3dFinal")
                    }).catch((err) => console.warn(err))

                }, 4000);
            }
            else {
                newArr.map(async (data) => {
                    const files = new FormData();
                    const t = data.roomDetails.roomPics.map((d2) => {
                        const filet = d2.image.fullFile;
                        files.append("files", filet)
                        return files

                    });
                    const t1 = data.roomDetails.roomPics.map((d2) => {
                        if (d2?.desc === "" || d2?.desc?.length === 0 || d2?.desc === undefined) {
                            files.append("description", description)
                            return files
                        }
                        else {
                            files.append("description", d2.desc)
                            return files
                        }
                    });

                    await axios.put(`https://3d-api.idesign.market/api/room/add-room-images?id=${localStorage.getItem("apiId")}&roomNameId=${data.Rid.id}`, files
                    ).then((res) => {
                        setError("")
                        console.warn(res)
                        delete files.files;
                        delete files.description;
                    }).catch((err) => setError(err))

                })
                setTimeout(async () => {
                    await axios.put(`https://3d-api.idesign.market/api/room/add-pdf-for-design?id=${localStorage.getItem("apiId")}`).then((res) => {
                        console.warn(res)
                        localStorage.removeItem('key')
                        localStorage.removeItem("livingInput1")
                        localStorage.removeItem("bedroomInput1")
                        localStorage.removeItem("bathroomInput1")
                        localStorage.removeItem("kitchenInput1")
                        localStorage.removeItem("dInput1")
                        localStorage.removeItem("dInput2")
                        localStorage.removeItem("dInput3")
                        localStorage.removeItem("dInput4")
                        // localStorage.removeItem("room_ids")
                        dispatch(clearAll())
                        localStorage.removeItem("saveLater")
                        setIsLoading(false)
                        navigate("/3dCart")
                    }).catch((err) => console.warn(err))
                }, 4000);
            }
        }

    }
    // console.log(stateC1)


    useEffect(() => {
        if (localStorage.getItem("key")) {
            for (let f = 0; f < mArr?.length; f++) {
                if (mArr[f].roomName != '') {
                    let id1 = parseInt(mArr[f]?.stateName[5]) - 1
                    //  console.log(id1, q)
                    if (id1.toString() != '') {
                        var x1 = document?.getElementById(id1)
                        x1.onclick = () => {
                            setO(true)
                            if (id1 != q) {
                                dispatch(stateChange(mArr[f].stateName))
                            }
                        }
                    }

                }
            }

            for (let k = 0; k < mArr?.length; k++) {
                if (mArr[k].roomName != '') {
                    let id_ = parseInt(mArr[k]?.stateName[5]) - 1
                    if (id_.toString() != '' && o == false) {
                        //  console.log('I am true')
                        var a = document.getElementById(id_)
                        setQ(id_)
                        a.click()
                    }
                    break
                }
            }

        }


    }, [mArr])



    return (
        <>
            <ToastContainer />
            <div className="uploadReference_main">
                <div className="upload_top w-100" style={{ width: "", overflowX: 'scroll' }}>
                    
                    {mArr.map((item, index) => (item?.roomName?.length > 0 ? <div className="" style={{ width: "fit-content" }}><button id={index} className={stateC1 === item.stateName ? "uploadRef_active" : "uploadRef_unactive"} onClick={() => dispatch(stateChange(item.stateName))}>{item.roomName}</button> </div> : ""))}
                   
                </div>
                {stateC1 === 'state1' ? refImgArr?.length > 0 ? <UploadReference2 boolError={error1} wordError={error} /> :
                    <div className="uploadRef_picMain flex-column d-flex" >
                        <div className={error1 || error !== "" ? "uploaRef_pic-err" : "uploaRef_pic"} onClick={() => { uploadImageRef.current.click() }}>
                            <input type="file" accept="image/png, image/jpeg, image/jpg" ref={uploadImageRef} className="uploadRef_input d-none" onChange={handleUpload} multiple />
                            <img src={uploadRef} alt="" className="uploadRef_svg" />
                            <><p className="uploadRef_line">Drag and drop your photos here</p>
                            </>
                        </div>
                        {error1 ? <span className='err-line'>Upload a valid file</span> : error !== "" ? <span className='err-line'>{error}</span> : ""}
                    </div>
                    : stateC1 === 'state2' ? refImgArr1?.length > 0 ? <UploadReference2 boolError={error2} wordError={error} /> :
                        <div className="uploadRef_picMain flex-column d-flex" >
                            <div className={error2 || error !== "" ? "uploaRef_pic-err" : "uploaRef_pic"} onClick={() => { uploadImageRef.current.click() }}>
                                <input type="file" accept="image/png, image/jpeg, image/jpg" ref={uploadImageRef} className="uploadRef_input d-none" onChange={handleUpload} multiple />
                                <img src={uploadRef} alt="" className="uploadRef_svg" />
                                <><p className="uploadRef_line">Drag and drop your photos here</p>
                                </>
                            </div>
                            {error2 ? <span className='err-line' style={{ width: "35%" }}>Upload a valid file</span> : error !== "" ? <span className='err-line'>{error}</span> : ""}
                        </div> : stateC1 === 'state3' ? refImgArr2?.length > 0 ? <UploadReference2 boolError={error3} wordError={error} /> :
                            <div className="uploadRef_picMain flex-column d-flex" >
                                <div className={error3 || error !== "" ? "uploaRef_pic-err" : "uploaRef_pic"} onClick={() => { uploadImageRef.current.click() }}>
                                    <input type="file" accept="image/png, image/jpeg, image/jpg" ref={uploadImageRef} className="uploadRef_input d-none" onChange={handleUpload} multiple />
                                    <img src={uploadRef} alt="" className="uploadRef_svg" />
                                    <><p className="uploadRef_line">Drag and drop your photos here</p>
                                    </>
                                </div>
                                {error3 ? <span className='err-line' style={{ width: "35%" }}>Upload a valid file</span> : error !== "" ? <span className='err-line'>{error}</span> : ""}
                            </div> : stateC1 === "state4" ? refImgArr3?.length > 0 ? <UploadReference2 boolError={error4} wordError={error} /> :
                                <div className="uploadRef_picMain flex-column d-flex" >
                                    <div className={error4 || error !== "" ? "uploaRef_pic-err" : "uploaRef_pic"} onClick={() => { uploadImageRef.current.click() }}>
                                        <input type="file" accept="image/png, image/jpeg, image/jpg" ref={uploadImageRef} className="uploadRef_input d-none" onChange={handleUpload} multiple />
                                        <img src={uploadRef} alt="" className="uploadRef_svg" />
                                        <><p className="uploadRef_line">Drag and drop your photos here</p>
                                        </>
                                    </div>
                                    {error4 ? <span className='err-line' style={{ width: "35%" }}>Upload a valid file</span> : error !== "" ? <span className='err-line'>{error}</span> : ""}
                                </div> : stateC1 === "state5" ? refImgArr4?.length > 0 ? <UploadReference2 boolError={error5} wordError={error} /> :
                                    <div className="uploadRef_picMain flex-column d-flex" >
                                        <div className={error5 || error !== "" ? "uploaRef_pic-err" : "uploaRef_pic"} onClick={() => { uploadImageRef.current.click() }}>
                                            <input type="file" accept="image/png, image/jpeg, image/jpg" ref={uploadImageRef} className="uploadRef_input d-none" onChange={handleUpload} multiple />
                                            <img src={uploadRef} alt="" className="uploadRef_svg" />
                                            <><p className="uploadRef_line">Drag and drop your photos here</p>
                                            </>
                                        </div>
                                        {error5 ? <span className='err-line' style={{ width: "35%" }}>Upload a valid file</span> : error !== "" ? <span className='err-line'>{error}</span> : ""}
                                    </div> : stateC1 === "state6" ? refImgArr5?.length > 0 ? <UploadReference2 boolError={error6} wordError={error} /> :
                                        <div className="uploadRef_picMain flex-column d-flex" >
                                            <div className={error6 || error !== "" ? "uploaRef_pic-err" : "uploaRef_pic"} onClick={() => { uploadImageRef.current.click() }}>
                                                <input type="file" accept="image/png, image/jpeg, image/jpg" ref={uploadImageRef} className="uploadRef_input d-none" onChange={handleUpload} multiple />
                                                <img src={uploadRef} alt="" className="uploadRef_svg" />
                                                <><p className="uploadRef_line">Drag and drop your photos here</p>
                                                </>
                                            </div>
                                            {error6 ? <span className='err-line' style={{ width: "35%" }}>Upload a valid file</span> : error !== "" ? <span className='err-line'>{error}</span> : ""}
                                        </div> : stateC1 === "state7" ? refImgArr6?.length > 0 ? <UploadReference2 boolError={error7} wordError={error} /> :
                                            <div className="uploadRef_picMain flex-column d-flex" >
                                                <div className={error7 || error !== "" ? "uploaRef_pic-err" : "uploaRef_pic"} onClick={() => { uploadImageRef.current.click() }}>
                                                    <input type="file" accept="image/png, image/jpeg, image/jpg" ref={uploadImageRef} className="uploadRef_input d-none" onChange={handleUpload} multiple />
                                                    <img src={uploadRef} alt="" className="uploadRef_svg" />
                                                    <><p className="uploadRef_line">Drag and drop your photos here</p>
                                                    </>
                                                </div>
                                                {error7 ? <span className='err-line' style={{ width: "35%" }}>Upload a valid file</span> : error !== "" ? <span className='err-line'>{error}</span> : ""}
                                            </div> : stateC1 === "state8" ? refImgArr7?.length > 0 ? <UploadReference2 boolError={error8} wordError={error} /> :
                                                <div className="uploadRef_picMain flex-column d-flex" >
                                                    <div className={error8 || error !== "" ? "uploaRef_pic-err" : "uploaRef_pic"} onClick={() => { uploadImageRef.current.click() }}>
                                                        <input type="file" accept="image/png, image/jpeg, image/jpg" ref={uploadImageRef} className="uploadRef_input d-none" onChange={handleUpload} multiple />
                                                        <img src={uploadRef} alt="" className="uploadRef_svg" />
                                                        <><p className="uploadRef_line">Drag and drop your photos here</p>
                                                        </>
                                                    </div>
                                                    {error8 ? <span className='err-line' style={{ width: "35%" }}>Upload a valid file</span> : error !== "" ? <span className='err-line'>{error}</span> : ""}
                                                </div> : null}

                {isDesktop ?  <div className="btn_main-sticky" style={{ marginTop: "auto" }}>
                    {isLoading ? <button className="uploadRef_submit" disabled><Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /></button> : <button className="uploadRef_submit" onClick={handleSubmit}>Submit</button>}
                </div> : <div className="btn_main" style={{ marginTop: "auto" }}>
                    {isLoading ? <button className="uploadRef_submit"  disabled><Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /></button> : <button className="uploadRef_submit" onClick={handleSubmit}>Submit</button>}
                </div> }
            </div>
        </>
    )
}

export default UploadReference1