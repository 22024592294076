import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { emailVerfy, handleRegister } from '../../../Redux/Actions/auth';
// import HeaderNav from '../HeaderNav'
import './SaasOnboarding.css'
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify'
import LoginHeader from './LoginHeader';
import bgmain from "./SaasAssets/bg-main.svg"
import OtpVerifyMob from '../SaasOnboardingMobile/OtpVerifyMob';
import NewOnboardingHeader from './Products/NewOnboardingHeader';
// import NewOnboardingHeader from './NewOnboardingHeader';

const SaasOtpVeri = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch()
  const [otp, setOtp] = useState("")
  const location = useLocation();


  const handleChange = (e) => {
    e.preventDefault()
    if (e.target.value.length <= 4) {
      setOtp(e.target.value)
    }
  }

  useEffect(() => {
    localStorage.setItem("pathname", location.pathname)
  }, [])

  const payload = {
    deviceId: localStorage.getItem("deviceId"),
    email: localStorage.getItem("email"),
    password: localStorage.getItem("password")
  }

  const handleCheck = (e) => {
    e.preventDefault()
    axios.post('https://email-api.idesign.market/api/verify', {
      email: localStorage.getItem("email"),
      "otp": otp
    }).then((res) => {
      console.log(res)
      if (res.status == 200) {
        dispatch(handleRegister(payload))
        localStorage.removeItem("deviceId")
        localStorage.removeItem("email")
        localStorage.removeItem("password")
        window.location.href = "/profilebuild";
      } else if (res.status == 203) {
        toast.error("Enter correct otp")
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const goTo = () => {
    localStorage.removeItem("pathname")
    localStorage.setItem('token', 'null');
    navigateTo('/signup')
  }

  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };


  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

    return (
   <>
        {isDesktop ? <div>
        <ToastContainer />  
        {/* <NewOnboardingHeader/> */}
        <div className="w-100 main-saas" style={{
          backgroundImage: `url(${bgmain})`, backgroundRepeat: "no-repeat",
          backgroundSize: "100%", backgroundPositionY: "center"
        }}>
          <div className="d-flex flex-column a1" >
              <div className="saas-signup-header">OTP Verification</div>
            <span className="saas-signup-span mt-1">We have sent One Time Password on your email id <strong>{localStorage.getItem("email")} </strong> </span>
              <form className="flex-column d-flex" style={{ marginTop: "1rem" }}>
              <input className='saas-form' style={{height: "44px"}} type="number" value={otp} onChange={handleChange} />   
              </form>
            <button className="create222 mt-4" onClick={handleCheck}>Verify</button>
              <div className="d-flex flex-column mt-4">
              <div className="saas-resend" onClick={() => { emailVerfy(payload, navigateTo) }}>Resend OTP</div>
              <div className="saas-resend" onClick={goTo}>Change the Email address</div>
              </div>
          </div>
      </div>
        </div> : <OtpVerifyMob/> }
      </>
  )
}

export default SaasOtpVeri