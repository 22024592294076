import React, { useEffect, useState } from "react";
import SideBarWeb from "../Lms/LmsWebComponents/SideBarWeb";
import goBack from "../Images/gobackicon.svg";
import Cart3dMob from "./Cart3dMob";
import check from "../Images/tickIcon.svg";
import gstDoc from "../Images/gstShowingDocIcon.svg";
import editIcon from "../Images/editingPencil.svg";
import info from "./3dImages/info.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAll, payMethod } from "../Lms/Actions/threedActions";
import axios from "axios";
import { fetchProfileData, setGstDetails } from "../Lms/Actions";
import { Form, Modal, Offcanvas } from "react-bootstrap";
import close from "./3dImages/close.svg";
import { getToken } from "../Lms/getToken";
import SideCart from "./SideCart";
import { createOrder } from "../Apis";
import { RAZOR_PAY_KEY } from "../Config";
import { toast, ToastContainer } from "react-toastify";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";
// B2B - Dashboard\src\Lms\getToken.js

const Cart = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 599);
  const dispatch = useDispatch();
  const payM = useSelector((state) => state.threedReducer.payMethod);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };
  const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const [pay, setPay] = useState("full");
  const handleRadio = (event) => {
    setPay(event.target.value);
  };
  // console.log(pay)

  // console.log(payM)
  const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
  const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);

  const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
  // console.log(profileInfo)
  // console.log(profileInfo[0]?.data?.data?.planId?._id)

  useEffect(async () => {
    const res3 = await axios.get(`https://3d-api.idesign.market/api/room/req-designs?designerId=${localStorage.getItem("userId")}`);
    // console.log(res3)
    setUserData(res3.data);
  }, []);

  useEffect(async () => {
    const res = await axios.get(`https://3d-api.idesign.market/api/room/cart-data?designerId=${localStorage.getItem("userId")}&planId=${profileInfo[0]?.data?.data?.planId?._id}`);
    setData(res.data);
  }, [profileInfo]);

  // console.log(data)
  // console.log(profileInfo[0]?.data?.data?.planId?._id)

  const placeOrderOnRazorpay = async () => {
    var amount;
    if (pay === "half") {
      amount = Math.round(data.totalAmountToBePaid / 2);
    } else {
      amount = Math.round(data.totalAmountToBePaid);
    }
    // var amount = 1;
    const res = await createOrder({ amount: parseFloat(amount.toFixed(2)) });

    if (res?.statusCode !== 200) {
      console.log(res?.message);
      return;
    }

    const option = {
      description: "iDesign payment",
      currency: "INR",
      key: RAZOR_PAY_KEY,
      amount: amount,
      name: "iDesign.market pvt. ltd.",
      order_id: res?.data?.id,
      handler: paymentHandler.bind(this, amount),
      prefill: {
        name: profileInfo[0].data.data.companyName,
        email: profileInfo[0].data.data.email,
        contact: profileInfo[0].data.data.phoneNumber,
      },
      notes: {
        address: `Payment for cart`,
      },
      theme: {
        color: "#61dafb",
      },
    };

    try {
      const paymentObject = new window.Razorpay(option);
      paymentObject.open();
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const paymentHandler = async (amount, response) => {
    const data = {
      pricePaid: amount,
      razorPaymentId: response?.razorpay_payment_id,
      razorOrderId: response?.razorpay_order_id,
      razorSignature: response?.razorpay_signature,
    };
    if (response) {
      userData.map(async (item) => {
        const resM = await axios.put(`https://3d-api.idesign.market/api/room/save-amount-details?id=${item._id}&planId=${profileInfo[0]?.data?.data?.planId?._id}`).then((res) => {
          // console.log(res)
          navigate("/3dFinal");
        });
      });
    }
  };

  const proceedToBuy = () => {
    dispatch(payMethod(pay));
    placeOrderOnRazorpay();
    // userData.map(async (item) => {
    //     const resM = await axios.put(`https://3d-api.idesign.market/api/room/save-amount-details?id=${item._id}&planId=${profileInfo[0]?.data?.data?.planId?._id}`).then((res) => {
    //         navigate("/3dFinal")
    //     })
    // })
  };

  useEffect(() => {
    dispatch(fetchProfileData(authToken));
  }, []);

  var totViews = 0;
  for (let i = 0; i < userData.length; i++) {
    for (let j = 0; j < userData[i].room.length; j++) {
      totViews += userData[i].room[j].views;
    }
  }
  // console.log(totViews)

  const initialGstData = {
    companyName: profileInfo[0]?.data?.data?.companyName,
    gstNum: profileInfo[0]?.data?.data?.gstNumber,
  };
  const authTok = getToken() ? getToken() : "";
  const [gstOffcan, setGstOffcan] = useState(false);
  const [gstData, setGstData] = useState(initialGstData);
  const [showError, setShowError] = useState({
    companyNameError: "",
    gstNumError: "",
  });
  const gstOffcanHandler = (event) => {
    setGstOffcan(event.target.checked);
  };
  const [customCheckbox, setCustomCheckbox] = useState(profileInfo[0]?.data?.data?.planId?.name === "Free" ? false : false);
  const gstDataInputHandler = (event) => {
    const { name, value } = event.target;
    setGstData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleGstSubmit = () => {
    if (gstData.companyName.length < 1) {
      setShowError((prev) => {
        return {
          ...prev,
          companyNameError: "You cannot leave company name empty",
        };
      });
    } else if (gstData.gstNum.length < 1) {
      setShowError((prev) => {
        return {
          ...prev,
          gstNumError: "You cannot leave GST number empty",
        };
      });
    } else {
      setGstOffcan(false);
      dispatch(setGstDetails(authTok, gstData.gstNum, gstData.companyName));
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <Cart3dMob />
      <div className="d-none d-md-block">
        <ToastContainer />
        <Modal
          show={gstOffcan}
          onHide={() => {
            setGstOffcan(false);
          }}
          centered
        >
          <Modal.Header className="p-0" closeButton style={{ margin: "16px 16px 0 16px" }}>
            <Modal.Title>GST Information</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0" style={{ margin: "16px" }}>
            <div style={{ marginBottom: "24px" }}>
              <div>Company Name</div>
              <div>
                <input className="w-100" placeholder="Type company name" name="companyName" value={gstData.companyName} onChange={gstDataInputHandler} />
                {gstData?.companyName?.length < 1 && <span style={{ fontSize: "12px", color: "red" }}>{showError.companyNameError}</span>}
              </div>
            </div>
            <div style={{ marginBottom: "32px" }}>
              <div>Registration No.</div>
              <div>
                <input className="w-100" maxLength={15} placeholder="Enter Registration No." name="gstNum" value={gstData.gstNum} onChange={gstDataInputHandler} />
                {gstData?.gstNum?.length < 1 && <span style={{ fontSize: "12px", color: "red" }}>{showError.gstNumError}</span>}
              </div>
            </div>
            <div>
              <button className="gstModalWebButton" onClick={handleGstSubmit}>
                Confirm
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <HeaderNav />
        <div className="tabDisplay">
          <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh" }}>
            <SidebarWebNew />
          </div>
          <div style={{ width: "100%", padding: "2rem", height: "90vh", overflowY: "scroll", overflowX: "hidden" }} className="myCart-wholeContent">
            <div className="webMyCart-header" style={{ padding: "12px 0px", borderBottom: "1px solid #DFDFDF", boxShadow: "none", cursor: "pointer" }}>
              <div className="d-flex align-items-center" role="button">
                <img src={goBack} />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "500" }} role="button">
                My Cart
              </div>
            </div>
            <div style={{ margin: "24px 0", fontSize: "18px", fontWeight: "500" }}>My Items</div>
            <div className="cart_main">
              <SideCart payMethod={pay} />
              <div>
                <div style={{ width: "24vw", padding: "2rem", height: "60vh", paddingTop: "0" }} className="myCart-wholeContent">
                  <div className="webCart-pricingPart my-2" style={{ width: "100%" }}>
                    {pay === "full" ? (
                      <div className="sections" style={{ padding: "32px 24px 16px 20px", border: "1px solid #DFDFDF" }}>
                        <div style={{ fontSize: "15px", fontWeight: "400", marginBottom: "16px" }}>Price Details</div>
                        <div className="d-flex justify-content-between">
                          <div style={{ fontSize: "13px", fontWeight: "700" }}>Total View({totViews})</div>
                          <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {data.totalAmount}</div>
                        </div>
                        {profileInfo[0]?.data?.data?.planId?.name !== "Free" ? (
                          <div className="d-flex justify-content-between">
                            <div style={{ fontSize: "13px", fontWeight: "700" }}>
                              Discount{" "}
                              <span
                                style={{
                                  color: "#888888",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                }}
                              >
                                (premium user)
                              </span>
                            </div>
                            <div style={{ fontSize: "13px", fontWeight: "400", color: "#176091" }}>- ₹ {Math.round(data.discount)}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="d-flex justify-content-between" style={{ borderBottom: "1px solid #DFDFDF", paddingBottom: "30px", marginBottom: "16px" }}>
                          <div style={{ fontSize: "13px", fontWeight: "700" }}>GST- 18%</div>
                          <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(data.gstApplied)}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div style={{ fontSize: "15px", fontWeight: "700" }}>Total Price</div>
                          <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(data.totalAmountToBePaid)}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="sections" style={{ padding: "32px 24px 16px 20px", border: "1px solid #DFDFDF" }}>
                        <div style={{ fontSize: "15px", fontWeight: "400", marginBottom: "16px" }}>Price Details</div>
                        <div className="d-flex justify-content-between">
                          <div style={{ fontSize: "13px", fontWeight: "700" }}>Total View({totViews})</div>
                          <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {data.totalAmount / 2}</div>
                        </div>
                        {profileInfo[0]?.data?.data?.planId?.name !== "Free" ? (
                          <div className="d-flex justify-content-between">
                            <div style={{ fontSize: "13px", fontWeight: "700" }}>
                              Discount{" "}
                              <span
                                style={{
                                  color: "#888888",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                }}
                              >
                                (premium user)
                              </span>
                            </div>
                            <div style={{ fontSize: "13px", fontWeight: "400", color: "#176091" }}>- ₹ {Math.round(data.discount / 2)}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="d-flex justify-content-between" style={{ borderBottom: "1px solid #DFDFDF", paddingBottom: "30px", marginBottom: "16px" }}>
                          <div style={{ fontSize: "13px", fontWeight: "700" }}>GST- 18%</div>
                          <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(data.gstApplied / 2)}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div style={{ fontSize: "15px", fontWeight: "700" }}>Total Price</div>
                          <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(data.totalAmountToBePaid / 2)}</div>
                        </div>
                      </div>
                    )}
                    <div className="sections" style={{ padding: "12px 18px 16px 18px", border: "1px solid #DFDFDF" }}>
                      <div className="d-flex align-items-center" style={{ paddingBottom: "10px" }}>
                        <div>
                          <div
                            className="customCheckbox"
                            style={{ marginRight: "12px", userSelect: "none" }}
                            role="button"
                            onClick={() => {
                              setGstOffcan(true);
                            }}
                          >
                            {profileInfo[0]?.data?.data?.gstNumber ? <img src={check} /> : null}
                          </div>
                        </div>
                        <div style={{ fontSize: "15px" }}>
                          I have a GST number <span>(Optional)</span>
                        </div>
                      </div>
                      <div>
                        {gstData?.gstNum?.length > 0 && (
                          <div className="gstInfoShowingBox" style={{ padding: "8px 16px 8px 12px" }}>
                            <div className="d-flex align-items-center">
                              <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                                <img src={gstDoc} />
                              </div>
                              <div>
                                <div style={{ fontSize: "11px", fontWeight: "700" }}>{gstData.companyName}</div>
                                <div style={{ fontSize: "11px", fontWeight: "700" }}>{gstData.gstNum}</div>
                              </div>
                            </div>
                            <div>
                              <img
                                src={editIcon}
                                role="button"
                                style={{ height: "20px", width: "18px" }}
                                onClick={() => {
                                  setGstOffcan(true);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="sections" style={{ padding: "12px 18px 16px 18px", border: "1px solid #DFDFDF" }}>
                      <div className="d-flex flex-column align-items-center">
                        <div className="d-flex w-100" style={{ borderBottom: "1px solid #DFDFDF" }}>
                          <input type="radio" value="full" checked={pay === "full"} onChange={handleRadio} />
                          <p className="my-2 px-2 d-flex justify-content-between  w-100" style={{ fontWeight: "400", fontSize: "16px", color: "black" }}>
                            {" "}
                            Pay in full<span className="float-end">₹ {Math.round(data.totalAmountToBePaid)} /-</span>
                          </p>
                        </div>
                        <div className="d-flex w-100">
                          <input type="radio" value="half" checked={pay === "half"} onChange={handleRadio} />
                          <p className="my-2 px-2 d-flex justify-content-between  w-100" style={{ fontWeight: "400", fontSize: "16px", color: "black" }}>
                            Pay 50% later<span className="float-end">₹ {Math.round(data.totalAmountToBePaid / 2)} /-</span>
                          </p>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="sections" style={{ border: "none", marginBottom: "2rem" }}>
                      <button className="proceedToBuyButton" style={{ marginBottom: "1rem" }} onClick={proceedToBuy}>
                        Proceed To Buy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
